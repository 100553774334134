import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Input,
  message,
  Select,
  Tag,
  Menu,
  Button,
  Form,
  Drawer,
  Space,
  Modal,
} from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../Component/jsonsStyles/styleJson";
import { useSelector } from "react-redux";
import ReactQuill from "react-quill";
import parse from "html-react-parser";
import en from "../../localization/en";
import ar from "../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const NotificationTemplate = () => {
  const { confirm } = Modal;

  const pdfRef = React.createRef();
  const [form] = Form.useForm();

  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [TotalCount, setTotalCount] = useState(30);
  const [Open, setOpen] = useState(false);
  const [notificationTypeList, setnotificationTypeList] = useState([]);
  const [notificationType, setnotificationType] = useState();
  const [record, setrecord] = useState();
  const [page, setpage] = useState(0);

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const { Option } = Select;

  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRouteToComponent(record)}>
        Edit
      </Menu.Item>
      <Menu.Item key="2" onClick={() => handleDeleteNotification(record)}>
        Delete
      </Menu.Item>
    </Menu>
  );

  const handleDeleteNotification = (record) => {
    setLoading(true);
    confirm({
      title: `${t.DoyouWanttodeletethis} ${t.NotificationTemplate}?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      cancelButtonProps: {
        style: { color: "black" }, // Setting the text color to black
      },
      onOk: () => {
        setLoading(true);
        Api.get(
          `AdminAPIs/DeleteNotificationTemplate?NotificationTemplateId=${record?.Id}`
        ).then(
          (res) => {
            setLoading(false);
            if (res?.status === 200) {
              message?.success(res?.data?.metas?.message);
              fetchListData();
            }
          },
          (err) => {
            message.error(err);
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };
  const handleRouteToComponent = (record) => {
    setrecord(record);
    fetchNotificationType();
    if (record) {
      form.setFieldsValue({
        notificationType: record?.NotificationTemplateTypeId,
        Message: record?.Message,
        MessageLT: record?.MessageLT,
      });
    } else {
      form.resetFields();
    }
    // if (Object.keys(record).length > 0) {
    setOpen(true);
    // }
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.Message} </p>,
      dataIndex: "Message",
    },
    {
      title: <p className="table-header-text"> {t.CreationDate}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("DD-MM-YYYY ")} </p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={["hover"]}>
          <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
        </Dropdown>
      ),
    },
  ];

  const fetchListData = (index = 0) => {
    setLoading(true);
    setpage(index);
    Api.get(`AdminAPIs/GetNotificationTemplates?page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setCallBack(dataSource);
          setCallBackList(dataSource);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const onClose = () => {
    setrecord();
    form.resetFields();
    setOpen(false);
  };

  const fetchNotificationType = () => {
    setLoading(true);
    Api.get(`AdminAPIs/GetNotificationTemplateTypes`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setnotificationTypeList(res?.data?.model);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleNotificationTypeSelect = (e) => {
    setnotificationType(e);
  };

  const handleEditorChange = () => {};

  const handleSave = (values) => {
    setLoading(true);
    let body = {
      NotificationTemplateTypeId: values?.notificationType,
      Message: values?.Message?.replace(/<[^>]+>/g, ""),
      MessageLT: values?.MessageLT?.replace(/<[^>]+>/g, ""),
    };
    if (record) {
      body = { Id: record?.Id, ...body };
      // Edit existing notification template
      Api.post(`AdminAPIs/EditNotificationTemplate`, body)
        .then((res) => {
          setLoading(false);
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);
            onClose(); // Close the drawer after saving
            fetchListData(); // Refresh the list
          }
        })
        .catch((err) => {
          message.error(err?.response?.data?.message || "Failed to update");
          setLoading(false);
        });
    } else {
      Api.post(`AdminAPIs/CreateNotificationTemplate`, body).then(
        (res) => {
          setLoading(false);
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);
            setOpen(false);
            form.resetFields();
            setrecord();
            fetchListData();
          }
        },
        (err) => {
          message.error(err);
          setLoading(false);
        }
      );
    }
  };

  useEffect(() => {
    fetchListData();
  }, []);

  return (
    <div className="product__wrapper">
      <Drawer
        title={
          record !== undefined && Object?.keys(record).length > 0
            ? t.EditNotificationTemplate
            : t.CreateNotificationTemplate
        }
        width={520}
        onClose={onClose}
        open={Open}
      >
        <>
          <div className="NotificationTemplateDrawer">
            {" "}
            <Form
              onFinish={handleSave}
              form={form}
              initialValues={record || {}}
            >
              <Form.Item
                label={t.SelectNotificationType}
                name="notificationType"
              >
                <Select className="notification-temp-select">
                  {notificationTypeList?.map((type) => (
                    <Option key={type?.Id} value={type?.Id}>
                      {type?.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item label={t.MessageAr} name="MessageLT">
                <ReactQuill
                  theme="snow"
                  className="rtl-input"
                  onChange={() => handleEditorChange("ar")}
                />
              </Form.Item>
              <div style={{ height: "30px" }}></div>
              <Form.Item label={t.MessageEn} name="Message">
                <ReactQuill theme="snow" onChange={handleEditorChange("en")} />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 30 }}
                  onClick={handleSave}
                  loading={loading}
                >
                  Save Template
                </Button>
              </Form.Item>
            </Form>
          </div>
        </>
      </Drawer>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">{t.NotificationTemplate}</p>
            <Button
              onClick={() => handleRouteToComponent()}
              style={{
                border: "none",
                color: "#fff",
                backgroundColor: "#612166",
              }}
            >
              {" "}
              {t.CreateNotificationTemplate}
            </Button>
          </div>
        }
        columns={columns || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
      />
    </div>
  );
};
