import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, message, Rate, Select, Tag } from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import moment from "moment";
import { CSVLink } from "react-csv";
import { DownOutlined } from "@ant-design/icons";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import en from "../../../localization/en";
import ar from "../../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const OrderSLA = () => {
  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [columns, setColumns] = useState([]);

  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);
  const [page, setpage] = useState(0);
  const t = currentLang === "en" ? en : ar;

  const [excelData, setExcelData] = useState([]);

  const fetchListData = (index = 0) => {
    setLoading(true);
    setpage(index);
    Api.get(`AdminAPIs/SLAReport?Page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          const data = res?.data?.model?.map((item, ind) => ({
            Id: ind + 1 + index * 30,
            ...item,
          }));
          setCallBackList(data);
          setColumns([
            Object.keys(data[0]).map((key) => {
              return {
                title: key?.replace(/([A-Z])/g, " $1")?.trim(), // Convert camelCase to spaced words
                dataIndex: key,
                key: key,
                render: (text) =>
                  text !== null && text !== undefined ? text : "N/A", // Handle null or undefined values
              };
            }),
          ]);
          setCallBack(data);
        }

        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  // const columns = [
  //   {
  //     title: <p className="table-header-text"> {t.OrderID} </p>,
  //     dataIndex: "id",
  //     render: (_, record) => (
  //       <a
  //         onClick={() => {
  //           history.push({
  //             pathname: `/ordersDetails/${record?.Id}`,
  //             state: record,
  //           });
  //         }}
  //       >
  //         {" "}
  //         #{record?.Id}
  //       </a>
  //     ),
  //   },
  //   {
  //     title: <p className="table-header-text"> {t.CompanyName} </p>,
  //     dataIndex: "CompanyName",
  //   },
  //   {
  //     title: <p className="table-header-text"> {t.Driver}</p>,
  //     dataIndex: "DeliveryName",
  //     render: (_, record) => <span>{record?.DeliveryName} </span>,
  //   },
  //   {
  //     title: <p className="table-header-text"> {t.datePlaced}</p>,
  //     dataIndex: "CreationDate",
  //     render: (_, record) => {
  //       return (
  //         <p>
  //           {moment.utc(record?.CreationDate).local().format("YYYY/M/D - ")}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className="table-header-text">{t.orderDelivery} </p>,
  //     dataIndex: "CreationDate",
  //     render: (_, record) => {
  //       return (
  //         <p>
  //           {moment.utc(record?.DeliveryDateTime).local().format("YYYY/M/D - ")}
  //         </p>
  //       );
  //     },
  //   },
  //   {
  //     title: <p className="table-header-text">{t.orderDelivery}</p>,
  //     dataIndex: "OrderDeliveryStatus",
  //   },
  //   //   {
  //   //     title: <p className='table-header-text'>{t.OrderDate}</p>,
  //   //     dataIndex: 'OrderDate',
  //   //   },
  // ];

  useEffect(() => {
    fetchListData();
  }, []);

  // useEffect(() => {
  //   const data = callBack?.map((item) => {
  //     let obj = {};
  //     columns.map((dataIndex) => {
  //       if (dataIndex.dataIndex === "date")
  //         return (obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] =
  //           moment(item[dataIndex.dataIndex]).format("DD/MM/YYYY"));
  //       else
  //         return (obj[dataIndex.title.props.children[0]?.toUpperCase()] =
  //           item[dataIndex.dataIndex]);
  //     });
  //     return obj;
  //   });
  //   setExcelData(data);
  // }, [columns, callBack]);

  useEffect(() => {
    if (callBack.length && columns[0].length) {
      const data = callBack?.map((item) => {
        let obj = {};

        columns[0]?.forEach((column) => {
          const dataIndex = column.dataIndex;
          const title = column.title?.props?.children[0]?.toUpperCase() || "";

          if (dataIndex.toLowerCase().includes("date")) {
            obj[title] = moment(item[dataIndex]).format("DD/MM/YYYY");
          } else {
            obj[title] =
              item[dataIndex] !== null && item[dataIndex] !== undefined
                ? item[dataIndex]
                : "N/A"; // Handle null or undefined values
          }
        });

        return obj;
      });

      setExcelData(data);
    }
  }, [columns, callBack]);

  const handelPrint = () => {
    var bodyPrint = document.getElementById("print-section").innerHTML;
    var mywindow = window.open("", "PRINT");
    mywindow.document.write("<html><head>");
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">'
    );
    mywindow.document.write(`<style> ${StyleToPRint}</style>`);
    mywindow.document.write(bodyPrint);
    mywindow.document.write("</body></html>");
    mywindow.document.title = t.OrderSLA;
    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 200);
  };

  return (
    <div className="product__wrapper">
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title"> {t.OrderSLA}</p>
            <div className="download__list">
              <CSVLink filename={`${t.OrderSLA}.csv`} data={callBackList || []}>
                {t.Excel}
              </CSVLink>
              <a onClick={handelPrint}>{t.Print}</a>
            </div>
          </div>
        }
        columns={columns[0] || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
        scrollX={1400}
      />
    </div>
  );
};
