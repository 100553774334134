
import { Button, Input, message } from 'antd';
import React, { useEffect, useState } from 'react';
import Api from '../../../Network/ApiConfig';
import './style.scss'

export const PriceListPage = () => {
    const [priceList, setPriceList] = useState([]);
    const [editPriceList, setEditPriceList] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleFetchPriceList = () => {
        Api.get('products/priceList').then(res => {
            setPriceList(res.data.data.List);
        });
    }

    useEffect(() => handleFetchPriceList(), []);

    const handleChangeGroupePrice = (value, groupIndex) => {
        let priceListEdit = { ...priceList };
        let groupsEdit = priceListEdit.groups?.map((item, idx) => {
            let updatedItem = item;
            if (idx === groupIndex) {
                updatedItem.group_price = value
            }
            return updatedItem
        });
        priceListEdit.groups = groupsEdit
        setPriceList(priceListEdit);
    }

    const updatePriceList = () => {
        let header = {
            'Content-Type': 'multipart/form-data;'
        }
        setLoading(true);
        let body = new FormData();

        body.append("productId", priceList?.id);
        body.append("base_price", priceList?.base_price);
        priceList?.groups.map((subItem, idx) => {
            body.append(`group_id[${idx}]`, subItem.group_id);
            body.append(`group_price[${idx}]`, subItem.group_price ? subItem.group_price : priceList?.base_price)
        });

        Api.post('products/updatePriceList', body, header).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0])
            }
            if (res.data.code === 200) {
                message.success(res.data.message);
                setEditPriceList(false)
            }
            setLoading(false)
        })
    }

    const handleChangeGroupePriceBase = (value) => {
        let priceListEdit = { ...priceList }
        priceListEdit.base_price = value;
        setPriceList(priceListEdit)
    }

    return (
        <div className='table__wrapper'>
            <div className='table__wrapper--header'>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p className='bold'> Price list <span className='normal-font' style={{ marginLeft: '10px' }}> قائمة الاسعار </span></p>
                    <Button onClick={() => setEditPriceList(true)} className='secondary'>تعديل الاسعار</Button>
                </div>
            </div>
            <table className='ant-table'>
                <thead className='ant-table-thead'>
                    <tr>
                        <th className='ant-table-cell'><p className='table-header-text'> Name <span>الاسم</span> </p></th>
                        <th className='ant-table-cell'><p className='table-header-text'> Product Image <span>صورة المنتج</span> </p></th>
                        <th className='ant-table-cell'> <p className='table-header-text'> Base Price  <span>السعر الاساسي</span> </p></th>
                        {priceList?.groups?.map((item, idx) => (
                            <th key={item.group_id} className='ant-table-cell'>{item.group_name}</th>
                        ))}
                    </tr>
                </thead>

                <tbody className='ant-table-tbody'>
                    <tr  >
                        <td className='ant-table-cell'> {priceList?.name} </td>
                        <td className='ant-table-cell'>  <img src={priceList?.image} alt={priceList?.name} /> </td>
                        <td className='ant-table-cell'> {!editPriceList ?
                            <>
                                جنيه {priceList?.base_price}
                            </> :
                            <Input className='price__input' value={priceList?.base_price}
                                onChange={(e) => {
                                    if (e.target.value.length >= 11) return
                                    if (/^\d*\.?\d*$/.test(e.target.value) || !!!e.target.value)
                                        handleChangeGroupePriceBase(e.target.value);
                                }}
                            />
                        } </td>
                        {priceList?.groups?.map((subItem, idx) => (
                            <td key={subItem.group_id} className='ant-table-cell'>
                                {!editPriceList ?
                                    <>  جنيه {subItem.group_price ? subItem.group_price : priceList.base_price} </> :
                                    <Input className='price__input' value={subItem.group_price ? subItem.group_price : priceList.base_price}
                                        onChange={(e) => {
                                            if (e.target.value.length >= 11) return
                                            if (/^\d*\.?\d*$/.test(e.target.value) || !!!e.target.value)
                                                handleChangeGroupePrice(e.target.value, idx)
                                        }}
                                    />
                                }
                            </td>
                        ))}
                    </tr>

                </tbody>
            </table>

            {editPriceList && <div style={{ textAlign: 'right', padding: '20px 40px' }}>
                <Button loading={loading} onClick={updatePriceList} className='default'> save</Button>
            </div>}
        </div>
    );
};
