import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  message,
  Select,
  Modal,
} from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import Input from "antd/lib/input/Input";
import { CSVLink } from "react-csv";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
const { Option } = Select;
const { confirm } = Modal;

export const Chauffeurs = () => {
  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const [page, setpage] = useState(0);

  const handelDltRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} السائق?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        Api.get(`Delivery/DeleteDelivery?DeliveryId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              fetchLeedsList();
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };

  const handelEditRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} السائق?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        Api.get(`Delivery/DeleteDelivery?DeliveryId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              setLeadsList(res?.data?.model);
              setLeadsListFiltered(res?.data?.model);
            }
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [pdfData, setPdfData] = useState([]);
  const [TotalCount, setTotalCount] = useState(30);

  // const [pdfTableColumns, setPdfTableColumns] = useState(pdfColumns);

  // const columnList = () => {
  //   return (
  //     <div className='dropdown__column--visible'>
  //       <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
  //         Check all
  //       </Checkbox>
  //       <Checkbox.Group options={checkBoxColumn} value={checkedList} onChange={onChange} />
  //     </div>
  //   )
  // }

  // const onChange = list => {
  //   // if (list?.length === 0) return
  //   let tableColumnClone = [];
  //   columns.map((item) => {
  //     if (list.includes(item.dataIndex)) {
  //       tableColumnClone.push(item)
  //     }
  //   })

  //   setTableColumn(tableColumnClone)
  //   setCheckedList(list);
  //   setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
  //   setCheckAll(list.length === checkBoxColumn.length);
  // };

  const fetchLeedsList = (index = 0) => {
    setLoading(true);
    Api.get(`AdminAPIs/GetChauffeurs?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setLeadsList(dataSource);
          setLeadsListFiltered(dataSource);
          if (res?.data?.metas?.TotalaCount) {
            setTotalCount(res?.data?.metas?.TotalaCount);
          }
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLeedsList();
    setpage(0);
  }, [currentLang]);

  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "key",
    },
    // {
    //   title: <p className='table-header-text'> # </p>,
    //   dataIndex: 'Id',
    // },
    {
      title: <p className="table-header-text"> {t.name}</p>,
      dataIndex: currentLang === "en" ? "Name" : "NameLT",
    },
    {
      title: <p className="table-header-text"> {t.deliveryCompany}</p>,
      render: (_, record) => <span>{record?.Company?.Name}</span>,
    },
    {
      title: <p className="table-header-text"> {t.Phonenumber}</p>,
      dataIndex: "user_phone",
      render: (_, record) =>
        record?.AspNetUsers && (
          <a
            href={`tel:${
              record?.AspNetUsers?.PhoneKey + record?.AspNetUsers?.PhoneNumber
            }`}
          >
            {record?.AspNetUsers?.PhoneKey + record?.AspNetUsers?.PhoneNumber}{" "}
          </a>
        ),
    },
    // {
    //   title: <p className='table-header-text'> National Id Card <span> بطاقة الهوية الوطنية</span></p>,
    //   render: (_, record) => <Image src={record?.AspNetUsers?.FullName} />,
    // },
    {
      title: <p className="table-header-text"> {t.VehicleLicence}</p>,
      render: (_, record) => <Image src={record?.VehicleLicenceImage} />,
    },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="select-item-action">
            {/* <EditOutlined onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              // handelEditRowData(record?.Id);
            }} style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }} /> */}
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handelDltRowData(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}> {t.Chauffeurs}</p>

            {/* <div className='product__btn'>
            <Button onClick={() => history.push('/add-chauffeur')} className='primary'>New Chauffeur  سائق جديد</Button>
          </div> */}
            {/* <div className='download__list'>
            <CSVLink filename="leads.csv" data={excelData}>{t.Excel}</CSVLink>
            <a onClick={handelPrint}>Pdf</a>
            <Dropdown trigger={'click'} overlay={columnList}>
              <a style={{ width: '135px' }}>
                column visibility <DownOutlined style={{ marginLeft: '7px' }} />
              </a>
            </Dropdown>
          </div>
          <div className='search__list--input'>
            <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={columns}
        dataSource={leadsList || []}
        // defaultCurrent={10}
        loading={loading}
        total={TotalCount}
        onChange={(pagination) => {
          setpage(pagination?.current - 1);
          fetchLeedsList(pagination?.current - 1);
        }}
        pageSize={30}
      />
    </div>
  );
};
