import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Dropdown, Image, message, Select, Modal, Switch } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import Api from '../../../Network/ApiConfig'
import { DownOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from 'react-redux';
const { Option } = Select
const { confirm } = Modal;

export const FAQCategories = () => { 
  const history = useHistory();
  const {  currentLang } = useSelector((state) => state?.user);

  const t = currentLang === "en" ? en : ar;

const handelDltRowData = (Id) => {
  confirm({
    title: `${t.DoyouWanttodeletethis} فئه?`,
    // title: localization.DodeletetheseAddress[this.props.currentLang], 
    icon: <ExclamationCircleOutlined />,
    okText: t.Ok,
    cancelText: t.Cancel,
    onOk: () => {
      setLoading(true)
      Api.get(`AdminAPIs/DeleteFAQCategory?FAQCategoryId=${Id}`).then(res => {
        if (res?.status === 200) {
          // setLeadsList(res?.data?.model);
          // setLeadsListFiltered(res?.data?.model);
          message.success(res?.data?.metas?.message);
          fetchLeedsList(); 
        }
      }, (err) => {
        setLoading(false)
      })
      },
      onCancel() {
        
      },
    });
  };


  const columns = [
    {
      title: <p className='table-header-text'> # </p>,
      dataIndex: 'Id',
    },
    {
      title: <p className='table-header-text'> {t.name}</p>,
            dataIndex: currentLang === "en" ? "Name" : "NameLT",

    },
    {
      title:  <p className='table-header-text'> {t.Actions}</p>,
      dataIndex: '',
      render: (_, record) => {
        return (
          <div className="select-item-action"> 
            <EditOutlined onClick={(e) => {
              e.stopPropagation();
              e.preventDefault(); 
              // handelEditRowData(record?.Id);
              history.push({ pathname: '/add-faq-category', state: record })
            }} style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }} />
            <DeleteOutlined onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handelDltRowData(record?.Id);
            }
            } style={{ marginInlineEnd: 20, color: 'red', fontSize: 20, cursor: "pointer" }} />
          </div>
        );
      },
    },
  ];

  const pdfRef = React.createRef()
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [tableColumn, setTableColumn] = useState(columns);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [checkedList, setCheckedList] = useState(
    columns.map((item) => {
      return item.dataIndex
    }));
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(true);
  const [pdfData, setPdfData] = useState([]);
  // const [pdfTableColumns, setPdfTableColumns] = useState(pdfColumns);
  const checkBoxColumn = columns.map((item) => {
    return { label: item.title, value: item.dataIndex }
  });

  const onChange = list => {
    // if (list?.length === 0) return
    let tableColumnClone = [];
    columns.map((item) => {
      if (list.includes(item.dataIndex)) {
        tableColumnClone.push(item)
      }
    })

    setTableColumn(tableColumnClone)
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
    setCheckAll(list.length === checkBoxColumn.length);
  };


  const fetchLeedsList = (url, pageSize) => {
    
    setLoading(true);

      Api.get('AdminAPIs/GetFAQCategories?page=0').then(res => {
        if (res?.status === 200) {
          setLeadsList(res?.data?.model);
          setLeadsListFiltered(res?.data?.model); 
        }
        setLoading(false)
      }, (err) => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchLeedsList()
  }, [currentLang]);


  return (
    <div>
      <TableGrid TableRef={pdfRef} 
      title={
        <div className='table__filter'>
          <p style={{ fontWeight: 'bold' }}>{t.FAQCategories}</p>

          <div className='product__btn'>
            <Button onClick={() => history.push('/add-faq-category')} className='primary'>   {t.NewCategory} </Button>
          </div>
        </div>
      }
        columns={tableColumn}
        dataSource={tableColumn.length > 0 ? leadsList : []}
        pagination={{ pageSize: 10 }}
        defaultCurrent={10}
        loading={loading}
        total={leadsListFiltered?.pagination?.total}
        onChange={(pagination) => {
          let url = `leads?page=${pagination?.current}`
          fetchLeedsList(url)
        }}
        pdfData={pdfData}
        // pdfTableColumns={pdfTableColumns}
        pageSize={30}
      />
    </div>
  );
};
