import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  message,
  Select,
  Modal,
  Switch,
  Typography,
} from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import { StyleToPRint } from "../../../Component/jsonsStyles/styleJson";
import moment from "moment";

const { Option } = Select;
const { confirm } = Modal;

export const Blogs = () => {
  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  const [TotalCount, setTotalCount] = useState(30);
  const [excelData, setExcelData] = useState([]);

  // const [pdfTableColumns, setPdfTableColumns] = useState(pdfColumns);
  // const checkBoxColumn = columns.map((item) => {
  //   return { label: item.title, value: item.dataIndex };
  // });

  const t = currentLang === "en" ? en : ar;

  const handelDltRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} ${t.Blog} ?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        setLoading(true);
        Api.get(`AdminAPIs/DeleteBlog?BlogId=${Id}`).then(
          (res) => {
            if (res?.status === 200) {
              message.success(res?.data?.metas?.message);
              fetchLeedsList();
            }
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
      cancelButtonProps: {
        style: { color: "black" }, // Setting the text color to black
      },
    });
  };

  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.name}</p>,
      dataIndex: currentLang === "en" ? "Title" : "TitleLT",
      render: (_, record) =>
        currentLang === "en"
          ? parse(record?.Title || "")
          : parse(record?.TitleLT || ""),
    },
    // {
    //   title: <p className="table-header-text"> {t.description}</p>,
    //   className: "text-elp",
    //   render: (_, record) => <p>{record?.DescriptionLT}</p>,
    //   // render: (_, record) =>  <Paragraph ellipsis>{record?.DescriptionLT}</Paragraph>
    // },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      render: (_, record) => {
        return (
          <div
            className="select-item-action"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                history.push({ pathname: "/add-blog", state: record });
              }}
              style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }}
            />
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handelDltRowData(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const fetchLeedsList = (index = 0) => {
    setLoading(true);

    Api.get(`AdminAPIs/GetBlogs?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          const data = res?.data?.model?.map((item, ind) => ({
            key: ind + 1 + index * 30,
            ...item,
          }));
          setLeadsList(data);
          setLeadsListFiltered(data);
        }

        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    if (leadsListFiltered.length && columns[0].length) {
      const data = leadsListFiltered?.map((item) => {
        let obj = {};

        columns[0]?.forEach((column) => {
          const dataIndex = column.dataIndex;
          const title = column.title?.props?.children[0]?.toUpperCase() || "";

          if (dataIndex.toLowerCase().includes("date")) {
            obj[title] = moment(item[dataIndex]).format("DD/MM/YYYY");
          } else {
            obj[title] =
              item[dataIndex] !== null && item[dataIndex] !== undefined
                ? item[dataIndex]
                : "N/A"; // Handle null or undefined values
          }
        });

        return obj;
      });
      setExcelData(data);
    }
  }, [columns, leadsListFiltered]);

  const handelPrint = () => {
    var bodyPrint = document.getElementById("print-section").innerHTML;
    var mywindow = window.open("", "PRINT");
    mywindow.document.write("<html><head>");
    mywindow.document.write(
      '<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">'
    );
    mywindow.document.write(`<style> ${StyleToPRint}</style>`);
    mywindow.document.write(bodyPrint);
    mywindow.document.write("</body></html>");
    mywindow.document.title = t.cancelationReport;
    setTimeout(() => {
      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      mywindow.close();
    }, 200);
  };

  useEffect(() => {
    fetchLeedsList();
  }, [currentLang]);

  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}>{t.Blogs}</p>

            <div className="product__btn">
              <Button
                onClick={() => history.push("/add-blog")}
                className="primary"
              >
                {t.NewBlog}{" "}
              </Button>
            </div>
          </div>
        }
        columns={columns || []}
        dataSource={leadsList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchLeedsList(pagination?.current - 1);
        }}
      />
    </div>
  );
};
