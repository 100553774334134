import React, { useEffect, useState } from 'react';
import { Button, Select, Tag, Modal, message, Form, DatePicker } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Api from '../../../Network/ApiConfig'
import { StarFilled, DeleteFilled, ExclamationCircleOutlined, EditFilled } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';
import moment from 'moment';
import en from '../../../localization/en';
import ar from '../../../localization/ar';

const { Option } = Select
const { confirm } = Modal

export const AllProduct = () => {
	const [form] = Form.useForm();
	const history = useHistory();
	const [productList, setProductList] = useState([]);
	const [productListPaginate, setProductListPaginate] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [productSelected, setProductSelected] = useState(false);
	const [areaList, setAreaList] = useState([]);
	const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
    const currentLang = "ar";
    const t = currentLang === "en" ? en : ar;
	const fetchProduct = (url, pagSize) => {
		let body = {
			pagination_number: pagSize ? pagSize : defaultCurrentVal
		}

		setLoading(true)
		Api.post(url ? url : 'products', body).then(res => {
			if (res.data.validation.length > 0) {
				message.error(res.data.validation[0])
			}
			if (res.data.code === 200) {
				setProductList(res.data.data.products);
				setProductListPaginate(res.data.data);
			}
			setLoading(false)
		})
	}


	const fetchAreaList = () => {
		setLoading(true)
		Api.get('products/areas').then(res => {
			if (res.data.validation.length > 0) {
				message.error(res.data.validation[0])
			}
			if (res.data.code === 200) {
				setAreaList(res.data.data.areas);
			}
			setLoading(false)
		})
	}

	useEffect(() => {
		fetchProduct();
		fetchAreaList()
	}, []);

	const columns = [
		{
			title: <p className='table-header-text'> Order <span>الترتيب</span> </p>,
			dataIndex: 'rank',
			render: (_, rec) => {
				return (
					<p> {rec.rank}  <StarFilled width={'20px'} style={{ color: '#ff9901' }} /> </p>
				)
			}
		},
		{
			title: <p className='table-header-text'> Thumbnail  <span> الصورة </span></p>,
			dataIndex: 'mainImage',
			render: (_, rec) => {
				return <img width={50} src={rec.mainImage} alt="product" />
			}
		},
		{
			title: <p className='table-header-text'> Name <span> اسم المنتج </span></p>,
			dataIndex: 'name',
		},
		{
			title: <p className='table-header-text'> Subcategory <span> القسم </span></p>,
			dataIndex: 'subCategoryEnName',
		},
		{
			title: <p className='table-header-text'> Views <span> المشاهدات </span></p>,
			dataIndex: 'views',
		},
		{
			title: <p className='table-header-text'> Visibility <span> حالة المنتج </span></p>,
			dataIndex: 'visibility',
			render: (_, rec) => {
				return (
					<div>
						<Tag className='table-tag' style={{ background: rec.visibility === 1 ? '#612166' : '#fc4242' }}>
							{rec.visibility === 1 ? 'visible - ظاهر' : 'Invisible - مخفي'}
						</Tag>
					</div>
				);
			},
		},
		{
			title: <p className='table-header-text'> Actions <span> افعال </span></p>,
			dataIndex: 'address',
			render: (_, record) => {
				return (
					<div>
						<EditFilled onClick={() => handleGetProductData(record)} style={{ color: '#612166', fontSize: '18px', margin: '10px', cursor: 'pointer' }} />
						<DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeleteSubCategories(record)} />
						<p onClick={() => {
							setProductSelected(record)
							setIsModalVisible(true)
						}} className='add-promotion'> Add Promotion </p>
					</div>
				);
			}
		},
	];

	const handleGetProductData = (rec) => {
		history.push({
			pathname: '/profile/product-add',
			state: { productId: rec.id }
		});
	}

	const handleDeleteSubCategories = (record) => {
		let body = new FormData();
		body.append('productId', record.id);

		confirm({
			title: `Delete This Product?`,
			icon: <ExclamationCircleOutlined />,
			okText: 'Delete',
			cancelText: 'Cancel',
			onOk: () => {
				Api.post('products/delete', body).then(res => {
					if (res.data.validation.length > 0) {
						message.error(res.data.validation[0])
					}
					if (res.data.code === 200) {
						message.success(res.data.message || 'successfully')
						fetchProduct();
					}
				})
			},
			onCancel() {
				console.log('Cancel');
			},
		});
	}

	const handleProductPromotion = (values) => {
		const body = {
			productId: productSelected.id,
			price: values.price,
			minimum_quantity: values.minimum,
			expiration_date: moment(values.endDate).format('YYYY-MM-DD'),
			areaId: values.areaId,
			limit_of_use: +values.limitUse,
			limit_of_users: +values.users,
		}
		Api.post('products/add/promotion', body).then(res => {
			if (res.data.validation.length > 0) {
				message.error(res.data.validation[0])
			}
			else {
				message.success(res.data.message || 'successfully')
				fetchProduct();
			}

			setIsModalVisible(false)
		})
	}

	const handleFilterList = (value) => {
		if (!!value) {
			let salesFiltered = productListPaginate?.products.filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
			setProductList(salesFiltered)
		} else {
			fetchProduct()
		}
	}

	return (
		<div className='product__wrapper'>
			<div className='product__wrapper--header'>
				<p>العروض: {productListPaginate.promotions_count}</p>
				<p>المنتجات: {productListPaginate.products_count}</p>
			</div>
			<TableGrid title={
				<div className='table__filter'>
					<p className='table__title'> All products <span> كل المنتجات </span></p>
					<div className='table__filter--count'>
						<p>Show</p>
						<Select
							defaultValue={defaultCurrentVal}
							placeholder="Lead status"
							onChange={(value) => {
								setDefaultCurrentVal(value);
								fetchProduct(null, value)
							}}
						>
							<Option value={10}> 10 </Option>
							<Option value={20}> 20 </Option>
							<Option value={30}> 30 </Option>
							<Option value={40}> 40 </Option>
						</Select>
						<span>entries</span>
					</div>
					<div className='search__list--input'>
						<Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
					</div>
					<div className='product__btn'>
						<Button className='secondary' onClick={() => history.push('/profile/priceList')}>قائمة الاسعار</Button>
						<Button className='primary' onClick={() => history.push({
							pathname: '/profile/product-add', state: null
						})}>New Product منتج جديد</Button>
					</div>
				</div>
			}
				columns={columns}
				dataSource={productList}
				pageSize={30}
				total={productListPaginate.products_count}
				loading={loading}
				onChange={(pagination) => {
					let url = `products?page=${pagination?.current}`
					fetchProduct(url)
				}}
			/>

			<Modal className="add__record--modal"
				footer={null}
				title='ترقية المنتج'
				visible={isModalVisible}
				onOk={() => setIsModalVisible(false)}
				onCancel={() => setIsModalVisible(false)}>

				<Form form={form} name="personalInfo" className='form__wrapper'
					onFinish={handleProductPromotion}
					// onFinishFailed={onFinishFailed}
					autoComplete="off"
				>
					<Form.Item
						label="سعر المنتج"
						name="price"
						rules={[
							{ required: true, message: t.ThisFieldisrequired },
							{ pattern: /^\d+$/, message: 'must be numbers only!' }
						]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label="الحد الادنى للترقية"
						name="minimum"
						rules={[
							{ required: true, message: t.ThisFieldisrequired },
							{ pattern: /^\d+$/, message: 'must be numbers only!' }
						]}
					>
						<Input />
					</Form.Item>


					<Form.Item
						label="الحد الادنى للاستخدام"
						name="limitUse"
						rules={[
							{ required: true, message: t.ThisFieldisrequired },
							{ pattern: /^\d+$/, message: 'must be numbers only!' }
						]}
					>
						<Input type='number' />
					</Form.Item>

					<Form.Item
						label="الحد الادنى للمستخدمين"
						name="users"
						rules={[
							{ required: true, message: t.ThisFieldisrequired },
							{ pattern: /^\d+$/, message: 'must be numbers only!' }
						]}
					>
						<Input type='number' />
					</Form.Item>

					<Form.Item
						label="الحد الادنى للترقية"
						name="areaId"
						rules={[{ required: true, message: t.ThisFieldisrequired }]}
					>
						<Select>
							{areaList.map((area) => (
								<Option key={area.id}>
									{area.name_en} - {area.name_ar}
								</Option>
							))}
						</Select>
					</Form.Item>

					<Form.Item
						label="نهاية صلاحية الترقية"
						name="endDate"
						rules={[{ required: true, message: t.ThisFieldisrequired }]}
					>
						<DatePicker />
					</Form.Item>


					<Form.Item>
						<Button type="primary" htmlType="submit">
							حفظ
							<span>&#10004;</span>
						</Button>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};
