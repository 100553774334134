import { ADD_PRODUCT, ITEMS_LIST, INCRESE_PRODUCT, EDIT_PRODUCT, SET_FILTERD_LIST } from "./cart.type";

const INITIAL_STATE = {
  cartItems: [],
  itemsList: [],
};
const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_PRODUCT:
      return {
        ...state,
        cartItems: [action.payload, ...state.cartItems],
      };
    case EDIT_PRODUCT:
      return handelEditItem(state, action.payload);
    case INCRESE_PRODUCT:
      return {
        ...state,
        itemsList: action.payload,
      };
    case ITEMS_LIST:
      return {
        ...state,
        itemsList: action.payload,
      };
    case SET_FILTERD_LIST:
      return {
        ...state,
        itemsList: action.payload,
      };
    default:
      return state;
  }
};


function handelEditItem(state, payload) {
  let selectedItemInd = state.cartItems.findIndex(ele => ele.id === payload.id);
  state.cartItems[selectedItemInd].quantaty = payload.quantaty
  return {
      ...state,  cartItems: [...state.cartItems]
  }
}

export default cartReducer;
