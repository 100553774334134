import React, { Component } from "react";
import ProtectedRoute from "./ProtectedRoute";
import { Route } from "react-router-dom";
import login from "./pages/Login/login";
// import Header from "./components/Header/Header";
// import Footer from "./components/Footer/Footer";
// import ResNavBar from "./components/ResNavBar/ResNavBar";
import { Order } from "./pages/Orders/index";
import { Dashboard } from "./pages/Dashboard/index";
import { ProductRfq } from "./pages/profile/ProductRFQ/index";
import { OrderDetails } from "./pages/Orders/OrderDetails/index";
import { SubscriptionHistory } from "./pages/subscription/History/index";
import { MySubscription } from "./pages/subscription/mySubscription/index";
import { UserGroup } from "./pages/profile/UserGroup/index";
import { PersonalInfo } from "./pages/profile/PersonalInfo/index";
import { UserLeads } from "./pages/Reports/UserLeads/index";
import { AllProduct } from "./pages/profile/AllProducts/index";
import { AddProduct } from "./pages/profile/AllProducts/AddProduct/index";
import { SubCategories } from "./pages/profile/subCategories/index";
import { AssignSubCategories } from "./pages/profile/subCategories/AssignCategories/index";
import { ExtrasPurchase } from "./pages/subscription/Extras";
import { PriceListPage } from "./pages/profile/PriceList";
import { CompanyInfo } from "./pages/profile/CompanyInfo";
import { NewsFeed } from "./pages/profile/NewsFeed";
import { Message } from "./pages/Messages";
import { SalesTeams } from "./pages/profile/SalesTeam";
import { AddEditSalesTeam } from "./pages/profile/SalesTeam/addEditSalesTeam";
import { CancelationReport } from "./pages/Reports/CallBack";
import { InfoViewed } from "./pages/Reports/InfoViewed";
import { MyHistorySub } from "./pages/subscription/MyHistorySub";
import OrderInfo from "./pages/OrderInfo/OrderInfo";
import { Chauffeurs } from "./pages/Reports/Chauffeurs";
import Notification from "./pages/Notification/Notification";
import { AddChauffeur } from "./pages/AddChauffeur";
import { Offers } from "./pages/Reports/Offers";
import { Countries } from "./pages/Reports/Countries";
import { Cities } from "./pages/Reports/Cities";
import { AddOffer } from "./pages/AddOffer";
import { Companies } from "./pages/Reports/Companies";
import { AddCompany } from "./pages/AddCompany";
import { FAQCategories } from "./pages/Reports/FAQCategories";
import { FAQ } from "./pages/Reports/FAQ";
import { AddCountry } from "./pages/AddCountry";
import { AddCity } from "./pages/AddCity";
import { AddFAQCategory } from "./pages/AddFAQCategory";
import { AddFaq } from "./pages/AddFaq";
import { Terms } from "./pages/Reports/Terms";
import AddTerms from "./pages/AddTerms/AddTerms";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import PhoneVerify from "./pages/PhoneVerify/PhoneVerify";
import { ActiveAndInActiveCustomersReport } from "./pages/Reports/ActiveAndInActiveCustomersReport/ActiveAndInActiveCustomersReport";
import { ActiveAndInActiveDeliveriesReport } from "./pages/Reports/ActiveAndInActiveDeliveriesReport/ActiveAndInActiveDeliveriesReport";
import { SatisfactionReport } from "./pages/Reports/SatisfactionReport/SatisfactionReport";
import { PaymentReport } from "./pages/Reports/PaymentReport/PaymentReport";
import { CompaniesOrdersActivites } from "./pages/Reports/CompaniesOrdersActivites/CompaniesOrdersActivites";
import { OrderSLA } from "./pages/Reports/OrderSLA/OrderSLA";
import { ContactUs } from "./pages/ContactUs/ContactUs";
import { ComplaintAndSuggestions } from "./pages/ComplaintAndSuggestions/ComplaintAndSuggestions";
import { Advertisements } from "./pages/Advertisements/Advertisements";
import { AddAdvertisement } from "./pages/AddAdvertisement/AddAdvertisement";
import { Blogs } from "./pages/Reports/Blogs";
import { BlogsReports } from "./pages/Reports/BlogReports";
import AddBlog from "./pages/AddBlog/AddBlog";
import { Newsletter } from "./pages/Newsletter/Newsletter";
import { SendCoupon } from "./pages/Coupon/SendCoupon";
import { GenerateCoupon } from "./pages/Coupon/GenerateCoupon";
import AddCoupon from "./pages/Coupon/AddCoupon";
import { NotificationTemplate } from "./pages/NotificationTemplate/notificationTemplate";
import { EmailTemplate } from "./pages/EmailTemplate/EmailTemplate";
import { PaymentLogs } from "./pages/PaymentLogs/PaymentLogs";

class Routes extends Component {
  render() {
    return (
      <>
        <div className="page-wrapper app-wrapper">
          {/* <Header />  */}
          <div className="page-main">
            <div className="container">
              <Route exact path="/login" component={login} />
              <Route
                exact
                path="/forget-password?"
                component={ForgetPassword}
              />
              <Route
                exact
                path="/phone-verification?"
                component={PhoneVerify}
              />
              <Route exact path="/" component={Dashboard} />
              <Route exact path="/order" component={Order} />
              <Route exact path="/chauffeurs" component={Chauffeurs} />
              <Route exact path="/add-company" component={AddCompany} />
              <Route exact path="/companies" component={Companies} />
              <Route exact path="/contact-us" component={ContactUs} />
              <Route
                exact
                path="/complaint-suggestions"
                component={ComplaintAndSuggestions}
              />
              <Route exact path="/advertisements" component={Advertisements} />
              <Route
                exact
                path="/add-advertisement"
                component={AddAdvertisement}
              />
              <ProtectedRoute
                exact
                path="/packages"
                component={SubCategories}
              />
              <ProtectedRoute exact path="/offers" component={Offers} />
              <ProtectedRoute exact path="/offers/add" component={AddOffer} />
              <ProtectedRoute
                exact
                path="/notification"
                component={Notification}
              />
              <ProtectedRoute
                exact
                path="/packages/add"
                component={AssignSubCategories}
              />

              {/* start settings links */}
              <ProtectedRoute exact path="/countries" component={Countries} />
              <ProtectedRoute
                exact
                path="/add-country"
                component={AddCountry}
              />
              <ProtectedRoute exact path="/cities" component={Cities} />
              <ProtectedRoute exact path="/add-city" component={AddCity} />
              <ProtectedRoute exact path="/faq" component={FAQ} />
              <ProtectedRoute exact path="/add-faq" component={AddFaq} />
              <ProtectedRoute
                exact
                path="/faq-category"
                component={FAQCategories}
              />
              <ProtectedRoute
                exact
                path="/add-faq-category"
                component={AddFAQCategory}
              />
              <ProtectedRoute exact path="/terms" component={Terms} />
              <ProtectedRoute exact path="/blogs" component={Blogs} />
              <ProtectedRoute
                exact
                path="/reports/BlogReports"
                component={BlogsReports}
              />
              <ProtectedRoute exact path="/add-term" component={AddTerms} />
              <ProtectedRoute exact path="/add-blog" component={AddBlog} />

              <Route exact path="/dashboard" component={Dashboard} />
              <ProtectedRoute
                exact
                path="/ordersDetails"
                component={OrderInfo}
              />
              <ProtectedRoute
                exact
                path="/ordersDetails/:id"
                component={OrderInfo}
              />

              <ProtectedRoute
                exact
                path="/reports/cancelationReport"
                component={CancelationReport}
              />
              <ProtectedRoute
                exact
                path="/reports/activeAndInActiveCustomersReport"
                component={ActiveAndInActiveCustomersReport}
              />
              <ProtectedRoute
                exact
                path="/reports/activeAndInActiveDeliveriesReport"
                component={ActiveAndInActiveDeliveriesReport}
              />
              <ProtectedRoute
                exact
                path="/reports/SatisfactionReport"
                component={SatisfactionReport}
              />
              <ProtectedRoute
                exact
                path="/reports/CompaniesOrdersActivites"
                component={CompaniesOrdersActivites}
              />
              <ProtectedRoute
                exact
                path="/reports/PaymentReport"
                component={PaymentReport}
              />
              <ProtectedRoute
                exact
                path="/reports/OrderSLA"
                component={OrderSLA}
              />
              <ProtectedRoute
                exact
                path="/profile/PersonalInfo"
                component={PersonalInfo}
              />
              <ProtectedRoute
                exact
                path="/profile/all-product"
                component={AllProduct}
              />
              <ProtectedRoute
                exact
                path="/profile/sub-categories"
                component={SubCategories}
              />
              <ProtectedRoute
                exact
                path="/profile/sub-categories/assign"
                component={AssignSubCategories}
              />
              <ProtectedRoute
                exact
                path="/profile/product-add"
                component={AddProduct}
              />
              <ProtectedRoute
                exact
                path="/profile/priceList"
                component={PriceListPage}
              />
              <ProtectedRoute
                exact
                path="/profile/company-info"
                component={CompanyInfo}
              />
              <ProtectedRoute
                exact
                path="/profile/newsFeed"
                component={NewsFeed}
              />
              <ProtectedRoute
                exact
                path="/profile/sales-team"
                component={SalesTeams}
              />
              <ProtectedRoute
                exact
                path="/profile/add-sales-team"
                component={AddEditSalesTeam}
              />

              <ProtectedRoute exact path="/messages" component={Message} />
              <ProtectedRoute exact path="/newsletter" component={Newsletter} />
              <ProtectedRoute
                exact
                path="/coupon/sendCoupon"
                component={SendCoupon}
              />
              <ProtectedRoute
                exact
                path="/coupon/generateCoupon"
                component={GenerateCoupon}
              />
              <ProtectedRoute
                exact
                path="/coupon/addCoupon"
                component={AddCoupon}
              />
              <ProtectedRoute
                exact
                path="/notificationTemplate"
                component={NotificationTemplate}
              />
              <ProtectedRoute
                exact
                path="/emailTemplate"
                component={EmailTemplate}
              />
              <ProtectedRoute
                exact
                path="/paymentLogs"
                component={PaymentLogs}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Routes;
