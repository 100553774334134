import { Badge, Layout, Menu } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  CreditCardOutlined,
  NotificationOutlined,
  MailOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Logo from "../../../images/logo.svg";
import logoSm from "../../../images/logoSm.svg";
import {
  FaMapMarkedAlt,
  FaUserTie,
  FaPercent,
  FaTruckMoving,
  FaPrint,
  FaPhoneAlt,
  FaMailBulk,
  FaNewspaper,
} from "react-icons/fa";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { AiFillSetting } from "react-icons/ai";
import { useSelector } from "react-redux";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { PaymentLogs } from "../../PaymentLogs/PaymentLogs";

const { SubMenu } = Menu;
const { Sider } = Layout;

const rootSubmenuKeys = [
  "Dashboard",
  "Profile",
  "Reports",
  "2",
  "Subscription",
  "Orders",
];

export const SideBar = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [openKeysState, setOpenKeys] = useState(["Dashboard"]);
  const toggle = () => setCollapsed(!collapsed);
  const history = useHistory();
  const { pathname } = useLocation();
  const handleRouteToComponent = (route) => history.push(route);
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  useEffect(() => {
    if (document.getElementsByClassName("ant-layout-content")[0])
      document.getElementsByClassName("ant-layout-content")[0].scrollTop = 0;
  }, [pathname]);

  const onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => openKeysState.indexOf(key) === -1
    );

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) setOpenKeys(openKeys);
    else setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleCloseSubMenu = (openKey) => setOpenKeys([openKey]);

  return (
    <Sider
      className={collapsed ? "collapsed" : "open"}
      trigger={null}
      collapsible
      collapsed={collapsed}
    >
      <div className="logo__wrapper">
        <p>
          {collapsed ? (
            <img src={logoSm} alt="Logo" />
          ) : (
            <img src={Logo} alt="logo" />
          )}
        </p>
        <span className="collapse-icon" onClick={toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </span>
      </div>
      <Menu
        openKeys={openKeysState}
        onOpenChange={onOpenChange}
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
      >
        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/dashboard");
            handleCloseSubMenu("Dashboard");
          }}
          key="Dashboard"
          icon={<img src="/images/leftMenu/dashboard.svg" alt="Dashboard" />}
        >
          <p>{t.Dashboard}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/advertisements");
            handleCloseSubMenu("advertisements");
          }}
          key="advertisements"
          icon={<BsFillMegaphoneFill />}
        >
          <p>{t.Advertisements}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/contact-us");
            handleCloseSubMenu("contact-us");
          }}
          key="contact-us"
          icon={<FaPhoneAlt />}
        >
          <p>{t.contactUs}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/complaint-suggestions");
            handleCloseSubMenu("suggestions");
          }}
          key="suggestions"
          icon={<FaMailBulk />}
        >
          <p>{t.ComplaintAndSuggestions}</p>
        </Menu.Item>

        <SubMenu
          key="settings"
          icon={<AiFillSetting />}
          title={<p>{t.Settings}</p>}
        >
          <Menu.Item
            onClick={() => handleRouteToComponent("/countries")}
            key="countries"
          >
            <p>{t.countries}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/cities")}
            key="cities"
          >
            <p>{t.cities}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/faq-category")}
            key="faq-category"
          >
            <p>{t.FAQCategories}</p>
          </Menu.Item>
          <Menu.Item onClick={() => handleRouteToComponent("/faq")} key="faq">
            <p>{t.FAQ}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/terms")}
            key="terms"
          >
            <p>{t.TermsAndConditions}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/blogs")}
            key="blogs"
          >
            <p>{t.Blogs}</p>
          </Menu.Item>
          {/* <Menu.Item onClick={() => handleRouteToComponent("/complaints")} key="Complaints">
						<p>Complaints And Suggestions <span>الشكاوي والاقتراحات</span></p>
					</Menu.Item> */}
        </SubMenu>

        {/* <SubMenu key="Reports" icon={<img src='/images/leftMenu/reports.svg' alt='Reports' />} title={<p>Reports <span>التقارير</span> </p>}>
					<Menu.Item onClick={() => handleRouteToComponent("/reports/userLeads")} key="Unique">
						<p>Unique Leads <span>العملاء</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/reports/info-view")} key="Viewed">
						<p> Info Viewed <span>اظهار البيانات</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/reports/callBack")} key="Call">
						<p>Call back <span>طلب الاتصال</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/reports/product")} key="RFQ">
						<p>Product RFQ <span>طلب سعر المنتج</span></p>
					</Menu.Item>
				</SubMenu> */}

        {/* <Menu.Item onClick={() => {
					handleRouteToComponent("/messages");
					handleCloseSubMenu('Message')
				}} key="Message" icon={<img src='/images/leftMenu/Message.svg' alt='Message' />}>
					<p>Messages <span>الرسائل</span></p>
				</Menu.Item> */}

        {/* <SubMenu key="Subscription" icon={<img src='/images/leftMenu/subscriptions.svg' alt='Subscriptions' />} title={<p>Subscription <span>الاشتراك</span> </p>}>
					<Menu.Item onClick={() => handleRouteToComponent("/subscription/mySubscription")} key="mySubscription">
						<p>My Subscription <span>اشتراكي</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/subscription/purchase")} key="Extras">
						<p> Extras <span>باقات اضافية</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/subscription/history")} key="History">
						<p>History <span>تفاصيل المعاملات</span></p>
					</Menu.Item>
					<Menu.Item onClick={() => handleRouteToComponent("/subscription/my-history-sub")} key="SubscriptionHistory">
						<p><span style={{ lineHeight: '16px' }}> Subscription <br /> History </span><span>الاشتراكات السابقة</span></p>
					</Menu.Item>
				</SubMenu> */}
        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/order");
            handleCloseSubMenu("Orders");
          }}
          key="Orders"
          icon={<FaMapMarkedAlt />}
        >
          <p>{t.Orders}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/paymentLogs");
          }}
          key="paymentLogs"
          icon={<DatabaseOutlined />}
        >
          <p>{t.PaymentLogs}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/companies");
            handleCloseSubMenu("companies");
          }}
          icon={<FaTruckMoving />}
          key="companies"
        >
          <p>{t.Companies}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/Chauffeurs");
            handleCloseSubMenu("Chauffeurs");
          }}
          icon={<FaUserTie />}
          key="Chauffeurs"
        >
          <p>{t.Chauffeurs}</p>
        </Menu.Item>

        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/offers");
            handleCloseSubMenu("offers");
          }}
          icon={<FaPercent />}
          key="offers"
        >
          <p>{t.offers}</p>
        </Menu.Item>
        {/* 
				<Menu.Item
				onClick={() => {
					handleRouteToComponent('/packages');
					handleCloseSubMenu('packages')
				}} 
				icon={<FaBoxes />} key="packages">
					<p>packages <span>الحزم</span></p>
				</Menu.Item> */}

        {/* <Menu.Item
				onClick={() => {
					handleRouteToComponent('/reports/userLeads');
					handleCloseSubMenu('Customers')
				}} 
				icon={<FaUserFriends />} key="Customers">
					<p>Customers <span>العملاء</span></p>
				</Menu.Item> */}

        <SubMenu key="Reports" icon={<FaPrint />} title={<p>{t.Reports}</p>}>
          <Menu.Item
            onClick={() => handleRouteToComponent("/reports/cancelationReport")}
            key="cancelationReport"
          >
            <p>{t.cancellations}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              handleRouteToComponent(
                "/reports/activeAndInActiveCustomersReport"
              )
            }
            key="ActiveAndInActiveCustomersReport"
          >
            <p>{t.activeAndInActiveCustomersReport}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              handleRouteToComponent(
                "/reports/activeAndInActiveDeliveriesReport"
              )
            }
            key="ActiveAndInActiveDeliveriesReport"
          >
            <p>{t.activeAndInActiveDeliveriesReport}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              handleRouteToComponent("/reports/SatisfactionReport")
            }
            key="SatisfactionReport"
          >
            <p>{t.Satisfactions}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/reports/PaymentReport")}
            key="PaymentReport"
          >
            <p>{t.Payments}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() =>
              handleRouteToComponent("/reports/CompaniesOrdersActivites")
            }
            key="CompaniesOrdersActivites"
          >
            <p>{t.CompaniesOrdersActivites}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/reports/OrderSLA")}
            key="OrderSLA"
          >
            <p>{t.OrderSLA}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/reports/BlogReports")}
            key="BlogReports"
          >
            <p>{t.Blogs}</p>
          </Menu.Item>
          {/* <Menu.Item onClick={() => handleRouteToComponent("/complaints")} key="Complaints">
						<p>Complaints And Suggestions <span>الشكاوي والاقتراحات</span></p>
					</Menu.Item> */}
        </SubMenu>
        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/newsletter");
            handleCloseSubMenu("newsletter");
          }}
          key="newsletter"
          icon={<FaNewspaper />}
        >
          <p>{t.Newsletter}</p>
        </Menu.Item>
        <SubMenu key="Coupon" icon={<FaPrint />} title={<p>{t.Coupon}</p>}>
          <Menu.Item
            onClick={() => handleRouteToComponent("/coupon/generateCoupon")}
            key="generateCoupon"
          >
            <p>{t.generateCoupon}</p>
          </Menu.Item>
          <Menu.Item
            onClick={() => handleRouteToComponent("/coupon/sendCoupon")}
            key="sendCoupon"
          >
            <p>{t.sendCoupon}</p>
          </Menu.Item>
        </SubMenu>

        {/* <Menu.Item
          onClick={() => {
            handleRouteToComponent("/coupon");
            handleCloseSubMenu("coupon");
          }}
          key="coupon"
          icon={<CreditCardOutlined />}
        >
          <p>{t.Coupon}</p>
        </Menu.Item> */}
        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/notificationTemplate");
            handleCloseSubMenu("notificationTemplate");
          }}
          key="notificationTemplate"
          icon={<NotificationOutlined />}
        >
          <p>{t.NotificationTemplate}</p>
        </Menu.Item>
        <Menu.Item
          onClick={() => {
            handleRouteToComponent("/emailTemplate");
          }}
          key="emailTemplate"
          icon={<MailOutlined />}
        >
          <p>{t.EmailTemplate}</p>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
