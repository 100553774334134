import axios from "axios";

const url = "https://chauffeur-be-prod-261156411298.us-central1.run.app/api/";

export const userLogin = (body, onSucess, onFail) => {
  axios({
    method: "post",
    url: `${url}AdminAPIs/AdminLogin`,
    data: body,
    headers: { "Content-Type": "application/json;" },
  })
    .then((response) => onSucess(response))
    .catch((err) => onFail(err));
};
