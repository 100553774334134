import React, { useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Modal,
  Rate,
  Select,
  Tag,
  Menu,
  Dropdown,
} from "antd";
import cls from "classnames";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../Network/ApiConfig";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { FaMapMarkedAlt, FaUserTie } from "react-icons/fa";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setMetaData } from "../../redux/user/user.action";
import en from "../../localization/en";
import ar from "../../localization/ar";
import dayjs from "dayjs";

const { confirm } = Modal;

const { Option } = Select;

export const Order = () => {
  const [ordersList, setOrderList] = useState([]);
  const [captainList, setCaptainList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [ordersListPaginate, setOrdersListPaginate] = useState([]);
  const [loading, setLoading] = useState(false);
  const [ActiveOrder, setActiveOrder] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [assignloading, setassignloading] = useState(false);
  const [orderStatus, setOrderStatus] = useState([]);
  const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
  const [generatePaymentLinkLoading, setgeneratePaymentLinkLoading] =
    useState(false);
  const [paymentLink, setpaymentLink] = useState();
  const [paymentLinkStatus, setpaymentLinkStatus] = useState();
  const [page, setpage] = useState(0);

  const dispatch = useDispatch();
  const { metaData } = useSelector((state) => state?.user);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [record, setrecord] = useState();
  const [cancelReason, setCancelReason] = useState("");
  const [companyModalPrice, setcompanyModalPrice] = useState();
  const [updateModal, setupdateModal] = useState(false);
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const [formReassignCompany] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
    // fetchCaptainList();
    fetchCompanyList();
  };

  const handleCancel = () => {
    setActiveOrder(null);
    setIsModalVisible(false);
    setOpenCancel(false);
    setcompanyModalPrice();
    setCompanyList();
    setCancelReason();
    setpaymentLink();
    setrecord();
    setupdateModal(false);
    formReassignCompany.resetFields();
  };
  const fetchOrderList = (index = 0) => {
    // let body = {
    //   pagination_number: pagSize ? pagSize : defaultCurrentVal
    // }
    setLoading(true);
    setcompanyModalPrice();
    // Api.get('Companies/GetCompanyOrders?OrderStatusIdint=0&Page=0').then(res => {
    Api.get(`AdminAPIs/GetOrders?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          setOrderList(res?.data?.model);
          if (res?.data?.metas?.TotalaCount) {
            setTotalCount(res?.data?.metas?.TotalaCount);
          }
          // setOrdersListPaginate(res.data.data)
          setLoading(false);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const fetchCaptainList = () => {
    Api.get("Delivery/GetCompanyDeliveries").then(
      (res) => {
        if (res?.status === 200) {
          setCaptainList(res?.data?.model);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const fetchCompanyList = (record) => {
    setcompanyModalPrice();
    try {
      Api.get(
        `AdminAPIs/GetDeliveryCompanies?FromCustomerAddressId=${record?.FromCustomerAddressId}&ToCustomerAddressId=${record?.ToCustomerAddressId}&PackgeSizeId=${record?.PackgeSizeId}&DeliveryTypeId=${record?.ServiceDeliveryTypeId}`
      ).then(
        (res) => {
          if (res?.status === 200) {
            setCompanyList(res?.data?.model);
          }
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
      Api.get(`AdminAPIs/GetPaymentLinkByOrder?OrderId=${record?.Id}`).then(
        (res) => {
          if (res?.status === 200) {
            const data = res?.data?.model;
            setpaymentLink(data?.PaymentLink);
            if (data?.PaymentLogStatusId == 1) {
              setpaymentLinkStatus("Pending");
            } else if (data?.PaymentLogStatusId == 2) {
              setpaymentLinkStatus("Cancelled");
            } else if (data?.PaymentLogStatusId == 3) {
              setpaymentLinkStatus("Completed");
            }
          }
        },
        (err) => {
          message.error(err?.message);
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchOrderList();
    // handleGetStatusOrder();

    Api.get("MetaData/GetMetaData").then(
      (res) => {
        dispatch(setMetaData(res?.data?.model));
      },
      (err) => {
        // console.log(err, "errrrrrrrrrrrrrrrrrrrrrrrrrrr")
      }
    );
  }, [currentLang]);

  const handleShowOrderDetails = (order) => {
    Api.get(`orders/show?orderId=${order.Id}`).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        history.push({
          pathname: "/ordersDetails",
          state: res.data.data.order_details,
        });
      }
    });
  };

  const handleGetStatusOrder = (order) => {
    Api.get(`orders/orderStatus`).then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        setOrderStatus(res.data.data.orderStatus);
      }
    });
  };
  const handleCancelOrder = (id) => {
    // confirm({
    //   title: `هل أنت متأكد من إلغاء هذا الطلب ؟`,
    //   // title: localization.DodeletetheseAddress[this.props.currentLang],
    //   icon: <ExclamationCircleOutlined />,
    //   okText: t.Ok,
    //   cancelText: t.Cancel,
    //   onOk: () => {
    //     Api.get(
    //       `Companies/UpdateOrderStatus?OrderId=${id}&OrderStatusId=5`
    //     ).then(
    //       (res) => {
    //         if (res?.status === 200) {
    //           fetchOrderList();
    //         }
    //         setLoading(false);
    //       },
    //       (err) => {
    //         setLoading(false);
    //       }
    //     );
    //   },
    //   onCancel() {},
    // });
  };

  const handleChangeOrderStatus = (id, rec) => {
    Api.get(
      `Orders/UpdateOrderStatus?OrderId=${rec?.Id}&OrderStatusId=${id}`
    ).then(
      (res) => {
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
          // fetchOrderList();
        }
      },
      (err) => {
        message.error(err?.message);
      }
    );
  };

  const handlAssignDelivery = (values) => {
    setassignloading(true);
    Api.get(
      `AdminAPIs/UpdateOrder?OrderId=${
        record?.Id
      }&OrderStatusId=${2}&DeliveryPackageId=${companyModalPrice?.Id}`
    ).then(
      (res) => {
        setassignloading(false);
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
          setIsModalVisible(false);
          fetchOrderList();
          setcompanyModalPrice();
        }
      },
      (err) => {
        setassignloading(false);
        message.error(err?.message);
        setcompanyModalPrice();
      }
    );
  };

  const history = useHistory();

  // Array of OrderStatusIds for which CancelOrder should not be shown
  const restrictedStatuses = [8, 10];
  const reassignOrderStatuses = [2, 5, 9];
  const updateOrderStatuses = [4, 6, 7];

  const columns = [
    {
      title: <p className="table-header-text"> {t.OrderID}</p>,
      dataIndex: "id",
      render: (_, record) => (
        <a
          onClick={() => {
            history.push({
              pathname: `/ordersDetails/${record?.Id}`,
              state: record,
            });
          }}
        >
          {" "}
          #{record?.Id}
        </a>
      ),
    },
    {
      title: <p className="table-header-text"> {t.User}</p>,
      render: (_, record) => (
        <div className="col-name-holder">
          {" "}
          <span>{record?.Customer?.AspNetUsers?.FullName} </span>
          {record?.CustomerRate?.RateValue ? (
            <Rate disabled value={record?.CustomerRate?.RateValue} />
          ) : null}{" "}
        </div>
      ),
    },
    {
      title: <p className="table-header-text"> {t.total}</p>,
      dataIndex: "Total",
      render: (_, record) => (
        <span>
          {record?.Total} {t.SAR}{" "}
        </span>
      ),
    },
    {
      title: <p className="table-header-text"> {t.Status}</p>,
      dataIndex: "order_status",
      render: (_, record) => {
        return (
          <div>
            <Tag
              className={`order__status--tag ${record?.OrderStatus?.Name?.split(
                " "
              )
                .join("")
                ?.toLowerCase()}`}
            >
              {currentLang === "en"
                ? record?.OrderStatus?.Name
                : record?.OrderStatus?.NameLT}
            </Tag>
          </div>
        );
      },
    },
    {
      title: <p className="table-header-text"> {t.datePlaced}</p>,
      dataIndex: "CreationDate",
      render: (_, record) => {
        return (
          <p>
            {" "}
            {dayjs.utc(record?.CreationDate).local().format("YYYY-MM-DD hh:mm")}
          </p>
        );
      },
    },

    {
      title: <p className="table-header-text"> {t.Driver}</p>,
      dataIndex: "Delivery",
      render: (_, record) => {
        return record?.Delivery ? (
          <div className="col-name-holder">
            {" "}
            <span>{record?.Delivery?.Name} </span>
            {record?.DeliveryRate?.RateValue ? (
              <Rate disabled value={record?.DeliveryRate?.RateValue} />
            ) : null}{" "}
          </div>
        ) : (
          // <div className='delivery-col-holder'>
          //   <p>{record?.Delivery?.Name}</p>
          //   <Avatar size={24} src={record?.Delivery?.Image} />
          //   </div>
          <span style={{ color: "#fc4242" }}>{t.Notassignedtodriver}</span>
        );
        // return (
        //   record?.OrderStatusId !== 5 ?
        //   !record?.Delivery ?
        //   <div className="order__actions">
        //     <span style={{color : "#612166"}} onClick={() => {
        //       // handlAssignDelivery(record)
        //       showModal()
        //       setActiveOrder(record)
        //     }}>Assign Delivery</span>
        //     </div> :
        //     <div className='delivery-col-holder'>
        //     <p>{record?.Delivery?.NameLT}</p>
        //     <Avatar size={24} src={record?.Delivery?.Image} />
        //     </div> : null
        // );
      },
    },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      render: (record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  key="1"
                  onClick={(e) => {
                    handleMenuChange(e, record);
                  }}
                >
                  {t.ViewDetails}
                </Menu.Item>
                {reassignOrderStatuses.includes(record?.OrderStatusId) && (
                  <Menu.Item
                    key="2"
                    onClick={(e) => {
                      handleMenuChange(e, record);
                    }}
                  >
                    {t.ReassignCompany}
                  </Menu.Item>
                )}
                {updateOrderStatuses.includes(record?.OrderStatusId) && (
                  <Menu.Item
                    key="3"
                    onClick={(e) => {
                      handleMenuChange(e, record);
                    }}
                  >
                    {t.UpdateOrder}
                  </Menu.Item>
                )}
                {!restrictedStatuses.includes(record?.OrderStatusId) && (
                  <Menu.Item
                    key="4"
                    onClick={(e) => {
                      handleMenuChange(e, record);
                    }}
                  >
                    {t.CancelOrder}
                  </Menu.Item>
                )}
              </Menu>
            }
            trigger={["hover"]}
          >
            <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
          </Dropdown>
        );
      },
    },
  ];

  const handleOkOrderCancel = () => {
    setLoading(true);
    const { Id, OrderStatusId, PackgeSizeId } = record;
    Api.get(
      `AdminAPIs/UpdateOrder?OrderId=${Id}&OrderStatusId=${10}&DeliveryPackageId=&CancelationReason=${cancelReason}`
    ).then(
      (res) => {
        if (res?.status === 200) {
          fetchOrderList();
          setOpenCancel(false);
          message.success(res?.data?.metas?.message);
        }
      },
      (err) => {
        message.error(err?.message);
      }
    );
    setLoading(false);
  };
  const handleMenuChange = (key, record) => {
    if (key?.key == 1) {
      history.push({
        pathname: `/ordersDetails/${record?.Id}`,
        state: record,
      });
    } else if (key?.key == 2) {
      setrecord(record);
      fetchCompanyList(record);
      setIsModalVisible(true);
    } else if (key?.key == 3) {
      setrecord(record);
      setupdateModal(true);
    } else if (key?.key == 4) {
      setrecord(record);
      setOpenCancel(true);
    }
  };

  const handleFilterList = (value) => {
    if (!!value) {
      let salesFiltered = ordersListPaginate?.orders?.filter((i) =>
        i.user_name?.toLowerCase().includes(value?.toLowerCase())
      );
      setOrderList(salesFiltered);
    } else {
      fetchOrderList();
    }
  };

  const OnchangeCompanyList = async (e) => {
    const json = JSON.parse(e);
    setcompanyModalPrice({ price: json.Price, ...json });
  };

  const handleOkOrderPaymentLink = (extraPaymentAmt) => {
    setgeneratePaymentLinkLoading(true);
    const { Id, OrderStatusId, PackgeSizeId } = record;
    const body = {
      OrderId: Id,
      Amount: extraPaymentAmt,
    };
    Api.post(`AdminAPIs/GeneratePaymentLinkAndLog`, body).then(
      (res) => {
        if (res?.status === 200) {
          setLoading(false);
          setpaymentLink(res?.data?.model?.PaymentLink);
          message.success(
            res?.data?.metas?.message + " And Email has been sent"
          );
          setgeneratePaymentLinkLoading(false);
        } else {
          message.error(res?.message);
          setgeneratePaymentLinkLoading(false);
          setLoading(false);
        }
      },
      (err) => {
        message.error(err?.message);
        setgeneratePaymentLinkLoading(false);
        setLoading(false);
      }
    );
  };

  const handleOkOrderUpdate = () => {
    setLoading(true);
    const { Id, OrderStatusId, PackgeSizeId } = record;
    let updateId = 0;
    if (OrderStatusId == 4) updateId = "6";
    else if (OrderStatusId == 6) updateId = "7";
    else if (OrderStatusId == 7) updateId = "8";
    Api.get(
      `AdminAPIs/UpdateOrder?OrderId=${Id}&OrderStatusId=${updateId}`
    ).then(
      (res) => {
        if (res?.status === 200) {
          fetchOrderList();
          setOpenCancel(false);
          message.success(res?.data?.metas?.message);
          setLoading(false);
        }
        message.error(res?.message);
      },
      (err) => {
        message.error(err?.message);
        setLoading(false);
      }
    );
    setLoading(false);
  };
  console.log("company", companyModalPrice, record);

  return (
    <div className="order__wrapper">
      <Modal
        title="Update Order Confirmation"
        visible={updateModal}
        destroyOnClose
        onOk={() => handleOkOrderUpdate()}
        okButtonProps={{ loading: loading }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Do you want to update this order to </p>
        {record?.OrderStatusId == 4 && (
          <p style={{ fontWeight: "bold" }}>On the way to collect shipment</p>
        )}
        {record?.OrderStatusId == 6 && (
          <p style={{ fontWeight: "bold" }}>Shipment Collected</p>
        )}
        {record?.OrderStatusId == 7 && (
          <p style={{ fontWeight: "bold" }}> Shipment Delivered </p>
        )}
        ?
      </Modal>
      <Modal
        title="Confirmation"
        visible={openCancel}
        destroyOnClose
        onOk={() => handleOkOrderCancel()}
        okButtonProps={{ loading: loading }}
        // confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>Do you want to cancel this order?</p>
        <Input
          placeholder="cancellation reason"
          onChange={(e) => {
            setCancelReason(e.target.value);
          }}
          type="textarea"
        />
      </Modal>
      <Modal
        title={
          <>
            <h5>Reassigning the Company</h5>
            <h3 style={{ fontWeight: "bold" }}> OrderID #{record?.Id}</h3>
          </>
        }
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
      >
        <div className="modal-order-holder">
          <Form
            form={formReassignCompany}
            name="delivery"
            onFinish={handlAssignDelivery}
          >
            <Form.Item
              label="Delivery Company"
              name="DeliveryId"
              rules={[
                { required: true, message: "Delivery Company is required!" },
              ]}
            >
              <Select size="large" onChange={OnchangeCompanyList}>
                {companyList?.map((s) => {
                  return (
                    s?.Id !== record?.CompanyPackgeSizeId && (
                      <Option key={JSON.stringify(s)}>
                        <div className="delivery-col-holder delivery-col-modal">
                          <Avatar
                            style={{
                              color: "#fff",
                              backgroundColor: "#612166",
                            }}
                            size={24}
                            src={
                              s?.Image || <FaUserTie style={{ padding: 2 }} />
                            }
                          />
                          <p style={{ marginLeft: 6 }}>{s?.Name}</p>
                        </div>
                      </Option>
                    )
                  );
                })}
              </Select>
            </Form.Item>
            {companyModalPrice ? (
              <>
                <p style={{ fontWeight: "bold" }}>
                  New Company: {"  "}
                  {companyModalPrice?.Name}
                </p>
                <p style={{ fontWeight: "bold" }}>
                  New Price for Assiging to this company
                </p>
                <p>
                  Total :{" "}
                  {parseFloat(
                    companyModalPrice?.price + companyModalPrice?.price * 0.05
                  ).toFixed(2)}{" "}
                  AED
                </p>
                <p>Price to Reassign: {companyModalPrice.price} AED</p>
                <p>
                  VAT: {parseFloat(companyModalPrice?.price * 0.05).toFixed(2)}{" "}
                  AED
                </p>
              </>
            ) : (
              ""
            )}
            <div style={{ height: "50px" }}></div>
            {record ? (
              <>
                <p style={{ fontWeight: "bold" }}>
                  Current Company: {"  "}
                  {
                    companyList?.filter((company) => {
                      if (company?.Id == record?.CompanyPackgeSizeId)
                        return company?.Name;
                    })?.[0]?.Name
                  }
                </p>
                <p style={{ fontWeight: "bold" }}>Current Price</p>
                <p>Total: {record?.Total}</p>
                <p> Price for this order: {record?.DeliveryCost}</p>
                <p>VAT: {record?.VAT}</p>
                <p>Discount: {record?.DiscountAmount || 0}</p>
              </>
            ) : (
              ""
            )}

            {companyModalPrice?.price + companyModalPrice?.price * 0.05 >
              record?.Total &&
            (!paymentLink || paymentLinkStatus == "Cancelled") ? (
              <>
                {paymentLinkStatus == "Cancelled" && (
                  <p style={{ margin: "20px 0" }}>
                    Payment Link Status has been {paymentLinkStatus}, Please
                    generate another link{" "}
                  </p>
                )}
                <Button
                  type="primary"
                  className={cls("btn-primary fw")}
                  style={{ margin: "20px 0" }}
                  loading={generatePaymentLinkLoading}
                  onClick={() =>
                    handleOkOrderPaymentLink(
                      parseFloat(
                        companyModalPrice?.price +
                          companyModalPrice?.price * 0.05
                      ).toFixed(2) - record?.Total
                    )
                  }
                >
                  {t.GeneratePaymentLink}
                </Button>
                <p style={{ margin: "20px 0" }}>
                  Please check in <a href="/paymentLogs">Paymentlogs</a> for
                  details
                </p>
              </>
            ) : (
              ""
            )}
            {paymentLinkStatus == "Pending" ? (
              <>
                <p style={{ marginTop: "20px" }}>
                  Payment Link has been Generated:{" "}
                </p>{" "}
                {paymentLink}
                <p style={{ marginTop: "20px" }}>
                  Payment Status:{" "}
                  <span style={{ color: "red" }}>{paymentLinkStatus}</span>
                </p>
              </>
            ) : (
              ""
            )}

            {companyModalPrice?.price + companyModalPrice?.price * 0.05 <
            record?.Total ? (
              <p style={{ margin: "40px 0", fontWeight: "bold" }}>
                Current company price is lower than Previous company's price so,
                refund will be initiated, Proceed with Reassigning
              </p>
            ) : (
              ""
            )}
            {companyModalPrice?.price + companyModalPrice?.price * 0.05 ==
            record?.Total ? (
              <p style={{ margin: "40px 0", fontWeight: "bold" }}>
                Current company price is equal to Previous company's price so,
                Proceed with reassigning
              </p>
            ) : (
              ""
            )}
            {paymentLinkStatus == "Completed" && (
              <p style={{ margin: "40px 0", fontWeight: "bold" }}>
                Payment Link Status has been{" "}
                <span style={{ color: "green" }}>{paymentLinkStatus}</span>
              </p>
            )}
            <div
              style={{
                display: "flex",
                flexBasis: "100%",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={handleCancel}
                danger
                size="large"
                ghost
                style={{ marginRight: 16 }}
              >
                {" "}
                cancel{" "}
              </Button>

              <Button
                loading={assignloading}
                htmlType="submit"
                type="primary"
                size="large"
                disabled={
                  companyModalPrice?.price + 5 == record?.Total ||
                  companyModalPrice?.price + 5 < record?.Total ||
                  paymentLinkStatus == "Completed"
                    ? false
                    : true
                }
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                Assign{" "}
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <TableGrid
        title={
          <div className="table__filter">
            <p className="bold">{t.Orders}</p>
            {/* <div className='table__filter--count'>
            <p>Show</p>
            <Select
              defaultValue={defaultCurrentVal}
              placeholder="Lead status"
              onChange={(value) => {
                setDefaultCurrentVal(value);
                fetchOrderList(null, value)
              }}
            >
              <Option value={10}> 10 </Option>
              <Option value={20}> 20 </Option>
              <Option value={30}> 30 </Option>
              <Option value={40}> 40 </Option>
            </Select>
            <span>entries</span>
          </div> */}
            {/* <div className='table__filter--count'>
            <p>Filter orders by: </p>
            <Select
              defaultValue={'all'}
              className='filter__by--order'
              onChange={(value) => {
                if (value === 'all') fetchOrderList();
                else if (value === 'Is Cancelled') {
                  let filterOrderList = ordersListPaginate?.orders?.filter(i => i.order_status === value);
                  setOrderList(filterOrderList);
                }
                else {
                  let filterOrderList = ordersListPaginate?.orders?.filter(i => i.order_status === orderStatus.find(i => i.id === value)?.name);
                  setOrderList(filterOrderList);
                }
              }}
            >
              <Option value={'all'}> All </Option>
              {orderStatus?.map((stat, idx) => (
                <Option key={idx} value={stat.Id}> {stat.name} </Option>
              ))}
            </Select>
          </div> */}
            {/* <div className='search__list--input'>
            <Input placeholder='Search For Orders' onChange={(e) => handleFilterList(e.target.value)} />
          </div> */}
          </div>
        }
        columns={columns}
        loading={loading}
        dataSource={ordersList}
        // defaultCurrent={0}
        pageSize={30}
        total={TotalCount}
        // pagination={{ pageSize: defaultCurrentVal, total: ordersListPaginate?.pagination?.total }}
        // total={ordersListPaginate?.pagination?.total}
        // onChange={(pagination) => {
        //   let url = `orders?page=${pagination?.current}`
        //   fetchOrderList(url)
        // }}
        onChange={(pagination) => {
          fetchOrderList(pagination?.current - 1);
        }}
      />
    </div>
  );
};
