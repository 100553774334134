import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message, Input, Upload, Divider, Modal } from 'antd';
import './style.scss'
import Api from '../../Network/ApiConfig'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UploadOutlined } from '@ant-design/icons';
import localization from '../../Localization2/Localization.json'

import PhoneInput ,{ parsePhoneNumber, isValidPhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
const { Option } = Select;

export const AddCompany = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState([]);
    const [imgList, setImgList] = useState([]);
    const [ProfileImg, setProfileImg] = useState(null);
    const [VehicleImg, setVehicleImg] = useState(null);
    const [loading, setLoading] = useState(false);
    const [btnPassLoading, setbtnPassLoading] = useState(false);
    const [passwordModalVisible, setpasswordModalVisible] = useState(false);
    const supplierInfo = JSON.parse(localStorage.getItem('userInfo'))
    const {  currentLang } = useSelector((state) => state?.user);
    const { VehicleTypes } = useSelector((state) => state?.user?.metaData);

    const t = currentLang === "en" ? en : ar;
    const location = useLocation();

    const fetchCategoriesList = () => {
        let body = {
            pagination_number: 100
        }
        Api.post('categories/unassigned', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0]);
            }
            if (res.data.code === 200) {
                setCategoryList(res.data.data.unAssignedCategories);
            }
        })
    }


    useEffect(() => {
        // fetchCategoriesList();
        if (location?.state) {
          if(location?.state?.AspNetUsers?.ProfileImage){
            const mainImg = {
              uid: '-1',
              name: location?.state?.AspNetUsers?.FullName,
              status: 'done',
              url: location?.state?.AspNetUsers?.ProfileImage,
              thumbUrl: location?.state?.AspNetUsers?.ProfileImage,
              type: 'main',
            };
            // getBase64();
            // setProfileImg(location?.state?.AspNetUsers?.ProfileImage)
            setImgList([mainImg]);
          }

      }
    }, []);

    function getBase64(file) {
      // console.log(file, "filefilefile")
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
          setProfileImg(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

    function getBase64VehicleLicenceImage(file) {
      // console.log(file, "filefilefile")
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
          setVehicleImg(base64result);
        };
        reader.onerror = function (error) {
          console.log('Error: ', error);
        };
     }

    //  function  getBase64(file){
    //    return new Promise(() => {
    //      const reader = new FileReader();
    //      reader.readAsDataURL(file);
    //      reader.onload = () => {
    //        const img = document.createElement('img');
    //        img.src = reader.result;
    //        var base64result = reader.result.substr(reader.result.indexOf(',') + 1);
    //        console.log(base64result, "base64result")
    //        // this.setState({avatar_img : reader.result})
           
    //       };
    //     });
    //   }

    const onFinish = (values) => {
        console.log(values);
        setLoading(true);
        const phoneNumber = parsePhoneNumber(values.PhoneNumber || "");

        let body = {
          Name: values?.Name,
          NameLT: values?.NameLT,
          // Description: values?.Description,
          // DescriptionLT: values?.DescriptionLT,
          ProfileImage: ProfileImg || null,
          PhoneKey: "+"+phoneNumber?.countryCallingCode,
          PhoneNumber: phoneNumber?.nationalNumber,
          Password: values?.password,
          ConfirmPassword: values?.ConfirmPassword,
          Email: values?.Email,
        };

        if(location?.state){
          body.Id = location?.state?.Id;
          Api.post("AdminAPIs/EditCompany", body).then(
            (res) => {
              console.log(res);
  
              console.log(res?.data?.metas?.message);
              // message.success(res?.data?.model?.AspNetUsers);
              setLoading(false);
  
              if(res?.Code){
                message.error(res?.message);
              }
              if(res?.status === 200){
                history.push({
                  pathname: '/companies',
              })
              }
              if(res?.status === 400){
                message.error(res?.message);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err?.message)
              message.error(err?.message);
            }
          );
          return false;
        }

        Api.post("AdminAPIs/CreateCompany", body).then(
          (res) => {
            // console.log(res, "mmmmmmmmmmmmmmmmmmmmmmmmm");
            // console.log(res?.data?.metas?.message);
            // message.success(res?.data?.model?.AspNetUsers);
            setLoading(false);
            if(res?.status === 200){
              history.push({
                pathname: '/companies',
            })
            }
            if(res?.Code){
              message.error(res?.message);
            }
            if(res?.status === 400){
              // console.log(res, "rrrrrrrrrrrrrrrrrrr")
              message.error(res?.message);
            } 
          },
          (err) => {
            setLoading(false);
            // console.log(err, "rrrrrrrrrrrrrrrrrrr")
            console.log(err?.message)
            message.error(err?.message);
          }
        );
      };
      const handleChange = async ({ fileList }) => setImgList(fileList);


     const onFinishChangePass = (values) => {
        setbtnPassLoading(true);
        let body = {
          CompanyId: location?.state?.Id,
          NewPassword: values?.newpassword,
          ConfirmNewPassword: values?.ConfirmNewPassword,
      }
    
      Api.post("AdminAPIs/ChangeCompanyPassword", body).then(
        (res) => {
              setbtnPassLoading(false);
    
          if(res?.status === 200){
            console.log(res, "admin@123admin@123admin@123")
            message.success(res?.data?.metas?.message);
            setpasswordModalVisible(false);
          }
          if(res?.status === 400){
            message.error(res?.errors?.message);
          }
        },
        (err) => {
              setbtnPassLoading(false);
          console.log(err?.message)
          message.error(err?.message);
        }
      );
      };

    return (
        <div className='content__wrapper'>
            {/* <div className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {categoryList?.length} categories</p>
            </div> */}
            {console.log(location?.state)}
            <div className='product__wrapper--add'>
              {location?.state ? 
                <p className='product__title--page'>{t.UpdateCompany} </p> :
                <p className='product__title--page'>{t.NewCompany} </p>
              }
                <Form 
                initialValues={{
                  Name: location?.state?.Name,
                  NameLT: location?.state?.NameLT,
                  // Description: location?.state?.Description,
                  // DescriptionLT: location?.state?.DescriptionLT,
                  PhoneNumber: location?.state?.AspNetUsers?.PhoneKey + location?.state?.AspNetUsers?.PhoneNumber,
                  Email: location?.state?.AspNetUsers?.Email,
                }}
                form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label="صوره الشركه"
                        name="ProfileImage"
                        // rules={[{ required: true, message: t.ThisFieldisrequired }]}
                        className="full-width"
                    >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                            fileList={imgList}
                            onChange={handleChange}
                            beforeUpload={(file) => getBase64(file)}
                            >
                            <Button icon={<UploadOutlined />}>{t.Upload}</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label={t.companynameAr}
                        name="NameLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item
                        label={t.companynameEn}
                        name="Name"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

            <Form.Item
                name="PhoneNumber"
                label={t.Phonenumber}
                rules={[
                  {
                    validator(_, value) {
                      let phoneValid = isValidPhoneNumber(value || "");
                     if(!value) return Promise.reject("من فضلك ادخل رقم الجوال");
                     else if(!phoneValid) return Promise.reject("رقم الجوال غير صحيح");
                     else return Promise.resolve();
                    },
                  }
                ]}
                className="input-holder"
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AE"
                  disabled={!!location?.state}
                />
              </Form.Item>

                <Form.Item
                  name="Email"
                label={t.EmailAddress}
                  rules={[ 
                    {
                      required: true,
                      message: `${t.Pleaseinput} ${t.EmailAddress}`,
                    },
                    { type: "email", message: `${t.Emailvalid}` },
                  ]}
                  className="input-holder" 
                >
                  <Input
                    // placeholder={t.EmailAddress}
                    type='email'
                  disabled={!!location?.state}

                  />
                </Form.Item>
                {location?.state && 
                <>
                    <Button type='link' onClick={() => setpasswordModalVisible(true)} >Change Password</Button>

                    <Modal destroyOnClose 
                    closable 
                    // title={localization.changepassword[currentLang]}
                     onCancel={() => setpasswordModalVisible(false)} 
                     wrapClassName="model-form-wrapper"
                     footer={null} visible={passwordModalVisible}>
                    <div className="form-holder-1-col">
                    <Form
                      name="basic"
                      onFinish={onFinishChangePass} 
                      // ref={this.formUserRef}
                      className='form__wrapper form__wrapper--product'
                    >
        
                      <Form.Item
                        name="newpassword" 
                        label={localization.newpassword[currentLang]}
                        className="input-holder group-floating-label"
                        rules={[ 
                          { required: true, message: `${localization.pleaseInput[currentLang]} ${localization.newpassword[currentLang]}` },
                        ]} 
                        hasFeedback
                      >
                        <Input.Password className="input-control"  />
                      </Form.Item>
        
                      <Form.Item
                        name="ConfirmNewPassword"
                        label= {localization.confirmpassword[currentLang]}
                        dependencies={['newpassword']}  
                        className="input-holder group-floating-label"
                        hasFeedback
                        rules={[
                          { required: true, message: `${localization.pleaseInput[currentLang]} ${localization.confirmpassword[currentLang]}` },
                          ({ getFieldValue }) => ({
                            validator(_, value) { 
                              if (!value || getFieldValue('newpassword') === value) { 
                                return Promise.resolve();
                              } 
        
                              return Promise.reject(new Error(localization.Thetwopasswords[currentLang]));
                            },
                          }),
                        ]}
                      >
                        <Input.Password className="input-control" />
                      </Form.Item> 
                    <div className="btn-action-holder" style={{gridColumn: "span 2"}}>
                        <Button  onClick={() => setpasswordModalVisible(false)}  size="large" style={{marginTop : 16}} type="ghost" >
                        {localization.Cancel[currentLang]} 
                        </Button>
                        <Button size="large" style={{marginTop : 16}} type="primary" htmlType="submit" loading={btnPassLoading}>
                        {localization.Save[currentLang]} 
                        </Button>
                    </div>
                    </Form>
                    </div>
                    </Modal>
                    </>
                  }
                  {!location?.state && 
                <Form.Item
                  name="password"
                  className="input-holder"
                label={t.Password}

                  rules={[
                    {
                      required: true,
                      message: `${t.Pleaseinput} ${t.Password}`,
                    },
                    {
                      min: 8,
                      message: `${t.Password8characters}`,
                    },
                    {
                      max: 20,
                      message: `${t.Password20characters}`,
                    },
                  ]}
                >
                  <Input.Password
                    // placeholder={t.Password}
                  />
                </Form.Item>
                  }


{!location?.state && 

                <Form.Item
                  name="ConfirmPassword"
                  dependencies={["password"]}
                  className="input-holder"
                label={t.ReenterPassword}

                  rules={[
                    {
                      required: true,
                      message: `${t.Pleaseinput} ${t.ReenterPassword}`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          // eslint-disable-next-line no-undef
                          return Promise.resolve();
                        }

                        // eslint-disable-next-line no-undef
                        return Promise.reject(
                          new Error(`${t.Thetwopasswords}`)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    // placeholder={t.ReenterPassword}
                  />
                </Form.Item>
}

                  {/* <Divider />

                  <Form.Item
                        label="رخصة المركبة"
                        name="VehicleLicenceImage"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                        // className="full-width"
                    >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                            beforeUpload={(file) => getBase64VehicleLicenceImage(file)}
                            >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label="نوع المركبه"
                        name="VehicleTypeId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Select>
                          {VehicleTypes.map((vehicle) => (
                            <Option key={vehicle.Id} value={vehicle.Id}>
                              {vehicle.NameLT}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>

                    <Form.Item
                        label=" بطاقة الهوية الوطنية"
                        name="NationalIdCard"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input type="number" />
                    </Form.Item>

                    <Form.Item
                        label="رقم رخصة القيادة"
                        name="DrivingLicence"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input type="number" />
                    </Form.Item> */}

                    <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};
