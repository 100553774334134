import React, { useEffect, useState } from "react";
import { Button, Input, Form, message, Upload } from "antd";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
// import { WithContext as ReactTags } from 'react-tag-input';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { FileImageOutlined } from "@ant-design/icons";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";

export const CompanyInfo = () => {
  const [form] = Form.useForm();
  const [productTags, setProductTags] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [socialLinks, setSocialLinks] = useState({
    facebook: "",
    linkedin: "",
    insta: "",
  });
  const [supplierId, setSupplierId] = useState("");
  const [imgList, setImgList] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const supplierInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const handleChangeData = (values) => {
    if (imgList.length) {
      let tags = [];
      productTags?.map((tag) => {
        tags.push(tag.text);
      });

      let body = {
        supplierName: values.name,
        supplierPhone: values.phone,
        supplierEmail: values.email,
        address: values.address,
        website: values.website,
        description: editorState.getCurrentContent().getPlainText(),
        keywords: tags.join(" , "),
        longitude: values.longitude,
        latitude: values.location,
        socialLinks: {
          facebook: socialLinks.facebook,
          linkedin: socialLinks.linkedin,
          instagram: socialLinks.insta,
        },
        supplierId: supplierId,
        image: imgList[0].originFileObj,
      };
      setBtnLoading(true);
      Api.post("profile/update", body).then((res) => {
        if (res.data.validation.length > 0) {
          message.error(res.data.validation[0]);
        } else message.success(res.data.message || "successfully");

        setBtnLoading(false);
      });
    } else {
      message.error("You have to add an image");
    }
  };

  const handleDelete = (i) =>
    setProductTags(productTags.filter((tag, index) => index !== i));

  const handleAddition = (tag) => setProductTags([...productTags, tag]);

  const onEditorStateChange = (editorState) => setEditorState(editorState);

  useEffect(() => {
    handleFetchProfileData();
  }, []);

  const handleChange = async ({ fileList }) => setImgList(fileList);

  const handleFetchProfileData = () => {
    Api.get("profile").then((res) => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0]);
      }
      if (res.data.code === 200) {
        const data = res.data.data.profile;

        let tags = data?.keywords?.split(",");
        if (tags?.length) {
          const localTags = tags?.map((tag) => {
            return { text: tag, id: tag };
          });
          setProductTags(localTags || []);
        }

        setSupplierId(data.id);

        const images = {
          uid: "iid",
          name: data?.logo.split("/")[data?.logo.split("/").length - 1],
          status: "done",
          url: data?.logo,
          thumbUrl: data?.logo,
        };
        setImgList([images]);
        form.setFieldsValue({
          name: data.name,
          email: data.supplierEmail,
          phone: data.supplierPhone,
          address: data.address,
          website: data.website,
          longitude: data.longitude,
          latitude: data.latitude,
        });
        const socialLinksString =
          typeof data.socialLinks === "string"
            ? JSON.parse(data.socialLinks)
            : data.socialLinks;

        setSocialLinks({
          facebook: socialLinksString?.facebook,
          linkedin: socialLinksString?.linkedin,
          insta: socialLinksString?.instagram,
        });

        const descriptionValue = EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(`<p>${data.description}</p>`)
          )
        );
        setEditorState(descriptionValue);
      }
    });
  };

  const handleChangeSocialLinks = (value, type) => {
    setSocialLinks({ ...socialLinks, [type]: value });
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const uploadButton = (
    <p className="image__upload--btn">
      <FileImageOutlined />{" "}
    </p>
  );

  return (
    <div className="content__wrapper">
      <div className="product__wrapper--add">
        <p className="product__title--page">
          Edit My Profile - تعديل الحساب الشخصي
        </p>
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={handleChangeData}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          initialValues={{
            name: supplierInfo?.FullName,
            phone: supplierInfo?.PhoneKey + supplierInfo?.PhoneNumber,
            email: supplierInfo?.Email,
          }}
        >
          <Form.Item label="" style={{ flexBasis: "100%" }} name="imgs">
            <ImgCrop quality={0.1}>
              <Upload
                action={(file) => {}}
                accept="image/png, image/gif, image/jpeg"
                listType="picture-card"
                fileList={imgList}
                onChange={handleChange}
                onPreview={onPreview}
                maxCount={1}
              >
                {imgList.length >= 8 ? null : uploadButton}
              </Upload>
            </ImgCrop>
          </Form.Item>

          <Form.Item
            label="اسم الشركة"
            name="name"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="تيلفون الشركة"
            name="phone"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="بريد الشركة الالكتروني"
            name="email"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="عنوان الشركة"
            name="address"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            label="موقع الشركة"
            name="website"
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="الموقع الجغرافي (latitude)"
            name="latitude" className='label-rtl'
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="الموقع الجغرافي (longitude)"
            name="longitude"
            className='label-rtl'
            rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="وصف الشركة*"
            style={{ flexBasis: '100%' }}
            className='rtl-label'
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={onEditorStateChange}
            />
          </Form.Item> */}

          <h2 className="social_links-title">* Supplier Social Links </h2>

          <Form.Item label="" name="facebook" className="social__media--fields">
            <span className="price__label">
              <img src="/images/companyFacebook.svg" alt="video" />
            </span>
            <Input
              value={socialLinks.facebook}
              onChange={(e) =>
                handleChangeSocialLinks(e.target.value, "facebook")
              }
              placeholder=""
            />
          </Form.Item>
          <Form.Item label="" name="linkedin" className="social__media--fields">
            <span className="price__label">
              <img src="/images/linkedin.svg" alt="video" />
            </span>
            <Input
              value={socialLinks.linkedin}
              onChange={(e) =>
                handleChangeSocialLinks(e.target.value, "linkedin")
              }
              placeholder=""
            />
          </Form.Item>

          <Form.Item label="" name="insta" className="social__media--fields">
            <span className="price__label">
              <img src="/images/insta.svg" alt="video" />
            </span>
            <Input
              value={socialLinks.insta}
              onChange={(e) => handleChangeSocialLinks(e.target.value, "insta")}
              placeholder=""
            />
          </Form.Item>
          {/* 
          <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ background: '#fff', color: "#fff" }}> التالي <ArrowLeftOutlined /> </Button>
            </div>
          </Form.Item> */}
        </Form>
      </div>
    </div>
  );
};
