import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";
import { Link, withRouter } from "react-router-dom";
// import "../../scss/auth.scss"
// import { forgetPassword, userLogin } from "../../network/auth.network";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.action";
import localization from '../../Localization2/Localization.json'
import Logo from '../../images/logo.svg'
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInput from "react-phone-number-input";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { parsePhoneNumber } from "react-phone-number-input";
import Api from "../../Network/ApiConfig";
// import { FaChevronRight } from "react-icons/fa";

// import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';
// const t = "currentLang" === "en" ? en : ar;
const t = en
class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
    };
  }

  onFinish = (values) => {
    this.setState({btnLoading : true});
    const phoneNumber = parsePhoneNumber(values.PhoneNumber || "");
    let body = {
      PhoneKey: "+"+phoneNumber?.countryCallingCode,
      PhoneNumber: phoneNumber?.nationalNumber,
  }

  Api.post("AdminAPIs/ForgetPassword", body).then(
    (res) => {
      console.log(res);
          this.setState({btnLoading : false});

      if(res?.status === 200){
        this.props.history.push({pathname:"/phone-verification",state: body});
      }
      if(res?.status === 400){
        message.error(res?.errors?.message);
      }
    },
    (err) => {
          this.setState({btnLoading : false});
      console.log(err?.message)
      message.error(err?.message);
    }
  );

    // forgetPassword(emailVal,
    //   (res)=>{
    //     this.setState({btnLoading : false});
    //    this.props.history.replace({pathname:"/phone-verification",state:{email:emailVal,status:0}});
    // },(error)=>{
    //   this.setState({btnLoading : false})
    //   message.error("من فضلك ادخل بريد إلكتروني صحيح")
    // })
  };
  
  render() {
    const { btnLoading } = this.state;
    const { currentLang } = this.props; 
    return ( 
        <div className="auth-wrapper page-wrapper-no-header">
          <div className="auth-form-content">

              <Link to="/" className="logo-holder">
                <img src={Logo} alt="logo" />
              </Link>
              <p className="form-red-p">
              Forgot your password
              </p>
              <p className="form-hint-p">
              Please enter your Phone Number to send a message containing the verification code 
              </p>
              <Form name="personalInfo" className='form__wrapper form__wrapper--product forget-form'
                    onFinish={this.onFinish}
                    autoComplete="off"
                >
                          <Form.Item
                name="PhoneNumber"
                label={t.Phonenumber}
                rules={[
                  {
                    validator(_, value) {
                      let phoneValid = isValidPhoneNumber(value || "");
                     if(!value) return Promise.reject("من فضلك ادخل رقم الجوال");
                     else if(!phoneValid) return Promise.reject("رقم الجوال غير صحيح");
                     else return Promise.resolve();
                    },
                  }
                ]}
                className="input-holder"
              >
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="AE"
                  
                />
              </Form.Item>
              {/* <div className="forget-pass-holder">
                <Button size="large" type="primary" htmlType="submit" block loading={btnLoading}>
                  {localization.Send[currentLang]}
                </Button>
              </div> */}
              <div className="forget-pass-holder" style={{textAlign : "center", marginTop : 20}}>
                  <Button loading={btnLoading} htmlType='submit' block style={{ border: 'none', color: "#fff" }}> Send </Button>
              </div>

            </Form>
          </div>
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang : state.user.currentLang,
}) 

const LoginWithRouter = withRouter(ForgetPassword);
export default connect(mapStateToProps)(LoginWithRouter);
