import { message } from "antd";
import axios from "axios";
// import { toast } from "react-toastify";

let paxios = axios.create();

export const errorCatch = () => {};

paxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // err.response.data.errors
    if (401 === error.response.status) {
      // localStorage.removeItem("token");
      // window.location.reload();
    }
    if (500 === error.response.status) {
      message.error("Sorry. something went wrong.");
    } else {
      return error?.response?.data?.errors;
    }
    // else {
    //     return Promise.reject(error);
    // }
  }
);

export default class Api {
  static async get(route, headers) {
    return await this.execute(route, null, "get", headers);
  }

  static async put(route, params, headers) {
    return await this.execute(route, params, "put", headers);
  }

  static async post(route, params, headers) {
    return await this.execute(route, params, "post", headers);
  }

  static async delete(route, params, headers) {
    return await this.execute(route, params, "delete", headers);
  }

  static async execute(route, params, verb, headersParam) {
    // let request = ["http://native-001-site30.ctempurl.com/api/" + route];
    // let request = [
    //   "https://chauffeur-be-staging-j2ezitroia-uc.a.run.app/api/" + route,
    // ];
    let request = [
      "https://chauffeur-be-prod-261156411298.us-central1.run.app/api/" + route,
    ];

    let config = {
      headers: headersParam
        ? {
            Authorization: localStorage.getItem("token"),
            ...headersParam,
          }
        : {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json;",
          },
    };
    if (route === "login") {
      config = {
        headers: {
          "Content-Type": "multipart/form-data;",
        },
      };
    }

    if (params) request.push(params);
    let result = await paxios[verb](...request, config);
    return result;
  }
}
