import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Upload,
  message,
  Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ar from "../../localization/ar";
import en from "../../localization/en";
import Api from "../../Network/ApiConfig";
import { UploadOutlined } from "@ant-design/icons";
import parse from "html-react-parser";
import "./style.scss";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
export default function AddBlog() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const [imgList, setImgList] = useState([]);
  const [ProfileImg, setProfileImg] = useState(null);
  const [description, setdescription] = useState("");
  const [descriptionLT, setdescriptionLT] = useState("");
  const [values, setvalues] = useState();
  const location = useLocation();
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const handleChange = async ({ fileList }) => setImgList(fileList);

  const { Option } = Select;
  useEffect(() => {
    if (location?.state) {
      if (location?.state?.Image) {
        const mainImg = {
          uid: "-1",
          name: "blog",
          status: "done",
          url: location?.state?.Image,
          thumbUrl: location?.state?.Image,
          type: "main",
        };
        setImgList([mainImg]);
      }
      getBlogDetails();
    }
  }, []);

  const blogCategories = [
    {
      Name: "Logistic",
      NameLT: "لوجستية",
      Id: 1,
      CreationDate: "2024-08-26T10:25:15.2233333",
      ModifiedDate: null,
      DeletedAt: null,
      IsDeleted: null,
    },
    {
      Name: "Global",
      NameLT: "عالمي",
      Id: 2,
      CreationDate: "2024-08-26T10:25:15.2233333",
      ModifiedDate: null,
      DeletedAt: null,
      IsDeleted: null,
    },
    {
      Name: "UAE",
      NameLT: "عالمي",
      Id: 3,
      CreationDate: "2024-08-26T10:25:15.2233333",
      ModifiedDate: null,
      DeletedAt: null,
      IsDeleted: null,
    },
    {
      Name: "Social",
      NameLT: "اجتماعي",
      Id: 4,
      CreationDate: "2024-08-26T10:25:15.2233333",
      ModifiedDate: null,
      DeletedAt: null,
      IsDeleted: null,
    },
    {
      Name: "Environment",
      NameLT: "بيئة",
      Id: 5,
      CreationDate: "2024-08-26T10:25:15.2233333",
      ModifiedDate: null,
      DeletedAt: null,
      IsDeleted: null,
    },
  ];
  const getBlogDetails = async () => {
    Api.get(`AdminAPIs/GetBlogById?BlogId=${location?.state?.Id}`).then(
      (res) => {
        if (res?.status === 200) {
          setvalues(res?.data?.model);
          setdescription(res?.data?.model?.Description);
          setdescriptionLT(res?.data?.model?.DescriptionLT);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const onFinish = (values) => {
    // return;
    setLoading(true);

    let body = {
      Alt: values?.Alt,
      AltLT: values?.AltLT,
      Title: values?.Title,
      TitleLT: values?.TitleLT,
      Description: values?.Description,
      DescriptionLT: values?.DescriptionLT,
      TotalLikes: 0,
      TotalDislikes: 0,
      // Description: draftToHtml(
      //   convertToRaw(descriptioncontent.getCurrentContent())
      // ).trim(),
      // DescriptionLT: draftToHtml(
      //   convertToRaw(descriptionLTcontent.getCurrentContent())
      // ).trim(),
      Image: ProfileImg || null,
      BlogCategoryId: values?.BlogCategoryId,
    };

    if (location?.state) {
      body.Id = location?.state?.Id;
      Api.post("AdminAPIs/EditBlog", body).then(
        (res) => {
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);

            history.push({
              pathname: "/blogs",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err?.message);
          message.error(err?.message);
        }
      );
      return false;
    } else {
      Api.post("AdminAPIs/CreateBlog", body).then(
        (res) => {
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);

            history.push({
              pathname: "/blogs",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err?.message);
          message.error(err?.message);
        }
      );
    }
  };
  function getBase64(file) {
    // console.log(file, "filefilefile")
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var base64result = reader.result.substr(reader.result.indexOf(",") + 1);
      setProfileImg(base64result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    <div className="terms-condition-wrapper">
      <h2>{t.NewBlog}</h2>
      <div className="form-inputs-wrapper">
        {(location?.state == undefined || values) && (
          <Form
            form={form}
            name="personalInfo"
            className="form__wrapper form__wrapper--product product__wrapper--add"
            onFinish={onFinish}
            autoComplete="off"
            initialValues={{
              ...values,
            }}
          >
            <div className="form-grid-holder">
              <Form.Item
                label={t.BlogCategory}
                name="BlogCategoryId"
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <Select>
                  {blogCategories.map((blog) => (
                    <Option key={blog.Id} value={blog.Id}>
                      {currentLang == "en" ? blog.Name : blog.NameLT}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t.mainPhoto}
                name="ProfileImage"
                // rules={[{ required: true, message: t.ThisFieldisrequired }]}
                className="full-width"
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture"
                  className="upload-list-inline"
                  maxCount={1}
                  fileList={imgList}
                  onChange={handleChange}
                  beforeUpload={(file) => getBase64(file)}
                >
                  <Button className="btn" icon={<UploadOutlined />}>
                    {t.Upload}
                  </Button>
                </Upload>
              </Form.Item>
              <div className="full-width">
                {/* <Form.Item label={t.altEn} name="Alt">
                <Input />
              </Form.Item>
              <Form.Item label={t.altAr} name="AltLT">
                <Input />
              </Form.Item> */}
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item label={t.altAr} name="AltLT">
                      <Input
                        style={{
                          maxWidth: "100%",
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label={t.altEn} name="Alt">
                      <Input
                        style={{
                          maxWidth: "100%",
                          direction: "ltr",
                          textAlign: "left",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
              <Divider className="full-width" />
              <Form.Item
                label={t.titleAr}
                name="TitleLT"
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder quill-content-input-holder rtl-input-title"
                />
              </Form.Item>

              <Form.Item
                label={t.titleEn}
                name="Title"
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder quill-content-input-holder"
                />
              </Form.Item>

              <Form.Item
                label={t.descriptionAr}
                name="DescriptionLT"
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
                className="full-width"
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder rtl-input"
                  modules={modules}
                />
              </Form.Item>

              <Form.Item
                label={t.descriptionEn}
                name="Description"
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
                className="full-width"
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder"
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>
            </div>

            <Form.Item style={{ marginTop: 30 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  flexBasis: "100%",
                }}
              >
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="btn"
                  style={{ border: "none", color: "#fff" }}
                >
                  {" "}
                  {t.Save}
                  {/* {location?.state?.Id ? t.update : t.Add} */}
                </Button>
              </div>
            </Form.Item>
            {/* 
          <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
              <Button loading={btnLoading} onClick={() => setTimeout(() => {
                document.querySelector('.ant-form-item-has-error')?.scrollIntoView(false)
              }, 50)} htmlType='submit' style={{ background: '#fff', color: "#fff" }}> التالي <ArrowLeftOutlined /> </Button>
            </div>
          </Form.Item> */}
          </Form>
        )}
      </div>
    </div>
  );
}
