export const SET_CURRENT_USER = "SET_CURRENT_USER"
export const SET_LANG = "SET_LANG"
export const SET_META_DATA = "SET_META_DATA"
export const UPDATE_CURRENT_USER = "UPDATE_CURRENT_USER"
export const SET_USER_REQUEST = "SET_USER_REQUEST"
export const SET_ROLE_ID = "SET_ROLE_ID"
export const SET_GOVERNATES = "SET_GOVERNATES"
export const SET_CITIES = "SET_CITIES"
export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT"
export const NOTIFICATION_LIST = "NOTIFICATION_LIST"
