import React, { useEffect, useState } from 'react';
import { Button, Select, Modal, message, Dropdown, Checkbox } from 'antd';
import { TableGrid } from '../../../Component/Table/index';
import './style.scss'
import Api from '../../../Network/ApiConfig'
import { ExclamationCircleOutlined, DownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import Input from 'antd/lib/input/Input';
import Pdf from "react-to-pdf";
import { StyleToPRint } from '../../../Component/jsonsStyles/styleJson';
const { confirm } = Modal


export const InfoViewed = () => {

    const columns = [
        {
            title: <p className='table-header-text'> # </p>,
            dataIndex: 'id',
        },
        {
            title: <p className='table-header-text'> Name  <span> الاسم </span></p>,
            dataIndex: 'user_name',
        },
        {
            title: <p className='table-header-text'> phone <span> الموبايل </span></p>,
            dataIndex: 'user_phone',
            render: (_, record) => <a style={{ textDecoration: 'underline' }}>{record.user_phone} </a>,
        },
        {
            title: <p className='table-header-text'> Email <span> البريد الالكتروني </span></p>,
            dataIndex: 'user_email',
        },
        {
            title: <p className='table-header-text'> Company Name <span> اسم الشركة </span></p>,
            dataIndex: 'company_name',
        },
        {
            title: <p className='table-header-text'> Date <span> التاريخ </span></p>,
            dataIndex: 'date',
            render: (_, rec) => {
                return <p> {moment(rec?.date).format('DD/MM/YYYY')} </p>
            }
        },
        {
            title: <p className='table-header-text'> Actions <span> افعال </span></p>,
            dataIndex: 'actions',
            render: (_, record) => {
                return (
                    record.isReported ? <p className='reported__label'>Reported</p> : <Button type='primary' onClick={() => handleReportUSer(record)}
                        style={{ background: '#612166', color: '#fff' }}>Report</Button>
                );
            }
        },
    ];

    const pdfRef = React.createRef()
    const [infoViewedList, setInfoViewed] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);
    const [tableColumn, setTableColumn] = useState(columns);
    const [infoViewedTableList, setInfoViewedTableList] = useState([]);
    const [checkedList, setCheckedList] = useState(
        columns.map((item) => {
            return item.dataIndex
        }));
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(true);
    const [excelData, setExcelData] = useState([]);
    const checkBoxColumn = columns.map((item) => {
        return { label: item.title, value: item.dataIndex }
    });

    const columnList = () => {
        return (
            <div className='dropdown__column--visible'>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                    Check all
                </Checkbox>
                <Checkbox.Group options={checkBoxColumn} value={checkedList} onChange={onChange} />
            </div>
        )
    }

    const onChange = list => {
        // if (list?.length === 0) return
        let tableColumnClone = [];
        columns.map((item) => {
            if (list.includes(item.dataIndex)) {
                tableColumnClone.push(item)
            }
        })

        setTableColumn(tableColumnClone)
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < checkBoxColumn.length);
        setCheckAll(list.length === checkBoxColumn.length);
    };

    const onCheckAllChange = e => {
        setTableColumn(e.target.checked ? columns : [])
        setCheckedList(e.target.checked ? columns.map((item) => {
            return item.dataIndex
        }) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const fetchInfoViewed = (url, pageSize) => {
        let body = {
            pagination_number: pageSize ? pageSize : defaultCurrentVal
        }
        setLoading(true)
        Api.post(url ? url : 'reports/view-info', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0])
            }
            if (res.data.code === 200) {
                setInfoViewed(res.data.data);
                setInfoViewedTableList(res.data.data.view_info)
            }
            setLoading(false)
        })
    }


    useEffect(() => { 
        const data = infoViewedList?.view_info?.map(item => {
            let obj = {};
            tableColumn.map(dataIndex => {
                if(dataIndex.dataIndex === 'actions') return obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] = item.isReported ? 'Reported': 'Not reported';
                if (dataIndex.dataIndex === 'date')
                    return obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] = moment(item[dataIndex.dataIndex]).format('DD/MM/YYYY');
                else
                    return obj[dataIndex.title.props.children[0]?.toUpperCase()] = item[dataIndex.dataIndex];
            })
            return obj
        });
        setExcelData(data);

    }, [tableColumn, infoViewedList]);

    useEffect(() => {
        fetchInfoViewed()
    }, []);

    const handleReportUSer = (rec) => {
        let body = new FormData();
        body.append('userId', rec.id);

        confirm({
            title: `Are you sure you want to report this user?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Report',
            cancelText: 'Cancel',
            onOk: () => {
                Api.post('reports/report-user', body).then(res => {
                    if (res.data.validation.length > 0) {
                        message.error(res.data.validation[0])
                    }
                    if (res.data.code === 200) {
                        message.success(res.data.message || 'successfully')
                        fetchInfoViewed();
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleFilterList = (value) => {
        if (!!value) {
            let salesFiltered = infoViewedTableList?.filter(i => i.user_name.toLowerCase().includes(value.toLowerCase()));
            setInfoViewed({...salesFiltered, view_info: salesFiltered})
        } else {
            fetchInfoViewed()
        }
    }

    const handelPrint = () => {
        var bodyPrint = document.getElementById('print-section').innerHTML;
        var mywindow = window.open('', 'PRINT');
        mywindow.document.write('<html><head>');
        mywindow.document.write('<link href="https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap" rel="stylesheet">');
        mywindow.document.write(`<style> ${StyleToPRint}</style>`); 
        mywindow.document.write(bodyPrint);
        mywindow.document.write('</body></html>');
        mywindow.document.title = 'Information Viewed';
        setTimeout(() => {
            mywindow.document.close();
            mywindow.focus();
            mywindow.print();
            mywindow.close();
        }, 200);
    }

    return (
        <div className='product__wrapper'>
            <TableGrid TableRef={pdfRef} title={
                <div className='table__filter'>
                    <p className='table__title'> Information Viewed <span>اظهار البيانات</span></p>
                    <div className='download__list'>
                        <CSVLink filename="Information-Viewed.csv" data={excelData || []}>Excel</CSVLink>
                         <a onClick={handelPrint}>Pdf</a> 
                        <Dropdown trigger={'click'} overlay={columnList}>
                            <a style={{ width: '135px' }}>
                                column visibility
                                <DownOutlined style={{ marginLeft: '7px' }} />
                            </a>
                        </Dropdown>
                    </div>
                    <div className='table__filter--count'>
                        <p>Show</p>
                        <Select
                            defaultValue={defaultCurrentVal}
                            placeholder="Lead status"
                            onChange={(value) => { 
                                setDefaultCurrentVal(value);
                                fetchInfoViewed(null, value)
                             }}
                        >
                            <Select.Option value={10}> 10 </Select.Option>
                            <Select.Option value={20}> 20 </Select.Option>
                            <Select.Option value={30}> 30 </Select.Option>
                            <Select.Option value={40}> 40 </Select.Option>
                        </Select>
                        <span>entries</span>
                    </div>
                    <div className='search__list--input'>
                        <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
                    </div>
                </div>
            }
                columns={tableColumn}
                defaultCurrent={defaultCurrentVal}
                dataSource={tableColumn.length > 0 ? infoViewedList?.view_info : []}
                loading={loading}
                pagination={{ pageSize: defaultCurrentVal, total: infoViewedList?.view_info?.length }}
                total={infoViewedList?.pagination?.total}
                pageSize={30}
                onChange={(pagination) => {
                    let url = `reports/view-info?page=${pagination?.current}`
                    fetchInfoViewed(url)
                }}
            />
        </div>
    );
};
