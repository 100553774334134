import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message, Input, Upload, Divider } from 'antd';
import './style.scss'
import Api from '../../Network/ApiConfig'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UploadOutlined } from '@ant-design/icons';

import PhoneInput ,{ parsePhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from 'react-redux';
const { Option } = Select;

export const AddOffer = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const supplierInfo = JSON.parse(localStorage.getItem('userInfo'))
    const { currentUser } = useSelector((state) => state?.user);
    const [selectedItems, setSelectedItems] = useState([]);
    const { PackgeSizes, DeliveryTypes, Cities } = useSelector((state) => state?.user?.metaData);

    const { currentLang } = useSelector((state) => state?.user);
    const t = currentLang === "en" ? en : ar;

    useEffect(() => {
      setSelectedItems(Cities);
    }, [])
    

    const onFinish = (values) => {
      // return false;
        console.log(values);
        setLoading(true);
        const phoneNumber = parsePhoneNumber(values.PhoneNumber || "");

        let body = {
          Description: values?.Description,
          DescriptionLT: values?.DescriptionLT,
          Price: values?.Price,
          FromCityId: values?.FromCityId,
          ToCityId: values?.ToCityId,
          PackgeSizeId: values?.PackgeSizeId,
        };
        Api.post("CompanyPackgeSizes/Add", body).then(
          (res) => {
            console.log(res?.status);
            if(res?.Code){
            message.error(res?.message);
              setLoading(false);
              return false
            }
            console.log(res?.data?.metas?.message);
            // message.success(res?.data?.model?.AspNetUsers);
            setLoading(false);
            if(res?.status === 200){
              history.push({
                pathname: '/offers',
            })
            }
          },
          (err) => {
            console.log(err, "errrrrrrrrrrrrrrrrrrrrrr")
            setLoading(false);
            console.log(err?.message)
            message.error(err?.message);
          }
        );
      };

      const handelFilterCity = (Id) => {
        const filterArr = Cities?.filter((o) => o?.Id !== Id);
        setSelectedItems(filterArr);
        // selectedItems?.filter((o) => !selectedItems?.includes(o));
    } 


    return (
        <div className='content__wrapper'>
            {/* <div className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {categoryList?.length} categories</p>
            </div> */}
            <div className='product__wrapper--add'>
                <p className='product__title--page'>Add Price  - اضافة سعر  </p>
                <Form form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label={t.descriptionAr}
                        name="DescriptionLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={t.descriptionEn}
                        name="Description"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    
                    <Form.Item
                        label="توصيل من المدينة"
                        name="FromCityId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                      <Select
                          // onChange={handelFilterCity}
                        >
                          {selectedItems.map((item) => (
                            <Select.Option key={item?.Id} value={+item?.Id}>
                              {item?.NameLT}
                            </Select.Option>
                          ))}
                        </Select> 
                      </Form.Item>
                    
                    <Form.Item
                        label="توصيل الي المدينة"
                        name="ToCityId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                      <Select
                          onChange={handelFilterCity}
                        >
                          {selectedItems.map((item) => (
                            <Select.Option key={item?.Id} value={+item?.Id}>
                              {item?.NameLT}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

{/*                     
                    <Form.Item
                        label="أنواع التوصيل"
                        name="DeliveryTypes"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Select>
                          {DeliveryTypes.map((delivery) => (
                            <Option key={delivery.Id} value={delivery.Id}>
                              {delivery?.NameLT}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item> */}

                    
                    <Form.Item
                        label="أحجام العبوة"
                        name="PackgeSizeId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Select>
                          {PackgeSizes.map((Package) => (
                            <Option key={Package.Id} value={Package.Id}>
                              {Package?.NameLT}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    
                    <Form.Item
                        label="السعر"
                        name="Price"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Input type="number" />
                      </Form.Item>


                    <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};
