import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Upload,
  message,
  DatePicker,
  Select,
  Switch,
  InputNumber,
} from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ar from "../../localization/ar";
import en from "../../localization/en";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import "./style.scss";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ReactQuill from "react-quill";
export default function AddCoupon() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  const [CouponType, setCouponType] = useState([]);
  const [allCitiesSwitch, setAllCitiesSwitch] = useState(true);
  const [allCities, setAllCities] = useState([]);
  const [allCompaniesSwitch, setAllCompaniesSwitch] = useState(true);

  const [allCompanies, setAllCompanies] = useState([]);
  const [fromCity, setFromCity] = useState(null);
  const [toCity, setToCity] = useState(null);

  const location = useLocation();
  const { Option } = Select;

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  useEffect(() => {
    // getCouponDetails();
    getCouponType();
    getAllCities();
    getDeliveryCompanies();
    location?.state?.AllCities
      ? setAllCitiesSwitch(true)
      : setAllCitiesSwitch(false);
    location?.state?.AllCompanies
      ? setAllCompaniesSwitch(true)
      : setAllCompaniesSwitch(false);
    setFromCity(
      allCities?.filter((city) => city?.Id == location?.state?.FromCityId)[0]
        ?.Id
    );
    setToCity(
      allCities?.filter((city) => city?.Id == location?.state?.ToCityId)[0]?.Id
    );
  }, []);

  const getDeliveryCompanies = () => {
    Api.get(`AdminAPIs/GetCompanies`).then((type) => {
      setAllCompanies(type?.data?.model);
    });
  };
  const getAllCities = () => {
    Api.get(`AdminAPIs/GetCities`).then((type) => {
      setAllCities(type?.data?.model);
    });
  };
  const handleAllCitiesChange = (checked) => {
    setAllCitiesSwitch(checked);
  };

  const handleAllCompaniesChange = (checked) => {
    setAllCompaniesSwitch(checked);
  };

  const getCouponType = async () => {
    Api.get(`AdminAPIs/GetCouponTypes`).then((type) => {
      setCouponType(type?.data?.model);
    });
  };

  const getCouponDetails = async () => {
    Api.get(`AdminAPIs/GetCouponById?CouponId=${location?.state?.Id}`).then(
      (res) => {
        if (res?.status === 200) {
          console.log("res", res);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onFinish = (values) => {
    setLoading(true);
    let body = {
      Name: values?.Name,
      NameLT: values?.NameLT,
      Description: values?.Description,
      DescriptionLT: values?.DescriptionLT,
      Code: values?.Code,
      CouponTypeId: values?.CouponTypeId,
      ExpirationDate: values?.ExpirationDate,
      MaxUsesPerUser: values?.MaxUsesPerUser,
      FromCityId: values?.FromCityId || null,
      ToCityId: values?.ToCityId || null,
      AllCities: allCitiesSwitch,
      CompanyId: values?.CompanyId,
      AllCompanies: allCompaniesSwitch,
      IsActive: true,
      RedeemAmountOrPercent: values?.RedeemAmountOrPercent,
      MinOrderAmount: values?.MinOrderAmount,
    };

    if (Object.keys(location?.state).length > 0) {
      body.Id = location?.state?.Id;
      Api.post("AdminAPIs/EditCoupon", body).then(
        (res) => {
          console.log(res?.data?.metas?.message);
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);

            history.push({
              pathname: "/coupon/generateCoupon",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err?.message);
          message.error(err?.message);
        }
      );
      return false;
    } else {
      Api.post("AdminAPIs/CreateCoupon", body).then(
        (res) => {
          console.log(res?.data?.metas?.message);
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);

            history.push({
              pathname: "/coupon/generateCoupon",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err?.message);
          message.error(err?.message);
        }
      );
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, false] }],
      [{ color: [] }, { background: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  const handleFromCityChange = (e, value) => {
    if (value == "FromCity") setFromCity(e);
    else if (value == "ToCity") setToCity(e);
  };

  return (
    <div className="terms-condition-wrapper">
      <h2>{t.NewCoupon}</h2>
      <div className="form-inputs-wrapper">
        <Form
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product product__wrapper--add"
          onFinish={onFinish}
          autoComplete="off"
          initialValues={{
            Name: location?.state?.Name,
            NameLT: location?.state?.NameLT,
            Description: location?.state?.Description,
            DescriptionLT: location?.state?.DescriptionLT,
            Code: location?.state?.Code,
            CouponTypeId: location?.state?.CouponTypeId,
            ExpirationDate: moment(location?.state?.ExpirationDate),
            MaxUsesPerUser: location?.state?.MaxUsesPerUser,
            FromCityId: location?.state?.FromCityId,
            ToCityId: location?.state?.ToCityId,
            AllCities: allCitiesSwitch,
            CompanyId: location?.state?.CompanyId,
            AllCompanies: allCompaniesSwitch,
            IsActive: location?.state?.IsActive,
            RedeemAmountOrPercent: location?.state?.RedeemAmountOrPercent,
            MinOrderAmount: location?.state?.MinOrderAmount,
          }}
        >
          {/* Name Fields */}
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.nameAr}
                name="NameLT"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.nameEn}
                name="Name"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <Input style={{ direction: "ltr", textAlign: "left" }} />
              </Form.Item>
            </Col>
          </Row>

          {/* Description Fields */}
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.descriptionAr}
                name="DescriptionLT"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder rtl-input"
                  modules={modules}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.descriptionEn}
                name="Description"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder"
                  modules={modules}
                  formats={formats}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Expiration Date, Coupon Type, Code */}
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label={t.ExpirationDate}
                name="ExpirationDate"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <DatePicker
                  style={{
                    width: "100%",
                    height: "100%", // Ensure height matches other inputs
                    lineHeight: "normal", // Adjust line height for consistent alignment
                  }}
                  disabledDate={(current) =>
                    current && current < moment().startOf("day")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t.CouponCodeType}
                name="CouponTypeId"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <Select>
                  {CouponType?.map((type) => (
                    <Option key={type?.Id} value={type?.Id}>
                      {type?.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t.Code}
                name="Code"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[{ required: true, message: t.ThisFieldisrequired }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          {/* Redeem Amount, Min Order Amount, Max Uses Per User */}
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                label={t.RedeemAmountOrPercent}
                name="RedeemAmountOrPercent"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  { required: true, message: t.ThisFieldisrequired },
                  // { type: "number", min: 1, message: t.MustBeGreaterThanZero },
                ]}
              >
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: "100%", paddingRight: "10%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t.MinOrderAmount}
                name="MinOrderAmount"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  { required: true, message: t.ThisFieldisrequired },
                  // { type: "number", min: 1, message: t.MustBeGreaterThanZero },
                ]}
              >
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: "100%", paddingRight: "10%" }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={t.MaxUsesPerUser}
                name="MaxUsesPerUser"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  { required: true, message: t.ThisFieldisrequired },
                  // { type: "number", min: 1, message: t.MustBeGreaterThanZero },
                ]}
              >
                <InputNumber
                  min={1}
                  precision={0}
                  style={{ width: "100%", paddingRight: "10%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* All Cities Switch */}
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.AllCities}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Switch
                  checked={allCitiesSwitch}
                  onChange={handleAllCitiesChange}
                  style={{
                    backgroundColor: allCitiesSwitch ? "#1890ff" : "#fff",
                    height: 25,
                    width: 20,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* From City and To City Select */}
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item
                label={t.FromCity}
                name="FromCityId"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={
                  !allCitiesSwitch
                    ? [{ required: true, message: t.ThisFieldisrequired }]
                    : []
                }
              >
                <Select
                  onChange={(e) => handleFromCityChange(e, "FromCity")}
                  disabled={allCitiesSwitch}
                  value={fromCity}
                >
                  {allCities.map((city) => (
                    <Option key={city.Id} value={city.Id}>
                      {currentLang == "en" ? city.Name : city?.NameLT}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t.ToCity}
                name="ToCityId"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                rules={
                  !allCitiesSwitch
                    ? [{ required: true, message: t.ThisFieldisrequired }]
                    : []
                }
              >
                <Select
                  onChange={(e) => handleFromCityChange(e, "ToCity")}
                  disabled={allCitiesSwitch}
                >
                  {allCities
                    .filter((city) => city.Id !== fromCity)
                    .map((city) => (
                      <Option key={city.Id} value={city.Id}>
                        {currentLang == "en" ? city.Name : city?.NameLT}
                      </Option>
                    ))}
                  {/* Add more options as needed */}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* All Delivery Companies Switch */}
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.AllCompanies}
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
              >
                <Switch
                  checked={allCompaniesSwitch}
                  onChange={handleAllCompaniesChange}
                  style={{
                    backgroundColor: allCompaniesSwitch ? "#1890ff" : "#fff",
                    height: 25,
                    width: 20,
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Delivery Company Select */}
          <Row gutter={20}>
            <Col span={24}>
              <Form.Item
                label={t.deliveryCompany}
                name="CompanyId"
                labelCol={{ span: 4 }}
                wrapperCol={{ span: 20 }}
                rules={
                  !allCompaniesSwitch
                    ? [{ required: true, message: t.ThisFieldisrequired }]
                    : []
                }
              >
                <Select disabled={allCompaniesSwitch}>
                  {allCompanies?.map((city) => (
                    <Option key={city.Id} value={city.Id}>
                      {currentLang == "en" ? city.Name : city?.NameLT}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {/* Submit Button */}
          <Form.Item style={{ marginTop: 30 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexBasis: "100%",
              }}
            >
              <Button
                loading={loading}
                htmlType="submit"
                style={{
                  border: "none",
                  color: "#fff",
                  backgroundColor: "#612166",
                }}
              >
                {t.Save}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
