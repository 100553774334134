import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message, Input, Upload } from 'antd';
import './style.scss'
import Api from '../../../../Network/ApiConfig'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { UploadOutlined } from '@ant-design/icons';
import en from '../../../../localization/en';
import ar from '../../../../localization/ar';
import { useSelector } from 'react-redux';
const { Option } = Select;

export const AssignSubCategories = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const {  currentLang } = useSelector((state) => state?.user);

    const t = currentLang === "en" ? en : ar;
    const fetchCategoriesList = () => {
        let body = {
            pagination_number: 100
        }
        Api.post('categories/unassigned', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0]);
            }
            if (res.data.code === 200) {
                setCategoryList(res.data.data.unAssignedCategories);
            }
        })
    }


    useEffect(() => {
        // fetchCategoriesList();
    }, []);

    const handleChangeData = (values) => {
        let header = {
            'Content-Type': 'multipart/form-data;'
        }

        let body = new FormData();
        values.categoryId.map((cate, idx) => {
            body.append(`categoryId[${idx}]`, cate);
        });

        Api.post('categories/assign', body, header).then(res => {
            setLoading(true)
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0]);
                setLoading(false)
            } else {
                message.success(res.data.message);
                setLoading(false)
                history.push({
                    pathname: '/packages',
                });
            }
            setLoading(false)
        }, () => {
            setLoading(false)
        })
    }

    return (
        <div className='content__wrapper'>
            {/* <div className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {categoryList?.length} categories</p>
            </div> */}
            <div className='product__wrapper--add'>
                <p className='product__title--page'>Add package  - اضافة حزمة  </p>
                <Form form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
                    onFinish={handleChangeData}
                    autoComplete="off"
                >

                    <Form.Item
                        label="صوره الحزمة"
                        name="image"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                        className="full-width"
                    >
                        <Upload
                            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            listType="picture"
                            className="upload-list-inline"
                            maxCount={1}
                            >
                            <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                    </Form.Item>

                    <Form.Item
                        label=" (ar) اسم الحزمة"
                        name="NameLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label=" (en) اسم الحزمة"
                        name="Name"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label=" (ar) وصف الحزمة"
                        name="DescriptionLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label=" (en) وصف الحزمة"
                        name="Description"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="سعر الحزمة"
                        name="price"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input type="number" />
                    </Form.Item>
                    <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};
