import { Input } from "antd";
import React, { useState } from "react";

export const UpdateOrderWhenOrderCanceled = () => {
  const [assignCompanyName, setAssignCompanyName] = useState("");

  return (
    <>
      <p>Assign this order to another company?</p>
      <Input
        placeholder="Company Name"
        onChange={(e) => {
          setAssignCompanyName(e.target.value);
        }}
        type="textarea"
      />
    </>
  );
};
