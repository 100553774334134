import { Card, message, Radio } from "antd";
import React, { useEffect, useState } from "react";
import "./style.scss";
import subscriptionIcon from "../../images/subscription.svg";
import LeadsHome from "../../images/leadsHome.svg";
import newLeads from "../../images/newleads.svg";
import { Line, Pie } from "react-chartjs-2";
import Api from "../../Network/ApiConfig";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { CalendarOutlined } from "@ant-design/icons";
import {
  FaCreditCard,
  FaMapMarkedAlt,
  FaPercent,
  FaTruckMoving,
  FaUsers,
  FaUserTie,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import ar from "../../localization/ar";
import en from "../../localization/en";
const { Meta } = Card;
export const Dashboard = () => {
  const [dashboardDate, setSetDashboardData] = useState(null);
  const [value, setValue] = useState(1);
  const [Loading, setLoading] = useState(true);
  const [lineData, setLineData] = useState({});
  const [pieData, setPieData] = useState({});
  const { currentLang } = useSelector((state) => state?.user);

  const history = useHistory();
  const { pathname } = useLocation();

  const t = currentLang === "en" ? en : ar;
  const onChange = (e) => {
    setValue(e.target.value);
  };

  const handleRouteToComponent = (route) => {
    history.push(route);
  };

  const fetchHomeData = () => {
    Api.get("AdminAPIs/AdminStatistics").then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setSetDashboardData(res?.data?.model);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
    // Api.get('AdminAPIs/AdminStatistics').then(res => {
    //     if (res.data.validation.length > 0) {
    //         message.error(res.data.validation[0])
    //     }
    //     // if (res.data.code === 200) {
    //     //     setSetDashboardData(res.data.data);

    //     //     const data = {
    //     //         labels: res.data.data?.charts.months,
    //     //         datasets: [
    //     //             {
    //     //                 label: "Profile Views",
    //     //                 data: res.data.data?.charts?.profile_views,
    //     //                 fill: true,
    //     //                 backgroundColor: "rgba(75,192,192,0.2)",
    //     //                 borderColor: "rgba(75,192,192,1)",
    //     //                 pointBorderColor: "rgba(0 ,122, 255, 1)",
    //     //                 pointBackgroundColor: "rgba(0 ,122, 255, 1)"
    //     //             },
    //     //             {
    //     //                 label: "Product Views",
    //     //                 data: res.data.data?.charts?.product_views,
    //     //                 fill: true,
    //     //                 backgroundColor: "rgba(0, 26, 98, 0.2)",
    //     //                 borderColor: "rgba(0, 26, 98, 1)",
    //     //                 pointBorderColor: "rgba(215 ,215 ,215,1)",
    //     //                 pointBackgroundColor: "rgba(215 ,215 ,215,1)"
    //     //             },
    //     //         ]
    //     //     }

    //     //     let pieLabels = Object.keys(res.data.data?.charts?.UniqueEngagementPercentage).map(key => {

    //     //         return key.split(/(?=[A-Z])/).join(' ')
    //     //     });

    //     //     const pieData = {

    //     //         labels: pieLabels,
    //     //         datasets: [
    //     //             {
    //     //                 label: '# of Votes',
    //     //                 data: Object.values(res.data.data?.charts?.UniqueEngagementPercentage),
    //     //                 backgroundColor: [
    //     //                     'rgba(0, 122, 255, 1)',
    //     //                     'rgba(251, 136, 50, 1)',
    //     //                     'rgba(230, 229, 230,1)',
    //     //                     'rgba(75, 192, 192, 1)',
    //     //                     'rgba(144, 19, 254, 1)',
    //     //                     'rgba(19 ,226 ,254,1)',
    //     //                 ],
    //     //                 borderColor: [
    //     //                     'rgba(0, 122, 255, 1)',
    //     //                     'rgba(251, 136, 50, 1)',
    //     //                     'rgba(230, 229, 230,1)',
    //     //                     'rgba(75, 192, 192, 1)',
    //     //                     'rgba(144, 19, 254, 1)',
    //     //                     'rgba(19 ,226 ,254,1)',
    //     //                 ],
    //     //                 borderWidth: 0,
    //     //                 margin: 10
    //     //             },
    //     //         ],
    //     //         legend: {
    //     //             fullWidth: false
    //     //         }
    //     //     };
    //     //     setPieData(pieData)
    //     //     setLineData(data)
    //     // }
    // })
  };

  useEffect(() => {
    fetchHomeData();
  }, []);

  return (
    <div className="dashboard__wrapper">
      <h2>{t.Dashboard}</h2>

      <div className="dashboard__wrapper--first">
        {/* <Card className="card-view-holder" hoverable loading={Loading}>
                    <img src={subscriptionIcon} alt='subscriptionIcon' />
                    <Meta title={<p> <span className='inter'> Current Subscription </span> <span>الاشتراك الحالي</span> </p>} />
                    <p className='second__text'>{dashboardDate?.Statistics?.current_subscription?.name}</p>
                    <div className='subscription__date'>
                        <p className='second__text' style={{ display: 'inline-block' }}> <span className='calender-icon'> <CalendarOutlined /> </span> تاريخ الانتهاء | {dashboardDate?.Statistics?.current_subscription?.endDate}</p>
                    </div>
                </Card> */}
        <Card
          className="card-view-holder"
          onClick={() =>
            handleRouteToComponent("/reports/activeAndInActiveCustomersReport")
          }
          hoverable
          loading={Loading}
        >
          <FaUsers />
          <Meta title={<p>{t.Customers}</p>} />
          <p className="second__text number">{dashboardDate?.CustomersCount}</p>
        </Card>
        <Card
          className="card-view-holder"
          onClick={() => {
            handleRouteToComponent("/companies");
          }}
          hoverable
          loading={Loading}
        >
          <FaTruckMoving />
          <Meta title={<p> {t.Companies}</p>} />
          <p className="second__text number">{dashboardDate?.CompaniesCount}</p>
        </Card>
        <Card
          className="card-view-holder"
          onClick={() => {
            handleRouteToComponent("/Chauffeurs");
          }}
          hoverable
          loading={Loading}
        >
          <FaUserTie />
          <Meta title={<p> {t.Chauffeurs}</p>} />
          <p className="second__text number">{dashboardDate?.DliveriesCount}</p>
        </Card>
      </div>
      <div className="dashboard__wrapper--first">
        {/* <Card className="card-view-holder" hoverable loading={Loading}>
                    <img src={subscriptionIcon} alt='subscriptionIcon' />
                    <Meta title={<p> <span className='inter'> Current Subscription </span> <span>الاشتراك الحالي</span> </p>} />
                    <p className='second__text'>{dashboardDate?.Statistics?.current_subscription?.name}</p>
                    <div className='subscription__date'>
                        <p className='second__text' style={{ display: 'inline-block' }}> <span className='calender-icon'> <CalendarOutlined /> </span> تاريخ الانتهاء | {dashboardDate?.Statistics?.current_subscription?.endDate}</p>
                    </div>
                </Card> */}
        <Card
          className="card-view-holder"
          onClick={() => {
            handleRouteToComponent("/offers");
          }}
          hoverable
          loading={Loading}
        >
          <FaPercent />
          <Meta title={<p>{t.offers}</p>} />
          <p className="second__text number">{dashboardDate?.OffersCount}</p>
        </Card>
        <Card
          className="card-view-holder"
          onClick={() => handleRouteToComponent("/order")}
          hoverable
          loading={Loading}
        >
          <FaMapMarkedAlt />
          <Meta title={<p> {t.Orders}</p>} />
          <p className="second__text number">{dashboardDate?.OrdersCount}</p>
        </Card>
        <Card
          className="card-view-holder"
          onClick={() => handleRouteToComponent("/reports/PaymentReport")}
          hoverable
          loading={Loading}
        >
          <FaCreditCard />
          <Meta title={<p> {t.Payments}</p>} />
          <p className="second__text number">{dashboardDate?.TotalPayment}</p>
        </Card>
      </div>
      <div className="dashboard__wrapper--first third-row">
        {/* <Card className="card-view-holder" hoverable loading={Loading}>
                    <div className='card__header--wrapper'>
                        <img src='/images/tenders.svg' alt='subscriptionIcon' />
                        <Meta title={<p className='inter'> Tenders <span>المناقصات</span> </p>} />
                    </div>
                    <div className='card__footer-wrapper'>
                        <p className='second__text number'>
                            <span className='inter'> New Tenders: </span> {dashboardDate?.Statistics?.tenders?.myTenders}</p>
                        <p className='second__text number'>
                            <span className='inter'>My Tenders: </span> {dashboardDate?.Statistics?.tenders?.openedTenders}</p>
                    </div>
                </Card> */}
      </div>

      {/* <div className='charts__wrapper'>
                <div className='cline__charts--view'>
                    <p>رسم بياني للمشاهدات</p>
                    <p className='inter'>Views Chart(Profile Views: 1048 - Product Views: 1404) </p>
                    <Line
                        legend={{
                            display: true,
                            labels: {
                                boxWidth: 50,
                                usePointStyle: true
                            }
                        }}
                        height={100} data={lineData} />
                </div>

                <div className='cline__charts--view pie'>
                    <p>نسبة تفاعل العملاء</p>
                    <p className='inter'>Unique Engagement Percentage </p>
                    <Pie
                        legend={{
                            display: true,
                            labels: {
                                boxWidth: 50,
                                usePointStyle: true
                            }
                        }} height={100} data={pieData} />
                </div>
            </div> */}
    </div>
  );
};
