import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, message, Select, Tag } from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../Component/jsonsStyles/styleJson";
import { useSelector } from "react-redux";
import en from "../../localization/en";
import ar from "../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const ContactUs = () => {
  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [TotalCount, setTotalCount] = useState(30);

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const columns = [
    {
      key: "Id",
      title: <p className="table-header-text">{t.id} </p>,
      dataIndex: "Id",
    },
    {
      title: <p className="table-header-text">{t.name} </p>,
      dataIndex: currentLang === "en" ? "Name" : "NameLT",
      className: "no-wrap",
    },
    {
      title: <p className="table-header-text"> {t.Phonenumber}</p>,
      dataIndex: "user_phone",
      render: (_, record) =>
        record?.PhoneNumber && (
          <a href={`tel:${record?.PhoneKey + record?.PhoneNumber}`}>
            {record?.PhoneKey + record?.PhoneNumber}{" "}
          </a>
        ),
    },
    {
      title: <p className="table-header-text"> {t.EmailAddress} </p>,
      dataIndex: "Email",
    },
    {
      title: <p className="table-header-text"> {t.contacttype} </p>,
      dataIndex: "ContactType",
      render: (_, record) => {
        if (record?.ContactTypeId == 1) {
          return t.Individual;
        } else if (record?.ContactTypeId == 2) {
          return t.Chauffeur;
        } else if (record?.ContactTypeId == 3) {
          return t.Corporate;
        }
      },
    },
    {
      title: <p className="table-header-text">{t.Message}</p>,
      dataIndex: "Message",
    },
    {
      title: <p className="table-header-text"> {t.dateMessage}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("YYYY-MM-DD ")} </p>;
      },
    },
  ];

  const fetchListData = (index = 0) => {
    setLoading(true);
    Api.get(`AdminAPIs/GetContactUs?Page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setCallBack(res?.data?.model);
          setCallBackList(res?.data?.model);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchListData();
  }, []);

  useEffect(() => {
    const data = callBack?.map((item) => {
      let obj = {};
      columns.map((dataIndex) => {
        if (dataIndex.dataIndex === "date")
          return (obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] =
            moment(item[dataIndex.dataIndex]).format("DD/MM/YYYY"));
        else
          return (obj[dataIndex.title.props.children[0]?.toUpperCase()] =
            item[dataIndex.dataIndex]);
      });
      return obj;
    });
    setExcelData(data);
  }, [columns, callBack]);

  return (
    <div className="product__wrapper">
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">{t.contactUs}</p>
          </div>
        }
        columns={columns || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
      />
    </div>
  );
};
