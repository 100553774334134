import React, { useEffect, useState } from 'react';
import { Button, Select, Tag, Modal, message } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Api from '../../../Network/ApiConfig'
import { DeleteFilled, ExclamationCircleOutlined, EditFilled } from '@ant-design/icons';
import Input from 'antd/lib/input/Input';

const { Option } = Select
const { confirm } = Modal

export const SalesTeams = () => {
    const history = useHistory();
    const [salesTeams, setSalesTeams] = useState([]);
    const [salesTeamsFiltered, setSalesTeamsFiltered] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultCurrentVal, setDefaultCurrentVal] = useState(10);

    const fetchSalesTeam = (url, pagSize) => {
        let body = {
            pagination_number: pagSize ? pagSize : defaultCurrentVal
        }

        setLoading(true)
        Api.post(url ? url : 'salesTeams', body).then(res => {
            if (res.data.validation.length > 0) {
                message.error(res.data.validation[0])
            }
            if (res.data.code === 200) {
                setSalesTeams(res.data.data['sales teams']);
                setSalesTeamsFiltered(res.data.data);
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchSalesTeam()
    }, []);

    const columns = [
        {
            title: <p className='table-header-text'> Title <span>الوظيفة</span> </p>,
            dataIndex: 'title',
            sorter: {
                compare: (a, b) => a.title < b.title || a.title > b.title,
            },
        },
        {
            title: <p className='table-header-text'> Name  <span> الاسم </span></p>,
            dataIndex: 'name',
            sorter: {
                compare: (a, b) => a.name < b.name || a.name > b.name,
            },
        },
        {
            title: <p className='table-header-text'> Email <span> البريد الالكتروني </span></p>,
            dataIndex: 'email',
        },
        {
            title: <p className='table-header-text'> phone <span> الموبايل </span></p>,
            dataIndex: 'phone',
        },
        {
            title: <p className='table-header-text'> Order <span> الترتيب </span></p>,
            dataIndex: 'rank',
        },
        {
            title: <p className='table-header-text'> Actions <span> افعال </span></p>,
            dataIndex: 'actions',
            render: (_, record) => {
                return (
                    <div>
                        <EditFilled onClick={() => handleGetProductData(record)} style={{ color: '#612166', fontSize: '18px', margin: '10px', cursor: 'pointer' }} />
                        <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeleteSubCategories(record)} />
                    </div>
                );
            }
        },
    ];

    const handleGetProductData = (rec) => {
        history.push({
            pathname: '/profile/add-sales-team',
            state: rec
        });
    }

    const handleDeleteSubCategories = (record) => {
        let body = new FormData();
        body.append('confirm', 1);
        body.append('salesTeamId', record.id);

        confirm({
            title: `Delete This member?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                Api.post('salesTeams/delete', body).then(res => {
                    if (res.data.validation.length > 0) {
                        message.error(res.data.validation[0])
                    }
                    if (res.data.code === 200) {
                        message.success(res.data.message)
                        fetchSalesTeam();
                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleFilterList = (value) => {
        if (!!value) {
            let salesFiltered = salesTeamsFiltered?.['sales teams'].filter(i => i.name.toLowerCase().includes(value.toLowerCase()));
            setSalesTeams(salesFiltered)
        } else {
            fetchSalesTeam()
        }
    }
    return (
        <div className='product__wrapper'>
            <TableGrid title={
                <div className='table__filter'>
                    <p className='table__title'> Sales Team <span> فريق المبيعات </span></p>
                    <div className='table__filter--count'>
                        <p>Show</p>
                        <Select
                            defaultValue={defaultCurrentVal}
                            placeholder="Lead status"
                            onChange={(value) => {
                                setDefaultCurrentVal(value);
                                fetchSalesTeam(null, value);
                            }}
                        >
                            <Option value={10}> 10 </Option>
                            <Option value={20}> 20 </Option>
                            <Option value={30}> 30 </Option>
                            <Option value={40}> 40 </Option>
                        </Select>
                        <span>entries</span>
                    </div>
                    <div className='search__list--input'>
                        <Input placeholder='Search For Name' onChange={(e) => handleFilterList(e.target.value)} />
                    </div>
                    <div className='product__btn'>
                        <Button className='primary' onClick={() => history.push({
                            pathname: '/profile/add-sales-team', state: null
                        })}>New Sales</Button>
                    </div>
                </div>
            }
                columns={columns}
                dataSource={salesTeams}
                loading={loading}
                pagination={{ pageSize: defaultCurrentVal }}
                defaultCurrent={defaultCurrentVal}
                total={salesTeamsFiltered?.pagination?.total}
                onChange={(pagination) => {
                    let url = `salesTeams=${pagination?.current}`
                    fetchSalesTeam(url)
                }}
                pageSize={30}
            />
        </div>
    );
};
