import React, { Component } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setCurrentUser } from "../../redux/user/user.action";
import localization from "../../Localization2/Localization.json";
import OtpInput from "react-otp-input";
import en from "../../localization/en";
import ar from "../../localization/ar";
import Api from "../../Network/ApiConfig";
import Logo from "../../images/logo.svg";

// import TiSocialFacebookCircular from 'react-icons/lib/ti/social-facebook-circular';
const t = en;

class PhoneVerify extends Component {
  constructor() {
    super();
    this.state = {
      btnLoading: false,
      isLoginValid: false,
      otp: "",
      timer: 0,
    };
    this.timerInt = "";
  }
  componentDidMount() {
    // console.log(this.props.location.state, ".location.state")
    this.state.timer === 120 &&
      setTimeout(() => this.setState({ timer: this.state.timer - 1 }), 1000);
  }
  // componentDidUpdate(prevProps,prevState){
  //   if(prevState.timer !== this.state.timer){
  //     this.timerInt =
  //          this.state.timer > 0 ? setTimeout(() => this.setState({timer:this.state.timer-1}), 1000):
  //          this.props.history.replace("/signin");
  //   }
  // }
  // componentWillUnmount(){
  //   clearInterval(this.timerInt);
  // }
  handleChange = (otp) => this.setState({ otp });

  routeToDashboard = (values) => {
    this.setState({ btnLoading: true });
    let userOtp = this?.state?.otp;
    let body = {
      PhoneKey: this?.props?.location?.state?.PhoneKey,
      PhoneNumber: this?.props?.location?.state?.PhoneNumber,
      NewPassword: values?.NewPassword,
      ConfirmPassword: values?.ConfirmPassword,
      Code: userOtp,
    };

    Api.post("AdminAPIs/ResetPassword", body).then(
      (res) => {
        console.log(res);
        this.setState({ btnLoading: false });
        // console.log(res, "sdfasdfsddf")
        if (res?.Code) {
          // console.log(res, "sdfasdfsddf")
          // console.log(res?.message, "sdfasdfsddf")
          message.error(res?.message);
        }
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
          this.props.history.push({ pathname: "/login" });
        }
        if (res?.status === 400) {
          console.log(res, "errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
          message.error(res?.errors?.message);
        }
      },
      (err) => {
        this.setState({ btnLoading: false });
        console.log(err?.message, "err?.message");
        message.error(err?.message);
      }
    );
  };

  handelResendCode = () => {
    let body = {
      PhoneKey: this?.props?.location?.state?.PhoneKey,
      PhoneNumber: this?.props?.location?.state?.PhoneNumber,
    };

    Api.post("Users/ReSendVerificationCode", body).then(
      (res) => {
        console.log(res);
        this.setState({ btnLoading: false });
        if (res?.Code) {
          message.error(res?.message);
        }
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);
        }
        if (res?.status === 400) {
          console.log(res, "errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
          message.error(res?.errors?.message);
        }
      },
      (err) => {
        this.setState({ btnLoading: false });
        console.log(err?.message, "err?.message");
        message.error(err?.message);
      }
    );
  };

  render() {
    const { btnLoading, isLoginValid } = this.state;
    const { currentLang } = this.props;
    return (
      <div className="auth-wrapper page-wrapper-no-header">
        <div className="auth-form-content">
          <Link to="/" className="logo-holder">
            <img src={Logo} alt="logo" />
          </Link>
          <p className="form-hint-h">Please enter the verification code sent</p>
          <Form
            name="personalInfo"
            className="form__wrapper form__wrapper--product forget-form"
            onFinish={this.routeToDashboard}
            autoComplete="off"
          >
            {/* <span className="form-mail-s">{this.props.location.state.PhoneKey + this.props.location.state.PhoneNumber}</span> */}
            <div className="otp-form-holder">
              <OtpInput
                value={this.state.otp}
                isInputNum
                hasErrored={true}
                shouldAutoFocus
                onChange={this.handleChange}
                numInputs={4}
                separator={<span></span>}
                className="input-otp"
              />
              {this.state.timer ? (
                <p className="resend-verify" style={{ marginBottom: "15px" }}>
                  Resend the code after {this.state.timer} second
                </p>
              ) : (
                <div
                  className="resend-link-holder"
                  onClick={this.handelResendCode}
                >
                  Resend code
                </div>
              )}

              <Form.Item
                name="NewPassword"
                className="input-holder"
                label={t.NewPassword}
                rules={[
                  {
                    required: true,
                    message: `${t.Pleaseinput} ${t.NewPassword}`,
                  },
                  {
                    min: 8,
                    message: `${t.Password8characters}`,
                  },
                  {
                    max: 20,
                    message: `${t.Password20characters}`,
                  },
                ]}
              >
                <Input.Password placeholder={t.NewPassword} />
              </Form.Item>

              <Form.Item
                name="ConfirmPassword"
                dependencies={["NewPassword"]}
                className="input-holder"
                label={t.ReenterPassword}
                rules={[
                  {
                    required: true,
                    message: `${t.Pleaseinput} ${t.ReenterPassword}`,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("NewPassword") === value) {
                        // eslint-disable-next-line no-undef
                        return Promise.resolve();
                      }

                      // eslint-disable-next-line no-undef
                      return Promise.reject(new Error(`${t.Thetwopasswords}`));
                    },
                  }),
                ]}
              >
                <Input.Password placeholder={t.ReenterPassword} />
              </Form.Item>
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                block
                loading={btnLoading}
                // onClick={this.routeToDashboard}
                style={{ marginTop: 10, width: "100%" }}
              >
                confirm
              </Button>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
});

const LoginWithRouter = withRouter(PhoneVerify);
export default connect(mapStateToProps)(LoginWithRouter);
