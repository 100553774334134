import "./antd.css";
import "./style.scss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import Routes from "./Routes";
import { ConfigProvider } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout } from "antd";
import { SideBar } from "./pages/Layout/SideBar";
import { MainHeader } from "./pages/Layout/Header";
import login from "./pages/Login/login";
import { createBrowserHistory } from "history";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import PhoneVerify from "./pages/PhoneVerify/PhoneVerify";
import onMessageListener, { requestForToken } from "./firebase";
import Api from "./Network/ApiConfig";
import { setNotificationList } from "./redux/user/user.action";
import moment from "moment";
import "moment/locale/ar"; // without this line it didn't work
import "react-quill/dist/quill.snow.css";
import dayjs from "dayjs";
import uts from "dayjs/plugin/utc";
import duration from "dayjs/plugin/duration";

const { Content } = Layout;
const history = createBrowserHistory();
const token = localStorage.getItem("token");

dayjs.extend(duration);
dayjs.extend(uts);
class App extends Component {
  componentDidMount() {
    if (this.props.currentLang === "ar") moment.locale("ar");
    else moment.locale("en");

    // console.log(Window.Notification)
    this.handelAddNotification();
    // GetAllNotification((res) => {
    //   this.props.dispatch(setNotificationList(res?.data?.model));
    //   // audioTune.play();
    //   }, (err) => {})
    onMessageListener((payload) => {
      // console.log(payload, "rrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
      Api.get("Users/GetAllNotification?Page=0").then(
        (res) => {
          console.log("notificationres", res);

          // dispatch(setNotificationList(res?.data?.model));
          this.props.dispatch(setNotificationList(res?.data?.model || []));
        },
        (err) => {
          console.log(err);
        }
      );
      // // audioTune.play();
      // }, (err) => {})
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { currentLang } = this.props;
    if (currentLang !== prevProps?.currentLang) {
      if (this.props.currentLang === "ar") moment.locale("ar");
      else moment.locale("en");
    }
  }

  handelAddNotification = async () => {
    const userToken = await requestForToken();
    // console.log(userToken, "userToken")
    let body = {
      Token: userToken,
      OS: "WEB",
    };
    // console.log(userToken)
    Api.post("Users/AddPushToken", body).then(
      (res) => {
        Api.get("Users/GetAllNotification?Page=0").then(
          (res) => {
            // dispatch(setNotificationList(res?.data?.model));
            this.props.dispatch(setNotificationList(res?.data?.model || []));
          },
          (err) => {
            console.log(err);
          }
        );
      },
      (err) => {
        // message.error(err?.message);
      }
    );
  };

  render() {
    const { currentLang, currentUser } = this.props;
    return (
      <div className="app">
        <ConfigProvider direction={currentLang === "en" ? "ltr" : "ltr"}>
          <Router history={history}>
            <Switch>
              {currentUser ? (
                <Layout>
                  <SideBar />
                  <Layout className="site-layout">
                    <MainHeader />
                    <Content
                      className="site-layout-background"
                      style={{
                        padding: 24,
                      }}
                    >
                      <Routes />
                    </Content>
                  </Layout>
                </Layout>
              ) : (
                <>
                  <Route exact path="/login" component={login} />
                  <Route
                    exact
                    path="/phone-verification"
                    component={PhoneVerify}
                  />
                  <Route
                    exact
                    path="/forget-password"
                    component={ForgetPassword}
                  />
                  {history.location?.pathname?.includes("/forget-password") ? (
                    <>
                      <Route
                        exact
                        path="/forget-password"
                        component={ForgetPassword}
                      />
                      {/* <Redirect to={'/forget-password'} />  */}
                    </>
                  ) : history.location?.pathname?.includes(
                      "/phone-verification"
                    ) ? (
                    <>
                      <Route
                        exact
                        path="/phone-verification"
                        component={PhoneVerify}
                      />
                      {/* <Redirect to={'/phone-verification'} />  */}
                    </>
                  ) : (
                    <>
                      <Route exact path="/login" component={login} />
                      {/* <Route
                      exact 
                      path='/phone-verification' 
                      component={PhoneVerify}
                    />
                     <Route
                      exact
                      path='/forget-password' 
                      component={ForgetPassword}
                    /> */}
                      <Redirect to={"/login"} />
                    </>
                  )}
                </>
              )}
            </Switch>
          </Router>
        </ConfigProvider>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  currentLang: state.user.currentLang,
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(App);
