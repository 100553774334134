import { Button, Checkbox, Input, message, Upload, Modal, Pagination } from "antd"
import './style.scss'
import Api from "../../../Network/ApiConfig";
import { useEffect, useState } from "react";
import { DeleteFilled, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";
const { confirm } = Modal;

export const NewsFeed = () => {
    const [postValue, setPostValue] = useState('');
    const [postList, setPostList] = useState([]);
    const [imgList, setImgList] = useState([]);
    const [pdfList, setPdfList] = useState([]);
    const [titleValue, setTitleValue] = useState('');
    const [selectedPost, setSelectedPost] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);

    function itemRender(current, type, originalElement) {
        if (type === 'prev')
            return <a>Previous</a>;

        if (type === 'next')
            return <a>Next</a>;

        return originalElement;
    }

    const handleFetchPostList = (url, update) => {
        let body = {
            pagination_number: 10
        }
        Api.post(url ? url : 'posts/my-posts', body).then(res => {
            let data = res.data.data

            let postData = res.data.data?.my_posts.map(post => {
                let obj = post;
                obj.showPost = true;
                return obj;
            })

            data.my_posts = postData; 
            setPostList(data);
            if (update) {
                setSelectedPost(res.data.data?.my_posts?.find(i => i.id === selectedPost?.id))
            }
        })
    }

    useEffect(() => handleFetchPostList(), [])

    const handleAddNewPost = () => {
        let header = {
            'Content-Type': 'multipart/form-data;'
        }
        setBtnLoading(true)
        let body = new FormData();
        body.append("title", titleValue);
        body.append("body", postValue);
        if (pdfList.length) body.append("media", pdfList[0]);

        if (imgList.length) {
            imgList?.map(file => {
                body.append("media[]", file)
            })
        };

        if (!selectedPost) {
            Api.post('posts/store', body, header).then(res => {
                setBtnLoading(false)
                if (res.data.validation?.length)
                    message.error(res.data.validation[0]);
                else if (res.data.code === 200) {
                    message.success(res.data.message);
                    handleFetchPostList();
                    setPostValue('');
                    setTitleValue('');
                    setImgList([]);
                    setPdfList([])
                }
            })
        } else {
            body.append('postId', selectedPost.id)
            Api.post('posts/update', body, header).then(res => {
                setBtnLoading(false)
                if (res.data.validation?.length)
                    message.error(res.data.validation[0]);
                else if (res.data.code === 200) {
                    message.success(res.data.message);
                    handleFetchPostList();
                    setPostValue('');
                    setSelectedPost(null);
                    setTitleValue('');
                    setImgList([]);
                    setPdfList([]);
                }
            })
        }
    }

    const handleDeletePostMedia = (post) => {
        let body = new FormData();

        body.append('postMediaId', post.id);

        confirm({
            title: `Delete This Post Media?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                Api.post('posts/delete/postMedia', body).then(res => {
                    if (res.data.validation.length > 0) {
                        message.error(res.data.validation[0])
                    }
                    if (res.data.code === 200) {
                        message.success(res.data.message || 'successfully')
                        handleFetchPostList(null, true);

                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }

    const handleDeletePost = (post) => {
        let body = new FormData();

        body.append('postId', post.id);

        confirm({
            title: `Delete This Post Media?`,
            icon: <ExclamationCircleOutlined />,
            okText: 'Delete',
            cancelText: 'Cancel',
            onOk: () => {
                Api.post('posts/delete', body).then(res => {
                    if (res.data.validation.length > 0) {
                        message.error(res.data.validation[0])
                    }
                    if (res.data.code === 200) {
                        message.success(res.data.message || 'successfully')
                        handleFetchPostList(null, true);

                    }
                })
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    }
    
    const handleEditPost = (post) => {
        Api.get(`posts/show?postId=${post.id}`,).then(res => {
            setSelectedPost(res.data.data.post);
            setPostValue(res.data.data.post.body);
            setTitleValue(res.data.data.post.title);

            document.getElementById('postHandle').scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        })
    }

    const handleShowPostMedia = (post, checked) => {
        console.log({ checked })
        let data = postList

        let postData = postList?.my_posts.map(item => {
            let obj = item;
            if (post.id === item.id)
                obj.showPost = checked;
            return obj;
        });
        data.my_posts = postData;
        setPostList(data)
    }

    return (
        <div className="newsFeed__wrapper">
            <div style={{ borderRadius: '10px', marginBottom: '10px' }} className='assign__cate--header'>
                <p style={{ fontWeight: 'bold' }} className='inter'>You have: {postList.pagination?.total} posts</p>
            </div>

            <div id="postHandle" class="post__panel--box">
                <div className="input-wrapper">
                    <Input style={{ height: '50px', borderBottom: '1px solid #cecece' }} value={titleValue} onChange={(e) => setTitleValue(e.target.value)} placeholder="اكتب عنوان المنشور" />
                    <Input value={postValue} onChange={(e) => setPostValue(e.target.value)} placeholder="اكتب منشور جديد" />
                    {selectedPost &&
                        <div className="post__media--edit">
                            {selectedPost?.media?.map((img) => (
                                <>
                                    <img src={img.media} alt="media" />
                                    <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeletePostMedia(img)} />
                                </>
                            ))}
                        </div>
                    }
                    <div className="media-wrapper">
                        <div>
                            <Upload fileList={imgList} action={(file) => setImgList([...imgList, file])} accept="image/png, image/gif, image/jpeg">
                                <Button icon={<img src='/images/uploadImg.svg' alt="upload" />}>صورة</Button>
                            </Upload>

                            <Upload fileList={pdfList} action={(file) => setPdfList([...pdfList, file])} accept="video/mp4,video/x-m4v,video/*">
                                <Button icon={<img src='/images/uploadVideo.svg' alt="upload" />}>فيديو</Button>
                            </Upload>
                        </div>
                        <Button loading={btnLoading} disabled={!!!postValue && !!!titleValue} onClick={handleAddNewPost} className="primary">{selectedPost ? 'تعديل' : 'اضافة'}</Button>
                    </div>
                </div>
            </div>

            <div className="posts__body--wrapper">
                {postList.my_posts?.map((post) => (
                    <div key={post.id} style={{ marginBottom: '15px' }}>
                        <div className="post__info">
                            <div className="post--header">
                                <div>
                                    <p> {post.title} </p>
                                    <span className="post__date">{post.post_date}</span>
                                </div>
                                <Checkbox onChange={(e) => handleShowPostMedia(post, e.target.checked)} checked={post.showPost}>
                                    اظهار المنشور علي الموقع
                                </Checkbox>
                            </div>
                            <div className="post--body">
                                <p> {post.body} </p>
                            </div>

                            <div className="post__media">
                                {post?.media?.map((img) => (
                                    <img src={img.media} alt="media" />
                                ))}
                            </div>
                            <div className="post__actions">
                                <span>
                                    <EditFilled onClick={() => handleEditPost(post)}
                                        style={{ color: '#612166', fontSize: '18px', margin: '10px', cursor: 'pointer' }} />
                                </span>
                                <span>
                                    <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeletePost(post)} />
                                </span>
                            </div>


                        </div>
                        <div className="comment__wrapper">
                            {post?.comments?.map((com, idx) => (
                                <div key={idx} className="comment-info">
                                    <h2> {com.created_at && moment(com.created_at).format('DD/MM/YYYY')} </h2>
                                    <p>{com.comment}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>

            <Pagination
                defaultPageSize={10}
                defaultCurrent={postList?.my_posts_count}
                total={postList.pagination?.total}
                onChange={(page) => {
                    let url = `posts/my-posts?page=${page}`
                    handleFetchPostList(url)
                }}
                itemRender={itemRender}
            />
        </div>
    )
}