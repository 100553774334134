import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message, Input } from 'antd';
import './style.scss'
import Api from '../../Network/ApiConfig'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const AddCountry = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const supplierInfo = JSON.parse(localStorage.getItem('userInfo'))
    const {  currentLang } = useSelector((state) => state?.user);

    const t = currentLang === "en" ? en : ar;
    const location = useLocation();

    
    const fetchCategoriesList = () => {
      let body = {
          pagination_number: 100
      }
      Api.post('categories/unassigned', body).then(res => {
          if (res.data.validation.length > 0) {
              message.error(res.data.validation[0]);
          }
          if (res.data.code === 200) {
              setCategoryList(res.data.data.unAssignedCategories);
          }
      })
  }

    useEffect(() => {
        // fetchCategoriesList();
        if (location?.state) {

      }
    }, []);


    const onFinish = (values) => {
        console.log(values);
        setLoading(true);

        let body = {
          Name: values?.Name,
          NameLT: values?.NameLT,
          // Description: values?.Description,
          // DescriptionLT: values?.DescriptionLT,
        };

        if(location?.state){
          body.Id = location?.state?.Id;
          Api.post("AdminAPIs/EditCountry", body).then(
            (res) => {
              console.log(res?.data?.metas?.message);
              setLoading(false);
  
              if(res?.Code){
                message.error(res?.message);
              }
              if(res?.status === 200){
          message.success(res?.data?.metas?.message);

                history.push({
                  pathname: '/countries',
              })
              }
              if(res?.status === 400){
                message.error(res?.errors?.message);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err?.message)
              message.error(err?.message);
            }
          );
          return false;
        }

        Api.post("AdminAPIs/CreateCountry", body).then(
          (res) => {
            console.log(res);
            console.log(res?.data?.metas?.message);
            // message.success(res?.data?.model?.AspNetUsers);
            setLoading(false);
            if(res?.status === 200){
          message.success(res?.data?.metas?.message);

              history.push({
                pathname: '/countries',
            })
            }
            if(res?.status === 400){
              console.log(res)
              message.error(res?.errors?.message);
            }
          },
          (err) => {
            setLoading(false);
            console.log(err?.message)
            message.error(err?.message);
          }
        );
      };

    return (
        <div className='content__wrapper'>
            {console.log(location?.state)}
            <div className='product__wrapper--add'>
              {location?.state ? 
                <p className='product__title--page'>{t.UpdateCountry} </p> :
                <p className='product__title--page'>{t.NewCountry} </p>
              }
                <Form 
                initialValues={{
                  Name: location?.state?.Name,
                  NameLT: location?.state?.NameLT,
                }}
                form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label={t.nameAr}
                        name="NameLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item
                        label={t.nameEn}
                        name="Name"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>
                    {/* <Form.Item
                        label={t.descriptionAr}
                        name="DescriptionLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t.descriptionEn}
                        name="Description"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item> */}

                    {/* <Form.Item
                        label="نوع المركبه"
                        name="VehicleTypeId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Select>
                          {[].map((vehicle) => (
                            <Select.Option key={vehicle.Id} value={vehicle.Id}>
                              {vehicle.NameLT}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item> */}

                    <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};
