import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dropdown,
  Image,
  message,
  Select,
  Modal,
  Switch,
  Typography,
} from "antd";
import { TableGrid } from "../../../Component/Table";
import "./style.scss";
import Api from "../../../Network/ApiConfig";
import {
  DownOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import en from "../../../localization/en";
import ar from "../../../localization/ar";
import { useSelector } from "react-redux";
const { Option } = Select;
const { confirm } = Modal;
const { Paragraph, Text } = Typography;
export const Terms = () => {
  const history = useHistory();
  const { currentLang } = useSelector((state) => state?.user);

  const t = currentLang === "en" ? en : ar;

  const handelDltRowData = (Id) => {
    confirm({
      title: `${t.DoyouWanttodeletethis} ${t.Blogs}?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      onOk: () => {
        setLoading(true);
        Api.get(
          `AdminAPIs/DeleteTermsAndCondition?TermsAndConditionId=${Id}`
        ).then(
          (res) => {
            if (res?.status === 200) {
              message.success(res?.data?.metas?.message);
              fetchLeedsList();
            }
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: <p className="table-header-text"> # </p>,
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.name}</p>,
      dataIndex: currentLang === "en" ? "Name" : "NameLT",
    },
    // {
    //   title: <p className="table-header-text"> {t.description}</p>,
    //   className: "text-elp",
    //   // render: (_, record) => <p>{record?.DescriptionLT}</p>,
    //   // render: (_, record) =>  <Paragraph ellipsis>{record?.DescriptionLT}</Paragraph>
    // },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      dataIndex: "",
      render: (_, record) => {
        return (
          <div className="select-item-action" style={{ display: "flex" }}>
            <EditOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                // handelEditRowData(record?.Id);
                history.push({ pathname: "/add-term", state: record });
              }}
              style={{ marginInlineEnd: 20, fontSize: 20, cursor: "pointer" }}
            />
            <DeleteOutlined
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handelDltRowData(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const pdfRef = React.createRef();
  const [leadsList, setLeadsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableColumn, setTableColumn] = useState(columns);
  const [leadsListFiltered, setLeadsListFiltered] = useState([]);
  const [checkAll, setCheckAll] = useState(true);
  const [TotalCount, setTotalCount] = useState(30);

  // const [pdfTableColumns, setPdfTableColumns] = useState(pdfColumns);
  const checkBoxColumn = columns.map((item) => {
    return { label: item.title, value: item.dataIndex };
  });

  const fetchLeedsList = (index = 0) => {
    setLoading(true);

    Api.get(`AdminAPIs/GetTermsAndConditions?Page=${index}`).then(
      (res) => {
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setLeadsList(dataSource);
          setLeadsListFiltered(dataSource);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchLeedsList();
  }, [currentLang]);

  return (
    <div>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p style={{ fontWeight: "bold" }}>{t.TermsAndConditions}</p>

            <div className="product__btn">
              <Button
                onClick={() => history.push("/add-term")}
                className="primary"
              >
                {t.NewTerm}{" "}
              </Button>
            </div>
          </div>
        }
        columns={columns || []}
        dataSource={leadsList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchLeedsList(pagination?.current - 1);
        }}
      />
    </div>
  );
};
