import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Input,
  message,
  Select,
  Tag,
  Menu,
  Modal,
  Button,
  Switch,
} from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import { useSelector } from "react-redux";
import { MoreOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import en from "../../localization/en";
import ar from "../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const GenerateCoupon = () => {
  const history = useHistory();

  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [page, setpage] = useState(0);

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;

  const { confirm } = Modal;

  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRouteToComponent(record)}>
        Edit
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={() => handleDeleteCoupon(record)}>
        Delete
      </Menu.Item> */}
    </Menu>
  );

  const handleDeleteCoupon = (record) => {
    console.log("record", record);

    confirm({
      title: `${t.DoyouWanttodeletethis} ${t.Coupon}" "${record?.Name} ?`,
      // title: localization.DodeletetheseAddress[this.props.currentLang],
      icon: <ExclamationCircleOutlined />,
      okText: t.Ok,
      cancelText: t.Cancel,
      cancelButtonProps: {
        style: { color: "black" }, // Setting the text color to black
      },
      onOk: () => {
        setLoading(true);
        Api.get(`AdminAPIs/DeleteCoupon?CouponId=${record?.Id}`).then(
          (res) => {
            setLoading(false);
            if (res?.status === 200) {
              console.log("res", res);
              fetchListData();
              message.success(res?.data?.metas?.message);
            }
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      onCancel() {},
    });
  };
  const columns = [
    // {
    //   title: <p className="table-header-text">{t.name} </p>,
    //   dataIndex: currentLang === "en" ? "Name" : "NameLT",
    //   className: "no-wrap",
    // },
    // {
    //   title: <p className="table-header-text"> {t.Phonenumber}</p>,
    //   dataIndex: "user_phone",
    //   render: (_, record) =>
    //     record?.PhoneNumber && (
    //       <a href={`tel:${record?.PhoneKey + record?.PhoneNumber}`}>
    //         {record?.PhoneKey + record?.PhoneNumber}{" "}
    //       </a>
    //     ),
    // },
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.CouponCodeType} </p>,
      dataIndex: currentLang == ar ? "couponTypeLT" : "couponType",
    },

    // {
    //   title: <p className="table-header-text"> {t.contacttype} </p>,
    //   dataIndex: "ContactTypeId",
    //   render: (_, record) => {
    //     return (
    //       <p>
    //         {" "}
    //         {currentLang === "en"
    //           ? record?.ContactType?.Name
    //           : record?.ContactType?.NameLT}{" "}
    //       </p>
    //     );
    //   },
    // },
    {
      title: <p className="table-header-text">{t.name}</p>,
      dataIndex: "Name",
    },
    {
      title: <p className="table-header-text"> {t.active}</p>,
      render: (_, record) => (
        <Switch
          onChange={(checked) => handelChangeCouponStatus(checked, record)}
          checked={record?.IsActive}
        />
      ),
    },
    {
      title: <p className="table-header-text"> {t.CreationDate}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("DD-MM-YYYY ")} </p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={["hover"]}>
          <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
        </Dropdown>
      ),
    },
  ];

  const handelChangeCouponStatus = (status, record) => {
    Api.get(
      `AdminAPIs/CouponChangeStatus?Id=${record?.Id}&isActive=${status}`
    ).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          console.log("res", res);
          message?.success(res?.data?.metas?.message);
        }
      },
      (err) => {
        setLoading(false);
        message.error();
      }
    );
    const updatedData = callBackList.map((item) =>
      item.key === record.key ? { ...item, IsActive: status } : item
    );
    setCallBackList(updatedData);
  };

  const fetchListData = (index = 0) => {
    setLoading(true);
    setpage(index);
    Api.get(`AdminAPIs/GetCouponTypes`).then((type) =>
      Api.get(`AdminAPIs/GetCoupons?page=${index}`).then(
        (res) => {
          setLoading(false);
          if (res?.status === 200) {
            const dataSource = res?.data?.model.map((item, ind) => ({
              ...item,
              couponType: type?.data?.model?.map((ctype) => {
                if (ctype?.Id == item?.CouponTypeId) return ctype?.Name;
              }),
              couponTypeLT: type?.data?.model?.map((ctype) => {
                if (ctype?.Id == item?.CouponTypeId) return ctype?.NameLT;
              }),
              key: ind + 1 + index * 30, // Or any other unique identifier
            }));
            console.log(dataSource);
            setCallBack(dataSource);
            setCallBackList(dataSource);
          }
          if (res?.data?.metas?.TotalaCount) {
            setTotalCount(res?.data?.metas?.TotalaCount);
          }
        },
        (err) => {
          setLoading(false);
        }
      )
    );
  };

  const handleRouteToComponent = (record, route = "/coupon/addCoupon") => {
    history.push({ pathname: route, state: { ...record } });
  };

  useEffect(() => {
    fetchListData();
  }, []);

  return (
    <div className="product__wrapper">
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">{t.generateCoupon}</p>
            <Button
              onClick={() => handleRouteToComponent({}, "/coupon/addCoupon")}
              style={{
                border: "none",
                color: "#fff",
                backgroundColor: "#612166",
              }}
            >
              {" "}
              {t.generateCoupon}
            </Button>
          </div>
        }
        columns={columns || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
      />
    </div>
  );
};
