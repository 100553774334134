import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dropdown,
  Input,
  message,
  Select,
  Tag,
  Menu,
  Drawer,
  Button,
  Form,
  Space,
} from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../Component/jsonsStyles/styleJson";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { MoreOutlined } from "@ant-design/icons";
import en from "../../localization/en";
import ar from "../../localization/ar";
import parse from "html-react-parser";

const CheckboxGroup = Checkbox.Group;

export const SendCoupon = () => {
  const pdfRef = React.createRef();

  const { Option } = Select;
  const [record, setrecord] = useState({});
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [TotalCount, setTotalCount] = useState(30);
  const [open, setOpen] = useState(false);
  const [childrenDrawer, setChildrenDrawer] = useState(false);
  const [Coupons, setCoupons] = useState([]);
  const [CouponDetail, setCouponDetail] = useState({});
  const [page, setpage] = useState(0);
  const [editorContent, setEditorContent] = useState(`
   
    `);
  const [EmailTemplate, setEmailTemplate] = useState([]);
  const [SelectedEmailTemplate, setSelectedEmailTemplate] = useState({});

  const [subject, setSubject] = useState(
    "Welcome to Chauffeur! Unlock Exclusive Benefits 🎉"
  );
  const [CouponTotal, setCouponTotal] = useState();
  const [EmailType, setEmailType] = useState([]);

  const { currentLang } = useSelector((state) => state?.user);
  const [couponForm] = Form.useForm();
  const [form] = Form.useForm();

  const t = currentLang === "en" ? en : ar;
  const ActionMenu = ({ record }) => (
    <Menu>
      <Menu.Item key="1" onClick={() => showDrawer(record)}>
        Send Coupons
      </Menu.Item>
      {/* <Menu.Item key="2" onClick={() => handleDeleteCoupon(record)}>
        Delete
      </Menu.Item> */}
    </Menu>
  );
  const columns = [
    // {
    //   title: <p className="table-header-text">{t.name} </p>,
    //   dataIndex: currentLang === "en" ? "Name" : "NameLT",
    //   className: "no-wrap",
    // },
    // {
    //   title: <p className="table-header-text"> {t.Phonenumber}</p>,
    //   dataIndex: "user_phone",
    //   render: (_, record) =>
    //     record?.PhoneNumber && (
    //       <a href={`tel:${record?.PhoneKey + record?.PhoneNumber}`}>
    //         {record?.PhoneKey + record?.PhoneNumber}{" "}
    //       </a>
    //     ),
    // },
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: <p className="table-header-text"> {t.EmailAddress} </p>,
      dataIndex: "Email",
    },
    // {
    //   title: <p className="table-header-text"> {t.contacttype} </p>,
    //   dataIndex: "ContactTypeId",
    //   render: (_, record) => {
    //     return (
    //       <p>
    //         {" "}
    //         {currentLang === "en"
    //           ? record?.ContactType?.Name
    //           : record?.ContactType?.NameLT}{" "}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   title: <p className="table-header-text">{t.Message}</p>,
    //   dataIndex: "Message",
    // },
    {
      title: <p className="table-header-text"> {t.SubscribedDate}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("DD-MM-YYYY ")} </p>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Dropdown overlay={<ActionMenu record={record} />} trigger={["hover"]}>
          <MoreOutlined style={{ cursor: "pointer", fontSize: "18px" }} />
        </Dropdown>
      ),
    },
  ];

  const fetchListData = (index = 0) => {
    setpage(index);
    setLoading(true);
    setCouponTotal();
    Api.get(`AdminAPIs/GetAllNewsLettersSubscription?page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setCallBack(dataSource);
          setCallBackList(dataSource);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const showDrawer = async (record) => {
    setLoading(true);
    setrecord(record);
    await Api.get(`AdminAPIs/GetCoupons`).then(
      async (res) => {
        setLoading(false);
        // if (res?.status === 200) {
        //   setCoupons(res?.data?.model);
        // }
        if (res?.data?.metas?.TotalaCount) {
          setCouponTotal(res?.data?.metas?.TotalaCount);
          const page = Math.ceil(res?.data?.metas?.TotalaCount / 30);
          const coup = [];
          for (let i = 0; i <= page; i++) {
            await Api.get(`AdminAPIs/GetCoupons?page=${i}`).then((res) => {
              coup.push(...res?.data?.model);
            });
          }
          setCoupons(coup);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
    Api.get(`AdminAPIs/GetEmailTemplates`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          setEmailTemplate(res?.data?.model);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
    setCoupons([]);
    setChildrenDrawer(false);
    setCouponDetail({});
    couponForm.resetFields();
    form.resetFields();
  };
  const showChildrenDrawer = () => {
    // setEditorContent(`
    //      <html>
    // <body>
    //  <p>Hello ${record?.Email},</p><br /><br />
    // <p>You’ve just unlocked access to a world of exclusive perks, premium insights, and exciting offers! 🚀 We’re thrilled to welcome you aboard and can’t wait to unveil the incredible benefits we have lined up just for you:</p><br /><br />
    // <p><strong>Your Coupon Code:</strong> <em>${CouponDetail?.Code}</em><br />
    // <strong>Discount:</strong> ${CouponDetail?.RedeemAmountOrPercent} ${CouponDetail?.CouponType}<br />
    // <strong>Valid Until:</strong> ${CouponDetail?.ExpirationDate}</p><br /><br />
    // <p>Simply enter this code at checkout to enjoy your discount!</p><br />
    // <p>Get ready to experience a new level of excitement with every email! 🎉</p><br /><br />
    // <h3>Here’s What’s in Store for You:</h3><br />
    // <ul>
    //   <li>🎁 <strong>Exclusive Deals & Discounts:</strong> Be the first to access special promotions and limited-time offers.</li><br />
    //   <li>📚 <strong>Insider Tips & Insights:</strong> Get ahead of the game with expert advice directly from the pros.</li><br />
    //   <li>🔔 <strong>Sneak Peeks & Announcements:</strong> Stay informed about new products, upcoming events, and special company news before anyone else.</li><br />
    //   <li>💎 <strong>Subscriber-Only Surprises:</strong> Enjoy unique gifts and surprises that are available only to our valued subscribers.</li><br /><br />
    // </ul>
    // <h3>Ready to Dive In?</h3><br />
    // <ol>
    //   <li>Explore Our [Website]: Discover a treasure trove of resources and start making the most of your subscription.</li><br />
    //   <li>Connect with Us on [Social Media Platforms]: Join our online community for the latest updates and engaging content.</li><br /><br />
    // </ol><br />
    // <p>We’re excited to have you on board and look forward to making your experience with us exceptional. If you have any questions or need support, feel free to reply to this email or reach out at [Contact us].</p><br /><br />
    // <p>Thank you for subscribing!</p><br />
    // <p>Chauffeur Hub Team</p>

    // </body>
    // </html>`);
    const EmailType = [
      {
        Name: "Order",
        NameLT: "طلب",
        Id: 1,
        CreationDate: "2024-08-14T09:12:08.5433333",
        ModifiedDate: null,
        DeletedAt: null,
        IsDeleted: null,
      },
      {
        Name: "Promotion",
        NameLT: "ترقية",
        Id: 2,
        CreationDate: "2024-08-14T09:12:08.5433333",
        ModifiedDate: null,
        DeletedAt: null,
        IsDeleted: null,
      },
    ];
    setEmailType(EmailType);
    setChildrenDrawer(true);
  };
  const onChildrenDrawerClose = () => {
    setSelectedEmailTemplate({});
    form.resetFields();
    setChildrenDrawer(false);
  };

  const handleCouponSelect = (e) => {
    Api.get(`AdminAPIs/GetCouponById?CouponId=${e}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          getCouponDetails(res?.data?.model).then((details) =>
            setCouponDetail(details)
          );
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getCouponDetails = async (details) => {
    const cities = await getAllCities(details);
    const companies = await getDeliveryCompanies(details);
    return {
      Code: details?.Code,
      Name: currentLang == "ar" ? details?.NameLT : details?.Name,
      AllCities: details?.AllCities,
      AllCompanies: details?.AllCompanies,
      Description:
        currentLang == "ar"
          ? parse(details?.DescriptionLT)
          : parse(details?.Description),
      ExpirationDate: moment(details?.ExpirationDate).format("DD MMM YYYY"),
      IsActive: details?.IsActive,
      MaxUsesPerUser: details?.MaxUsesPerUser,
      MinOrderAmount: details?.MinOrderAmount,
      RedeemAmountOrPercent: details?.RedeemAmountOrPercent,
      CreationDate: details?.CreationDate,
      FromCity: cities?.FromCity,
      ToCity: cities?.ToCity,
      Company: companies,
      CouponType: details?.CouponTypeId == 1 ? "Discount" : "Flat",
    };
  };

  const getDeliveryCompanies = async (details) => {
    return Api.get(`AdminAPIs/GetCompanies`).then((company) => {
      return company?.data?.model?.filter(
        (comp) => details?.CompanyId == comp?.Id
      );
    });
  };

  const getAllCities = async (details) => {
    const cities = await Api.get(`AdminAPIs/GetCities`);
    const FromCity = cities?.data?.model?.filter(
      (city) => city?.Id == details?.FromCityId
    );
    const ToCity = cities?.data?.model?.filter(
      (city) => city?.Id == details?.ToCityId
    );
    return { FromCity: FromCity, ToCity: ToCity };
  };

  const sendEmail = async (values) => {
    try {
      setLoading(true);
      let body = { ...values };
      body = { Id: SelectedEmailTemplate[0]?.Id, ...body };
      // Edit existing notification template
      await Api.post(`AdminAPIs/EditEmailTemplate`, body)
        .then(async (res) => {
          if (res?.status === 200) {
            await Api.post(`AdminAPIs/SendEmail`, {
              IsAllUsers: false,
              IsNewLetterSubscription: false,
              TemplateId: SelectedEmailTemplate[0]?.Id,
              Ids: [record?.Email],
            }).then((res) => {
              if (res.status === 200) {
                message?.success("Email sent successfully!");
                form.resetFields();
                setChildrenDrawer(false);
                setOpen(false);
                setCoupons([]);
                setCouponTotal();
                couponForm.resetFields();
              } else {
                message?.error("Failed to send email.");
              }
            });
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log(err);
          // message.error(err?.response?.data?.message);
          setLoading(false);
        });
    } catch (error) {
      console.error("Error sending email:", error);
      message?.error("An error occurred while sending the email.");
    } finally {
      setLoading(false);
      setCoupons([]);
      setCouponTotal();
      setCouponDetail({});
    }
  };

  const handleEmailTypeSelect = (e) => {
    const template = EmailTemplate?.filter((type) => type?.Id == e);
    form.setFieldsValue({ ...template[0] });
    setSelectedEmailTemplate(template);
    setEditorContent(template[0]?.Body);
    setSubject(template[0]?.Subject);
  };

  useEffect(() => {
    fetchListData();
  }, []);

  return (
    <div className="product__wrapper">
      <Drawer
        title="Sending Coupons to Subscribers"
        width={520}
        onClose={onClose}
        open={open}
      >
        <>
          <div className="CouponDrawer">
            Select a Coupon to Send:
            <Form form={couponForm}>
              <Form.Item name="couponSelect">
                <Select onChange={handleCouponSelect}>
                  {Coupons?.map((type) => (
                    <Option key={type?.Id} value={type?.Id}>
                      {type?.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
            {Object?.keys(CouponDetail)?.length > 0 ? (
              <>
                <div className="Drawer-coupondetails">
                  <span>
                    <p>Coupon Name:</p> {CouponDetail?.Name}
                  </span>
                  <span>
                    <p>Coupon Code:</p> {CouponDetail?.Code}
                  </span>
                  {CouponDetail?.AllCities ? (
                    <span>
                      <p>All Cities</p> {CouponDetail?.AllCities}
                    </span>
                  ) : (
                    ""
                  )}
                  {!CouponDetail?.AllCities ? (
                    <span>
                      <p>From City:</p> {CouponDetail?.FromCity?.[0]?.Name}
                    </span>
                  ) : (
                    ""
                  )}
                  {!CouponDetail?.AllCities ? (
                    <span>
                      <p>To City:</p> {CouponDetail?.ToCity?.[0]?.Name}
                    </span>
                  ) : (
                    ""
                  )}
                  {!CouponDetail?.AllCompanies ? (
                    <span>
                      <p>Company:</p> {CouponDetail?.Company?.[0]?.Name}
                    </span>
                  ) : (
                    <span>
                      <p>All Companies:</p> Yes
                    </span>
                  )}
                  <span>
                    <p>Coupon Redeem Amount/Percent:</p>{" "}
                    {CouponDetail?.RedeemAmountOrPercent}
                  </span>
                  <span>
                    <p>Coupon Type:</p> {CouponDetail?.CouponType}
                  </span>
                  <span>
                    <p>Coupon Active:</p>{" "}
                    {CouponDetail?.IsActive ? "Yes" : "No"}
                  </span>
                  <span>
                    <p>Coupon Description:</p> {CouponDetail?.Description}
                  </span>
                  <span>
                    <p>Coupon Min Order:</p> {CouponDetail?.MinOrderAmount}
                  </span>
                  <span>
                    <p>Coupon Max Use Per Person:</p>{" "}
                    {CouponDetail?.MaxUsesPerUser}
                  </span>
                </div>
                <br />
                Select a mail template to Send:
                <Select onChange={handleEmailTypeSelect}>
                  {EmailTemplate?.map((type) => (
                    <Option key={type?.Id} value={type?.Id}>
                      {type?.Subject}
                    </Option>
                  ))}
                </Select>
                <Button
                  type="primary"
                  className="CouponDrawer-Mail-btn"
                  onClick={showChildrenDrawer}
                >
                  Edit Mail Template
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
          <Drawer
            title="Mail Template"
            width={720}
            onClose={onChildrenDrawerClose}
            open={childrenDrawer}
          >
            <Form
              onFinish={sendEmail}
              form={form}
              initialValues={SelectedEmailTemplate[0] || {}}
              labelCol={{
                span: 4,
              }}
              wrapperCol={{
                span: 20,
              }}
            >
              <p style={{ marginBottom: "20px" }}>
                Please Use Coupon Code:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {CouponDetail?.Code}
                </span>
              </p>
              <Form.Item label="Email Type" name="EmailTypeId">
                <Select>
                  {EmailType?.map((type) => (
                    <Option key={type?.Id} value={type?.Id}>
                      {type?.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Subject (En)" name="Subject">
                <Input />
              </Form.Item>
              <Form.Item label="Subject (Ar)" name="SubjectLT">
                <Input style={{ direction: "rtl", textAlign: "right" }} />
              </Form.Item>

              <Form.Item label="Body (En)" name="Body">
                <ReactQuill
                  theme="snow"
                  style={{
                    height: "50vh", // Adjust height
                    width: "100%", // Adjust width
                  }}
                />
              </Form.Item>

              <Form.Item
                style={{
                  marginTop: "8vh",
                }}
                label="Body (Ar)"
                name="BodyLT"
              >
                <ReactQuill
                  theme="snow"
                  className="quill-content-holder rtl-input"
                  style={{
                    height: "50vh", // Adjust height
                    width: "100%",
                    direction: "rtl",
                    textAlign: "right", // Adjust width
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: 30 }}
                  onClick={sendEmail}
                  loading={loading}
                >
                  {t.Save} & Send Email
                </Button>
              </Form.Item>
            </Form>
            {/* <Form>
              <Form.Item label="Subject">
                <Input value={subject} onChange={handleSubjectChange} />
              </Form.Item>

              <Form.Item label="Body">
                <ReactQuill
                  theme="snow"
                  value={editorContent}
                  onChange={handleEditorChange}
                  style={{
                    height: "60vh", // Adjust this value to increase the height
                    flexGrow: 1,
                    minHeight: "50vh",
                    overflow: "auto",
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  style={{ marginTop: 30 }}
                  onClick={sendEmail}
                  loading={loading}
                >
                  Send Email
                </Button>
              </Form.Item>
            </Form> */}
          </Drawer>
        </>
      </Drawer>
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">{t.sendCoupon}</p>
          </div>
        }
        columns={columns || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
      />
    </div>
  );
};
