import React from "react";
import styles from "./notification.module.scss";
import { Image } from "antd";
import img_1 from "../../images/notification-bing.svg";
 
function Notification() {
  return (
    <>
      <div className={styles.notification_page}>
        <div className="container">
          <div className={styles.notification_page_holder}>
            <h2 className={styles.head_title}>Notification</h2>
            <ul className={styles.notification_list}>
              <li className={styles.notification_item}>
                <div>
                  <Image src={img_1} alt="img" preview={false} />
                </div>
                <div className={styles.item_content}>
                  <h4>Your Payment Success! Get Invoice</h4>
                  <p>
                    In publishing and graphic design, Lorem ipsum Icommonly used
                    to demonstrate the typeface without{" "}
                  </p>
                </div>
              </li>
              <li className={styles.notification_item}>
                <div>
                  <Image src={img_1} alt="img" preview={false} />
                </div>
                <div className={styles.item_content}>
                  <h4>Your Payment Success! Get Invoice</h4>
                  <p>
                    In publishing and graphic design, Lorem ipsum Icommonly used
                    to demonstrate the typeface without{" "}
                  </p>
                </div>
              </li>
              <li className={styles.notification_item}>
                <div>
                  <Image src={img_1} alt="img" preview={false} />
                </div>
                <div className={styles.item_content}>
                  <h4>Your Payment Success! Get Invoice</h4>
                  <p>
                    In publishing and graphic design, Lorem ipsum Icommonly used
                    to demonstrate the typeface without{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notification;
