import React, { useEffect, useState } from 'react';
import { Button, Tag, Modal, message } from 'antd';
import { TableGrid } from '../../../Component/Table';
import './style.scss'
import Api from '../../../Network/ApiConfig'
import { StarFilled, DeleteFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import en from '../../../localization/en';
import ar from '../../../localization/ar';

const { confirm } = Modal;

export const SubCategories = () => {
  const [categoryList, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const {  currentLang } = useSelector((state) => state?.user);

  const t = currentLang === "en" ? en : ar;
  const fetchCategoriesList = (url) => {
    let body = {
      pagination_number: 10
    }

    setLoading(true);
    Api.post(url ? url : 'categories/assigned', body).then(res => {
      if (res.data.validation.length > 0) {
        message.error(res.data.validation[0])
      }
      if (res.data.code === 200)
        setCategoryList(res.data.data);

      setLoading(false);
    }, () => {
      setLoading(false);
    })
  }

  useEffect(() => fetchCategoriesList(), []);

  const handleDeleteSubCategories = (record) => {
    let body = {
      categoryId: record.id,
      confirm: 1
    }

    confirm({
      title: `Delete This Category?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        Api.post('categories/delete', body).then(res => {
          if (res.data.validation.length > 0) {
            message.error(res.data.validation[0])
          }

          if (res.data.code === 200) {
            message.success(res.data.message)
            fetchCategoriesList();
          }
        })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: <p className='table-header-text'> {t.name}</p>,
      dataIndex: 'name_en',
      render: (_, rec) => {
        return (<p> {rec.Name} - {rec.NameLT} </p>)
      }
    },
    {
      title: <p className='table-header-text'> Description <span> وصف </span></p>,
      dataIndex: 'name_en',
      render: (_, rec) => {
        return <p> {rec.Description} - {rec.DescriptionLT} </p>
      }
    },
    {
      title: <p className='table-header-text'> Price <span> سعر </span></p>,
      dataIndex: 'Price',
    },
    // {
    //   title: <p className='table-header-text'> Visibility <span> الحالة </span></p>,
    //   dataIndex: 'visibility',
    //   render: (_, rec) => {
    //     return (
    //       <div>
    //         <Tag className='table-tag' style={{ background: rec.visibility === 1 ? '#612166' : '#fc4242' }}>
    //           {rec.visibility === 1 ? 'visible - ظاهر' : 'Invisible - مخفي'}
    //         </Tag>
    //       </div>
    //     );
    //   },
    // },
    {
      title: <p className='table-header-text'> Actions <span> افعال </span></p>,
      dataIndex: 'address',
      render: (_, record) => {
        return (
          <DeleteFilled style={{ color: '#fc4242', fontSize: '18px' }} onClick={() => handleDeleteSubCategories(record)} />
        );
      }
    },
  ];

  return (
    <div className='product__wrapper'>
      <TableGrid title={
        <div className='table__filter'>
          <p className='table__title'> packages <span> الحزم </span></p>

          <div className='product__btn'>
            <Button onClick={() => history.push('/profile/sub-categories/assign')} className='primary'>New package  حزمة جديدة</Button>
          </div>
        </div>
      }
        columns={columns}
        dataSource={categoryList?.categories}
        loading={loading}
        defaultCurrent={10}
        pagination={{ pageSize: 10, total: categoryList?.category_count }}
        total={categoryList?.category_count}
        onChange={(pagination) => {
          let url = `categories/assigned?page=${pagination?.current}`
          fetchCategoriesList(url)
        }}
      />
    </div>
  );
};
