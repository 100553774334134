import React, { useEffect, useState } from "react";
import { Button, Select, Form, message, Input, Upload } from "antd";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";

export const AddAdvertisement = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ProfileImg, setProfileImg] = useState(null);
  const [imgList, setImgList] = useState([]);
  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const location = useLocation();

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.Image) {
        const mainImg = {
          uid: "-1",
          name: "advertisement",
          status: "done",
          url: location?.state?.Image,
          thumbUrl: location?.state?.Image,
          type: "main",
        };
        setImgList([mainImg]);
      }
    }
  }, []);

  const handleChange = async ({ fileList }) => setImgList(fileList);

  function getBase64(file) {
    // console.log(file, "filefilefile")
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      var base64result = reader.result.substr(reader.result.indexOf(",") + 1);
      setProfileImg(base64result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const onFinish = (values) => {
    console.log(values);
    setLoading(true);

    let body = {
      Name: values?.Name,
      NameLT: values?.NameLT,
      Description: values?.Description,
      DescriptionLT: values?.DescriptionLT,
      Link: values?.Link,
      Image: ProfileImg || null,
    };

    if (location?.state) {
      body.Id = location?.state?.Id;
      Api.post("AdminAPIs/EditAdvertisement", body).then(
        (res) => {
          console.log(res?.data?.metas?.message);
          setLoading(false);

          if (res?.Code) {
            message.error(res?.message);
          }
          if (res?.status === 200) {
            message.success(res?.data?.metas?.message);

            history.push({
              pathname: "/advertisements",
            });
          }
          if (res?.status === 400) {
            message.error(res?.errors?.message);
          }
        },
        (err) => {
          setLoading(false);
          console.log(err?.message);
          message.error(err?.message);
        }
      );
      return false;
    }

    Api.post("AdminAPIs/CreateAdvertisement", body).then(
      (res) => {
        console.log(res);
        console.log(res?.data?.metas?.message);
        // message.success(res?.data?.model?.AspNetUsers);
        setLoading(false);
        if (res?.status === 200) {
          message.success(res?.data?.metas?.message);

          history.push({
            pathname: "/advertisements",
          });
        }
        if (res?.status === 400) {
          console.log(res);
          message.error(res?.errors?.message);
        }
      },
      (err) => {
        setLoading(false);
        console.log(err?.message);
        message.error(err?.message);
      }
    );
  };

  return (
    <div className="content__wrapper">
      {console.log(location?.state)}
      <div className="product__wrapper--add">
        {location?.state ? (
          <p className="product__title--page">{t.UpdateAdvertisement} </p>
        ) : (
          <p className="product__title--page">{t.NewAdvertisement} </p>
        )}
        <Form
          initialValues={{
            Name: location?.state?.Name,
            NameLT: location?.state?.NameLT,
            Description: location?.state?.Description,
            DescriptionLT: location?.state?.DescriptionLT,
            Link: location?.state?.Link,
          }}
          form={form}
          name="personalInfo"
          className="form__wrapper form__wrapper--product"
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label={t.advertisementimage}
            name="ProfileImage"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
            className="full-width"
          >
            <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              listType="picture"
              className="upload-list-inline"
              maxCount={1}
              fileList={imgList}
              onChange={handleChange}
              beforeUpload={(file) => getBase64(file)}
            >
              <Button icon={<UploadOutlined />}>{t.Upload}</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            label={t.nameAr}
            name="NameLT"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t.nameEn}
            name="Name"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t.descriptionAr}
            name="DescriptionLT"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t.descriptionEn}
            name="Description"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t.link}
            name="Link"
            // rules={[{ required: true, message: t.ThisFieldisrequired }]}
          >
            <Input type="url" />
          </Form.Item>

          <Form.Item
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexBasis: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexBasis: "100%",
              }}
            >
              <Button
                loading={loading}
                htmlType="submit"
                style={{ border: "none", color: "#fff" }}
              >
                {" "}
                {t.Save}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
