import React from "react";
import { Route } from "react-router-dom";


function ProtectedRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) => <Component {...props} />}
    />
  );
}

export default ProtectedRoute; 