import React, { useEffect, useState } from 'react';
import { Button, Select, Form, message, Input } from 'antd';
import './style.scss'
import Api from '../../Network/ApiConfig'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import "react-phone-number-input/style.css";
import en from "../../localization/en";
import ar from "../../localization/ar";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
export const AddFaq = () => {
    const history = useHistory();
    const [form] = Form.useForm();
    const [categoryList, setCategoryList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [editorState, seteditorState] = useState(EditorState.createEmpty());
    const [descriptioncontent, setdescriptioncontent] = useState(EditorState.createEmpty());
    const [descriptionLTcontent, setdescriptionLTcontent] = useState(EditorState.createEmpty());
    const {  currentLang } = useSelector((state) => state?.user);
    const t = currentLang === "en" ? en : ar;
    const location = useLocation();
    
    const fetchCategoriesList = () => {
      Api.get('AdminAPIs/GetFAQCategories?page=0').then(res => {
          if (res?.status === 200) {
            console.log("res?.data?.model", res?.data?.model)
              setCategoryList(res?.data?.model?.filter(s => !s?.IsDeleted));
          }
      })
  }

    useEffect(() => {
        fetchCategoriesList();
        if (location?.state) {
      if (location?.state?.Description) {
          // const contentBlocks = draftToHtml(JSON.parse(location?.state?.Description || ""));
          // const contentBlocks = convertFromHTML(location?.state?.Description || "");
          // console.log(contentBlocks, "contentBlockscontentBlockscontentBlocks")
        try {
          const contentBlocks = convertFromHTML(JSON.parse(location?.state?.Description || ""));
          const contentState = ContentState.createFromBlockArray(contentBlocks);
          setdescriptioncontent(EditorState.createWithContent(contentState))
        } catch (error) {
          try {
            const contentBlocks = convertFromHTML(JSON.parse(JSON.stringify(location?.state?.Description || "")));
          const contentState = ContentState.createFromBlockArray(contentBlocks);
          setdescriptioncontent(EditorState.createWithContent(contentState))
          } catch (err) {
            console.log(err, "errrrrrrrrrrrrrrrrrrrrr")
          }
        }  
        }
      if (location?.state?.DescriptionLT) {
        try {
          const contentBlocks = convertFromHTML(JSON.parse(location?.state?.DescriptionLT || ""));
          const contentState = ContentState.createFromBlockArray(contentBlocks);
          setdescriptionLTcontent(EditorState.createWithContent(contentState))
        } catch (error) {
          try {
            const contentBlocks = convertFromHTML(JSON.parse(JSON.stringify(location?.state?.DescriptionLT || "")));
          const contentState = ContentState.createFromBlockArray(contentBlocks);
          setdescriptionLTcontent(EditorState.createWithContent(contentState))
          } catch (err) {
            console.log(err, "errrrrrrrrrrrrrrrrrrrrr")
          }
        }  
        }
      // if (location?.state?.DescriptionLT) {
      //           const contentBlocks = convertFromHTML(JSON.parse(location?.state?.DescriptionLT));
      //           const contentState = ContentState.createFromBlockArray(contentBlocks);
      //           setdescriptionLTcontent(EditorState.createWithContent(contentState))
      // }
      }
    }, []);


    const onFinish = (values) => {
        // console.log(values);
        // console.log(draftToHtml(convertToRaw(descriptioncontent.getCurrentContent())));
        // return false;
        setLoading(true);
        let body = {
          Name: values?.Name,
          NameLT: values?.NameLT,
          FAQCategoryId: values?.FAQCategoryId,  
          Description: draftToHtml(convertToRaw(descriptioncontent.getCurrentContent())).trim(),
          DescriptionLT: draftToHtml(convertToRaw(descriptionLTcontent.getCurrentContent())).trim(),
        };

        if(location?.state){
          body.Id = location?.state?.Id;
          Api.post("AdminAPIs/EditFAQ", body).then(
            (res) => {
              console.log(res?.data?.metas?.message);
              setLoading(false);
  
              if(res?.Code){
                message.error(res?.message);
              }
              if(res?.status === 200){
                message.success(res?.data?.metas?.message);
                history.push({
                  pathname: '/faq',
              })
              }
              if(res?.status === 400){
                message.error(res?.errors?.message);
              }
            },
            (err) => {
              setLoading(false);
              console.log(err?.message)
              message.error(err?.message);
            }
          );
          return false;
        }

        Api.post("AdminAPIs/CreateFAQ", body).then(
          (res) => {
            console.log(res);
            console.log(res?.data?.metas?.message);
            // message.success(res?.data?.model?.AspNetUsers);
            setLoading(false);
            if(res?.status === 200){
          message.success(res?.data?.metas?.message);

              history.push({
                pathname: '/faq',
            })
            }
            if(res?.status === 400){
              console.log(res)
              message.error(res?.errors?.message);
            }
          },
          (err) => {
            setLoading(false);
            console.log(err?.message)
            message.error(err?.message);
          }
        );
      };

      const onEditorStateChange = (editorState) => {
        console.log(editorState, "editorState")
        console.log(editorState.getCurrentContent())
        console.log(convertToRaw(editorState.getCurrentContent()))
         const value = draftToHtml(convertToRaw(editorState.getCurrentContent()))
         console.log(value)
        seteditorState(editorState);
      };

    return (
        <div className='content__wrapper'>
            {/* {console.log(location?.state)} */}
            <div className='product__wrapper--add'>
              {location?.state ? 
                <p className='product__title--page'>{t.Updatefaq}</p> :
                <p className='product__title--page'>{t.Addfaq} </p>
              }
                <Form 
                initialValues={{
                  Name: location?.state?.Name,
                  NameLT: location?.state?.NameLT,
                  Description: location?.state?.Description,
                  DescriptionLT: location?.state?.DescriptionLT,
                  FAQCategoryId: location?.state?.FAQCategoryId,
                }}
                form={form} name="personalInfo" className='form__wrapper form__wrapper--product'
                    onFinish={onFinish}
                    autoComplete="off"
                >

                    <Form.Item
                        label={t.nameAr}
                        name="NameLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>



                    <Form.Item
                        label={t.nameEn}
                        name="Name"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label={t.descriptionAr}
                        name="DescriptionLT"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        {/* <TextArea rows={8} /> */}
                        <Editor
                        locale="ar"
                        toolbar={{
                          options: ['inline', 'blockType', 'textAlign', 'link', 'remove', 'history'],
                          inline: {
                            options: ['bold', 'italic', 'underline', 'strikethrough'],
                          },
                        }}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        editorState={descriptionLTcontent}
                        onEditorStateChange={(val) => setdescriptionLTcontent(val)}
                      />
                    </Form.Item>
                    <Form.Item
                        label={t.descriptionEn}
                        name="Description"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                    >
                        {/* <TextArea rows={8} /> */}
                    
                      <Editor
                      toolbar={{
                        options: ['inline', 'blockType', 'textAlign', 'link', 'remove', 'history'],
                        inline: {
                          options: ['bold', 'italic', 'underline', 'strikethrough'],
                        },
                      }}
                        wrapperClassName="demo-wrapper en-editor-style"
                        editorClassName="demo-editor"
                        onEditorStateChange={(val) => setdescriptioncontent(val)}
                        editorState={descriptioncontent}
                      />
                    </Form.Item>

                    <Form.Item
                        label={t.Category}
                        name="FAQCategoryId"
                        rules={[{ required: true, message: t.ThisFieldisrequired }]}
                      >
                        <Select>
                          {categoryList?.map((vehicle) => (
                            <Select.Option key={vehicle.Id} value={vehicle.Id}>
                              { currentLang === "en" ? vehicle.Name : vehicle.NameLT}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>

                    <Form.Item style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', flexBasis: '100%' }}>
                            <Button loading={loading} htmlType='submit' style={{ border: 'none', color: "#fff" }}> {t.Save}</Button>
                        </div>
                    </Form.Item>

                </Form>
            </div>
        </div>
    );
};
