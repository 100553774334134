import { combineReducers } from "redux";
import userReducer from "./user/user.reducer";
import cartReducer from "./cart/cart.reducer";
import orderReducer from "./order/order.reducer";
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist : ['cart', 'user']
  }

  const rootReducer = combineReducers({
    user : userReducer,
    cart : cartReducer,
    order: orderReducer
});

export default persistReducer(persistConfig, rootReducer);