import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Input, message, Select, Tag } from "antd";
import { TableGrid } from "../../Component/Table";
import "./style.scss";
import Api from "../../Network/ApiConfig";
import moment from "moment";
import { DeleteOutlined } from "@ant-design/icons";
import { CSVLink } from "react-csv";
import { StyleToPRint } from "../../Component/jsonsStyles/styleJson";
import { useSelector } from "react-redux";
import en from "../../localization/en";
import ar from "../../localization/ar";

const CheckboxGroup = Checkbox.Group;

export const PaymentLogs = () => {
  const pdfRef = React.createRef();
  const [callBack, setCallBack] = useState([]);
  const [callBackList, setCallBackList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelData, setExcelData] = useState([]);
  const [TotalCount, setTotalCount] = useState(30);
  const [page, setpage] = useState(0);

  const { currentLang } = useSelector((state) => state?.user);
  const t = currentLang === "en" ? en : ar;
  const columns = [
    // {
    //   title: <p className="table-header-text">{t.name} </p>,
    //   dataIndex: currentLang === "en" ? "Name" : "NameLT",
    //   className: "no-wrap",
    // },
    // {
    //   title: <p className="table-header-text"> {t.Phonenumber}</p>,
    //   dataIndex: "user_phone",
    //   render: (_, record) =>
    //     record?.PhoneNumber && (
    //       <a href={`tel:${record?.PhoneKey + record?.PhoneNumber}`}>
    //         {record?.PhoneKey + record?.PhoneNumber}{" "}
    //       </a>
    //     ),
    // },
    {
      title: "ID",
      dataIndex: "key",
    },
    {
      title: "Order Id",
      dataIndex: "OrderId",
    },
    {
      title: <p className="table-header-text"> {"Payment Link"} </p>,
      dataIndex: "PaymentLink",
    },
    {
      title: <p className="table-header-text"> {"Payment Status"} </p>,
      render: (text, record, index) => record?.PaymentLogStatus?.Name,
    },
    // {
    //   title: <p className="table-header-text"> {t.contacttype} </p>,
    //   dataIndex: "ContactTypeId",
    //   render: (_, record) => {
    //     return (
    //       <p>
    //         {" "}
    //         {currentLang === "en"
    //           ? record?.ContactType?.Name
    //           : record?.ContactType?.NameLT}{" "}
    //       </p>
    //     );
    //   },
    // },
    // {
    //   title: <p className="table-header-text">{t.Message}</p>,
    //   dataIndex: "Message",
    // },
    {
      title: <p className="table-header-text"> {t.CreationDate}</p>,
      dataIndex: "CreationDate",
      className: "no-wrap",
      render: (_, record) => {
        return <p> {moment(record?.CreationDate)?.format("DD-MM-YYYY")} </p>;
      },
    },
    {
      title: <p className="table-header-text"> {t.Actions}</p>,
      render: (_, record) => {
        return (
          <div className="select-item-action">
            <DeleteOutlined
              onClick={(e) => {
                handelDltPaymentLog(record?.Id);
              }}
              style={{
                marginInlineEnd: 20,
                color: "red",
                fontSize: 20,
                cursor: "pointer",
              }}
            />
          </div>
        );
      },
    },
  ];

  const handelDltPaymentLog = (e) => {
    setLoading(true);
    Api.get(`AdminAPIs/DeletePaymentLinkLog?PaymentLinkLogId=${e}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          fetchListData();
          message.success(res?.data?.metas?.message);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const fetchListData = (index = 0) => {
    setLoading(true);
    setpage(index);
    Api.get(`AdminAPIs/GetPaymentLinkLog?page=${index}`).then(
      (res) => {
        setLoading(false);
        if (res?.status === 200) {
          const dataSource = res?.data?.model.map((item, ind) => ({
            ...item,
            key: ind + 1 + index * 30, // Or any other unique identifier
          }));
          setCallBack(dataSource);
          setCallBackList(dataSource);
        }
        if (res?.data?.metas?.TotalaCount) {
          setTotalCount(res?.data?.metas?.TotalaCount);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchListData();
  }, []);

  // useEffect(() => {
  // const data = callBack?.map((item) => {
  //   let obj = {};
  //   columns.map((dataIndex) => {
  //     if (dataIndex.dataIndex === "date")
  //       return (obj[dataIndex?.title?.props?.children[0]?.toUpperCase()] =
  //         moment(item[dataIndex.dataIndex]).format("DD/MM/YYYY"));
  //     else
  //       return (obj[dataIndex.title.props.children[0]?.toUpperCase()] =
  //         item[dataIndex.dataIndex]);
  //   });
  //   return obj;
  // });
  // setExcelData(data);
  // }, [columns, callBack]);

  return (
    <div className="product__wrapper">
      <TableGrid
        TableRef={pdfRef}
        title={
          <div className="table__filter">
            <p className="table__title">{t.PaymentLogs}</p>
          </div>
        }
        columns={columns || []}
        dataSource={callBackList || []}
        loading={loading}
        pageSize={30}
        total={TotalCount}
        onChange={(pagination) => {
          fetchListData(pagination?.current - 1);
        }}
      />
    </div>
  );
};
